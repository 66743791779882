import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppConsts } from './../../../shared/AppConsts';
import { AudioConfig, SpeakerAudioDestination, SpeechConfig, SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk';
// import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { AppSessionService } from './../../shared/common/session/app-session.service';

@Injectable()
export class SpeechService {

  private _http: HttpClient;
  private authorizationToken: string;

  private enlocale = "en-US";
  private eslocale = "es-ES";

  private enVoice = "-AriaNeural";
  private esVoice = "-ElviraNeural";

  private speechConfig: SpeechConfig;
  private appSession: AppSessionService;
  private player: SpeakerAudioDestination;
  private audioConfig: AudioConfig;
  private synthesizer: SpeechSynthesizer;

  constructor(injector: Injector, handler: HttpBackend) {
    this._http = new HttpClient(handler);
    this.appSession = injector.get(AppSessionService);
    console.log(this.appSession);
    this.initAzureSpeechAuthToken();
  }

  getAzureSpeechAuthToken() {
    let httpOptions: any = {
      responseType: "text",
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Cache-Control": "no-cache, no-store, must-revalidate, max-age=0",
        'Ocp-Apim-Subscription-Key': AppConsts.azureTextToSpeech.subscriptionKey
      })
    };
    return this._http.post(AppConsts.azureTextToSpeech.speechTokenbaseUrl, {}, httpOptions).toPromise();
  }

  initAzureSpeechAuthToken() {
    this.getAzureSpeechAuthToken().then((token: any) => {
      this.authorizationToken = token;
      // console.log(this.authorizationToken);
      this.initSpeechConfig();
    });
  }

  initSpeechConfig() {
    // init speechConfig
    if (this.authorizationToken) {
      this.speechConfig = SpeechConfig.fromAuthorizationToken(this.authorizationToken, AppConsts.azureTextToSpeech.serviceRegion);
      this.speechConfig = SpeechConfig.fromSubscription(AppConsts.azureTextToSpeech.subscriptionKey, AppConsts.azureTextToSpeech.serviceRegion);
      this.setLanguageAndVoice();
    } else {
      this.initAzureSpeechAuthToken();
    }
  }

  setLanguageAndVoice() {
    let locale = this.enlocale;
    let voice = locale + this.enVoice;
    if (this.appSession.user.currentLanguageCode == 'es') {
      locale = this.eslocale;
      voice = locale + this.esVoice;
    }
    this.speechConfig.speechSynthesisLanguage = locale;
    this.speechConfig.speechSynthesisVoiceName = voice;
    // console.log(locale, voice, text);
  }

  synthesizeSpeech(text: string) {
    // console.log(player);
    const sanitizedText = text.replace(/[^\w\s]/gi, ''); // remove special characters
    this.player = new SpeakerAudioDestination();
    // const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
    this.audioConfig = AudioConfig.fromSpeakerOutput(this.player);
    this.synthesizer = new SpeechSynthesizer(this.speechConfig, this.audioConfig);
    this.synthesizer.speakTextAsync(
      sanitizedText,
      result => {
        if (result) {
          console.log(JSON.stringify(result));
        }
        this.synthesizer.close();
      },
      error => {
        console.log(error);
        this.synthesizer.close();
      });
  }

  stopSpeech() {
    if (this.player) {
      this.player.internalAudio.src = "";
      this.player.pause();
      this.player.close();
    }
  }

  // synthesizeSpeechU(text: string) {
  //   console.log('synthesizeSpeech');
  //   if (this.authorizationToken) {
  //     let speechConfig = SpeechConfig.fromAuthorizationToken(this.authorizationToken, AppConsts.azureTextToSpeech.serviceRegion);
  //     speechConfig = SpeechConfig.fromSubscription(AppConsts.azureTextToSpeech.subscriptionKey, AppConsts.azureTextToSpeech.serviceRegion);

  //     let locale = this.enlocale;
  //     let voice = locale + this.enVoice;
  //     if (this.appSession.user.currentLanguageCode == 'es') {
  //       locale = this.eslocale;
  //       voice = locale + this.esVoice;
  //     }

  //     speechConfig.speechSynthesisLanguage = locale;
  //     speechConfig.speechSynthesisVoiceName = voice;
  //     // console.log(locale, voice, text);

  //     const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
  //     const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
  //     synthesizer.speakTextAsync(
  //       text,
  //       result => {
  //         if (result) {
  //           console.log(JSON.stringify(result));
  //         }
  //         synthesizer.close();
  //       },
  //       error => {
  //         console.log(error);
  //         synthesizer.close();
  //       });
  //   } else {
  //     this.getAzureSpeechAuthToken();
  //   }
  // }

}
