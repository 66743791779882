import { RiskAlertForViewDtoPagedResultDto } from "src/shared/service-proxies/service-proxies";
import { assessmentTypes, createEmojiRiskAlert, createNoteAlert, createTempoRiskAlert, getAdmins, getAlertRecipients, getClasses, getEmojiAssessmentQuestions, getGrades, getGroups,  getStudents, getTeachers, loadRiskAlert } from "./settings.actions";

export interface State {
    riskAlertList: any,
    emojiriskAlert: any,
    assessmentTypes: any,
    grades: any,
    classes: any,
    groups: any,
    students: any,
    teachers: any,
    admins: any,
    emojiRiskALertQuestions: any,
    alertRecipients: any,
    tempoRiskAlert: any,
    noteRiskAlert:any
}
export const initialState: State = {
    riskAlertList: null,
    emojiriskAlert: [],
    assessmentTypes: [],
    grades: [],
    classes: [],
    groups: [],
    students: [],
    teachers: [],
    admins: [],
    emojiRiskALertQuestions: [],
    alertRecipients: [],
    tempoRiskAlert: [],
    noteRiskAlert:[]
}
export function reducer(state = initialState, action: any) {
    let stateToReturn = state;

    switch (action.type) {
        case loadRiskAlert.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                riskAlertList: requestPayload,
            });
        }
        case loadRiskAlert.success.type: {

            return Object.assign({}, state, {
                riskAlertList: action,
            })
        }


        case createEmojiRiskAlert.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createRiskAlert: requestPayload,
            });

        }

        case createEmojiRiskAlert.success.type: {
            return Object.assign({}, state, {
                createRiskAlert: action,
            })
        }

        // Assessment Types
        case assessmentTypes.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                assessmentTypes: requestPayload,
            });
        }

        case assessmentTypes.success.type: {
            return Object.assign({}, state, {
                assessmentTypes: action,
            })
        }
        // Grades
        case getGrades.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                grades: requestPayload,
            });
        }

        case getGrades.success.type: {
            return Object.assign({}, state, {
                grades: action,
            })
        }

        // Classes
        case getClasses.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                classes: requestPayload,
            });
        }

        case getClasses.success.type: {
            return Object.assign({}, state, {
                classes: action,
            })
        }

        // Groups
        case getGroups.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                groups: requestPayload,
            });
        }

        case getGroups.success.type: {
            return Object.assign({}, state, {
                groups: action,
            })
        }

        // Students
        case getStudents.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                students: requestPayload,
            });
        }

        case getStudents.success.type: {
            return Object.assign({}, state, {
                students: action,
            })
        }

        // Teachers
        case getTeachers.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                teachers: requestPayload,
            });
        }

        case getTeachers.success.type: {
            return Object.assign({}, state, {
                teachers: action,
            })
        }

        // Admins
        case getAdmins.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                admins: requestPayload,
            });
        }

        case getAdmins.success.type: {
            return Object.assign({}, state, {
                admins: action,
            })
        }

        // emoji questions and options
        case getEmojiAssessmentQuestions.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                emojiRiskALertQuestions: requestPayload,
            });
        }

        case getEmojiAssessmentQuestions.success.type: {
            return Object.assign({}, state, {
                emojiRiskALertQuestions: action,
            })
        }
        // Risk Alert Recipients
        case getAlertRecipients.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                alertRecipients: requestPayload,
            });
        }

        case getAlertRecipients.success.type: {
            return Object.assign({}, state, {
                alertRecipients: action,
            })
        }

        // create Tempo alert
        case createTempoRiskAlert.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createTempoAlert: requestPayload,
            });
        }
        case createTempoRiskAlert.success.type: {
            return Object.assign({}, state, {
                createTempoAlert: action,
            })
        }
        // create note alert
        case createNoteAlert.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createNoteAlert: requestPayload,
            });
        }
        case createNoteAlert.success.type: {
            return Object.assign({}, state, {
                createNoteAlert: action,
            })
        }


        default:
            stateToReturn
            break;
    }
    return stateToReturn;
}
export const emojiRiskAlert = (state: State) => state?.emojiriskAlert;
export const getAllRiskAlert = (state: State) => state?.riskAlertList;
export const defaultAssessmentTypes = (state: State) => state?.assessmentTypes;
export const grades = (state: State) => state?.grades;
export const classes = (state: State) => state?.classes;
export const groups = (state: State) => state?.groups;
export const students = (state: State) => state?.students;
export const teachers = (state: State) => state?.teachers;
export const admins = (state: State) => state?.admins;
export const emojiRiskALertQuestions = (state: State) => state?.emojiRiskALertQuestions;
export const alertRecipients = (state: State) => state?.alertRecipients;
export const tempoRiskAlert = (state: State) => state?.tempoRiskAlert;
export const noteRiskAlert = (state: State) => state?.noteRiskAlert;