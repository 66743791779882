import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateModel, AuthenticateResultModel, ExternalAuthenticateModel, ExternalAuthenticateResultModel, ExternalLoginProviderInfoModel, HttpStatusCode, TenantSettingsServiceProxy, TokenAuthServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { CredentialsService } from './credentials.service';
import { AppConsts } from 'src/shared/AppConsts';
import { FeatureFlagEnum } from 'src/shared/FeatureFlags';
import { UrlHelper } from '@app/shared/helpers/UrlHelper';
import * as _ from 'lodash';
import { ScriptLoaderService } from 'src/shared/utils/script-loader.service';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { RMSService } from '@app/shared/rms-service';
import { finalize } from 'rxjs';
import { DataService } from '@app/shared/data.service';
import * as reducers from '../reducers';
import { Store } from '@ngrx/store';
import { isLoginLoader } from '@app/app.actions';

declare const FB: any; // Facebook API
declare const gapi: any; // Facebook API
declare const WL: any; // Microsoft API

export class ExternalLoginProvider extends ExternalLoginProviderInfoModel {
    static readonly FACEBOOK: string = 'Facebook';
    static readonly GOOGLE: string = 'Google';
    static readonly MICROSOFT: string = 'Microsoft';
    static readonly OPENID: string = 'OpenIdConnect';
    static readonly WSFEDERATION: string = 'WsFederation';
    static readonly CLASSLINK: string = 'ClassLink';
    static readonly CLEVER: string = 'Clever';
    static readonly AEQUITAS: string = 'Aequitas';
    static readonly CANVAS: string = 'Canvas';

    icon: string;
    initialized = false;

    constructor(providerInfo: ExternalLoginProviderInfoModel) {
        super();
        this.name = providerInfo.name;
        this.clientId = providerInfo.clientId;
        this.additionalParams = providerInfo.additionalParams;
        this.icon = providerInfo.name.toLowerCase();
    }
}

@Injectable({
    providedIn: 'root'
})

export class LoginService {

    static readonly twoFactorRememberClientTokenName = 'TwoFactorRememberClientToken';

    authenticateModel: AuthenticateModel;
    authenticateResult: AuthenticateResultModel;
    externalLoginProviders: ExternalLoginProvider[] = [];
    rememberMe: boolean = true;
    wsFederationAuthenticationContext: any;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        // private _utilsService: UtilsService,
        // private _messageService: MessageService,
        // private _tokenService: TokenService,
        // private _logService: LogService,
        private oauthService: OAuthService,
        private _mountainTokenAuthService: TokenAuthServiceProxy,
        private credentialsService: CredentialsService,
        private RMSService: RMSService,
        private _dataService: DataService,
        private store: Store<reducers.State>,
    ) {
        this.clear();
        this.init();
    }

    public login(accessToken: string, encryptedAccessToken: string, expireInSeconds: number, rememberMe?: boolean, twoFactorRememberClientToken?: string, redirectUrl?: string): void {
        // this._tokenService.setToken(accessToken,undefined);
        this.credentialsService.setCookie('token', accessToken)
        let tokenExpireDate = rememberMe ? (new Date(new Date().getTime() + 1000 * expireInSeconds)) : undefined;
        if (rememberMe) {
            let encrytpedEmail = window.btoa(this.authenticateModel.userNameOrEmailAddress)
            this.credentialsService.setCookie(
                AppConsts.authorization.userNameOrEmailAddress,
                encrytpedEmail,
                tokenExpireDate,
                // abp.appPath
            );
        } else {
            // this._utilsService.deleteCookie
            this.credentialsService.removeCookie(AppConsts.authorization.userNameOrEmailAddress);
        }

        if (twoFactorRememberClientToken) {
            this.credentialsService.setCookie(
                LoginService.twoFactorRememberClientTokenName,
                twoFactorRememberClientToken,
                new Date(new Date().getTime() + 365 * 86400000), // 1 year
                // abp.appPath
            );
        }

        if (redirectUrl) {
            location.href = redirectUrl;
        } else {
            let initialUrl = UrlHelper.initialUrl;

            if (initialUrl.indexOf('/account') > 0) {
                initialUrl = AppConsts.appBaseUrl;
            }

            this.RMSService.onLoginFlowRegulator(initialUrl);
        }
    }


    private clear(): void {
        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.rememberClient = false;
        this.authenticateResult = null;
        this.rememberMe = true;
    }

    authenticate(finallyCallback?: () => void, redirectUrl?: string, captchaResponse?: string): void {
        finallyCallback = finallyCallback || (() => {
            // abp.ui.clearBusy();
        });

        // We may switch to localStorage instead of cookies
        this.authenticateModel.twoFactorRememberClientToken = this.credentialsService.getCookie(LoginService.twoFactorRememberClientTokenName);
        this.authenticateModel.singleSignIn = UrlHelper.getSingleSignIn();
        this.authenticateModel.returnUrl = UrlHelper.getReturnUrl();
        this.authenticateModel.captchaResponse = captchaResponse;
        this.RMSService.getRhithmFeatures().subscribe(x => {

            if (false) {

                // this.RMSService.onLoginAuthenticate(this.authenticateModel, x.ssoSettings_UseAWSRmsLoginService)
                //     .subscribe({
                //         next: (result: AuthenticateResultModel) => {
                //             this.processAuthenticateResult(result, redirectUrl);
                //             finallyCallback();
                //         },
                //         error: (err: any) => {
                //             finallyCallback();
                //         }
                //     });

            }
            else {

                this._mountainTokenAuthService.authenticate(this.authenticateModel)
                    .subscribe({
                        next: (result: AuthenticateResultModel) => {
                            this.processAuthenticateResult(result, redirectUrl);
                            finallyCallback();
                        },
                        error: (err: any) => {
                            finallyCallback();
                        }
                    });

            }

        })

    }

    externalAuthenticate(provider: ExternalLoginProvider): void {
        this.ensureExternalLoginProviderInitialized(provider, () => {
            // if (provider.name === ExternalLoginProvider.FACEBOOK) {
            //     FB.login(response => {
            //         this.facebookLoginStatusChangeCallback(response);
            //     }, { scope: 'email' });
            // } else
            if (provider.name === ExternalLoginProvider.GOOGLE) {

                gapi.auth2.getAuthInstance().grantOfflineAccess().then((res: any) => {
                    var refreshToken = res.code;
                    this.googleLoginStatusChangeCallback(refreshToken);
                });
            }
            // else if (provider.name === ExternalLoginProvider.AEQUITAS) {

            //     gapi.auth2.getAuthInstance().grantOfflineAccess().then(res => {
            //         var refreshToken = res.code;

            //         this.googleAequitasLoginStatusChangeCallback(refreshToken);
            //     });
            // }
            else if (provider.name === ExternalLoginProvider.CLASSLINK) {
                console.log("NOT IMPLMENTED> externalAuthenticate(provider: ExternalLoginProvider): void {");
            } else if (provider.name === ExternalLoginProvider.CLEVER) {
                console.log("NOT IMPLMENTED> externalAuthenticate(provider: ExternalLoginProvider): void {");
            }
            // else if (provider.name === ExternalLoginProvider.MICROSOFT) {
            //     WL.login({
            //         scope: ['wl.signin', 'wl.basic', 'wl.emails']
            //     });
            // }
        });
    }

    // externalAuthenticateAfterLogin(provider: ExternalLoginProvider, CustomMessageService?): void {
    //     this.ensureExternalLoginProviderInitialized(provider, () => {
    //        if (provider.name === ExternalLoginProvider.GOOGLE) {

    //             gapi.auth2.getAuthInstance().grantOfflineAccess().then(res => {
    //                 var refreshToken = res.code;
    //                 this.googleAfterLoginStatusChangeCallback(refreshToken, CustomMessageService);
    //             });
    //         }
    //     });
    // }
    // private googleAfterLoginStatusChangeCallback(refreshToken, CustomMessageService?) {
    //     if (refreshToken) {
    //         const model = new ExternalAuthenticateModel();
    //         model.authProvider = ExternalLoginProvider.GOOGLE;
    //         model.providerAccessCode = refreshToken;
    //         model.providerKey = "dummy-key";
    //         model.singleSignIn = UrlHelper.getSingleSignIn();
    //         model.returnUrl = UrlHelper.getReturnUrl();

    //         this._mountainTokenAuthService.googleClassRoomExternalAuthenticate(model)
    //             .subscribe({
    //                 next: (result: GoogleClassRoomExternalAuthenticateResultModel) => {
    //                     if(result.success == true){
    //                         //  this._messageService.info('You have successfully Connected.');
    //                         CustomMessageService.open(AppConsts.success, "Successfully connected with Google.", null,
    //                         null, false, null,false,[], [], true)
    //                         return;
    //                      } else{
    //                         CustomMessageService.open(AppConsts.error, "Unable to connect with Google.", null)
    //                         return;
    //                      }
    //                 },
    //                 error: (error: any) =>{
    //                     CustomMessageService.open(AppConsts.error, "Error connecting to Google", null)
    //                 }
    //             });
    //     }
    // }

    init(): void {
        this.initExternalLoginProviders();
    }

    private processAuthenticateResult(authenticateResult: AuthenticateResultModel, redirectUrl?: string) {
        this.authenticateResult = authenticateResult;

        if (authenticateResult.shouldResetPassword) {
            // Password reset

            this._router.navigate(['account/reset-password'], {
                queryParams: {
                    userId: authenticateResult.userId,
                    // tenantId: abp.session.tenantId,
                    tenantId: this.credentialsService.getCookie('Abp.TenantId'),
                    resetCode: authenticateResult.passwordResetCode
                }
            });

            this.clear();

        } else if (authenticateResult.requiresTwoFactorVerification) {
            // Two factor authentication

            this._router.navigate(['account/send-code']);

        } else if (authenticateResult.accessToken) {
            // Successfully logged in

            if (authenticateResult.returnUrl && !redirectUrl) {
                redirectUrl = authenticateResult.returnUrl;
            }

            this.login(
                authenticateResult.accessToken,
                authenticateResult.encryptedAccessToken,
                authenticateResult.expireInSeconds,
                this.rememberMe,
                authenticateResult.twoFactorRememberClientToken,
                redirectUrl
            );

        } else {
            // Unexpected result!

            // this._logService.warn('Unexpected authenticateResult!');
            this._router.navigate(['account/login']);

        }
    }

    private initExternalLoginProviders(callback?: any) {
        this._mountainTokenAuthService
            .getExternalAuthenticationProviders()
            .subscribe((providers: ExternalLoginProviderInfoModel[]) => {
                //remove chrome extention button details hide from login
                providers.splice(2, 1)
                this.externalLoginProviders = _.map(providers, p => new ExternalLoginProvider(p));
                let feature_RHIT4087: any = AppConsts.isFeatureEnabled(FeatureFlagEnum.Feature_RHIT4087)
                if (feature_RHIT4087) {
                    this.externalLoginProviders = this.externalLoginProviders.filter(x => x.name !== "Aequitas")
                }
                if (callback) {
                    callback();
                }
            });
    }

    ensureExternalLoginProviderInitialized(loginProvider: ExternalLoginProvider, callback: () => void) {
        if (loginProvider.initialized) {
            callback();
            return;
        }
        else if (loginProvider.name === ExternalLoginProvider.GOOGLE) {

            //https://developers.google.com/classroom/guides/auth
            let classRoomScopes: string[] = [
                "openid",
                "profile",
                "email",
                "https://www.googleapis.com/auth/classroom.courses.readonly",
                "https://www.googleapis.com/auth/classroom.rosters.readonly",
                "https://www.googleapis.com/auth/classroom.profile.emails"];

            var classRoomScopesString = classRoomScopes.join(" ");

            new ScriptLoaderService().load('https://apis.google.com/js/api.js').then(() => {
                gapi.load('client:auth2',
                    () => {
                        gapi.client.init({
                            clientId: loginProvider.clientId,
                            scope: classRoomScopesString
                        }).then(() => {
                            callback();
                        });
                    });
            });
        }
        else if (loginProvider.name === ExternalLoginProvider.CLASSLINK) {
            let config = this.getClasslinkConnectConfig(loginProvider);
            this.oauthService.configure(config);
            this.oauthService.initImplicitFlow('classLinkConnect=1');
        }
        else if (loginProvider.name === ExternalLoginProvider.CLEVER) {
            let config = this.getCleverConnectConfig(loginProvider);
            this.oauthService.configure(config);
            this.oauthService.initImplicitFlow();
        }
    }

    private getClasslinkConnectConfig(loginProvider: ExternalLoginProvider): any {
        let authConfig = new AuthConfig();
        authConfig.loginUrl = loginProvider.additionalParams['loginUrl'];
        authConfig.issuer = loginProvider.additionalParams['authority'];
        // authConfig.skipIssuerCheck = loginProvider.additionalParams['validateIssuer'] === 'false';
        authConfig.clientId = loginProvider.clientId;
        // authConfig.responseType = 'code';
        authConfig.redirectUri = window.location.origin + '/account/login';
        authConfig.scope = 'full';
        authConfig.requestAccessToken = true;
        return authConfig;
    }

    private getCleverConnectConfig(loginProvider: ExternalLoginProvider): any {
        let authConfig = new AuthConfig();
        authConfig.loginUrl = loginProvider.additionalParams['loginUrl'];
        authConfig.issuer = loginProvider.additionalParams['authority'];
        // authConfig.skipIssuerCheck = loginProvider.additionalParams['validateIssuer'] === 'false';
        authConfig.clientId = loginProvider.clientId;
        // authConfig.responseType = 'code';
        authConfig.redirectUri = window.location.origin + '/account/login?provider=clever';
        authConfig.scope = 'full';
        authConfig.requestAccessToken = true;
        authConfig.responseType = 'code';
        return authConfig;
    }


    private getOpenIdConnectConfig(loginProvider: ExternalLoginProvider): AuthConfig {
        let authConfig = new AuthConfig();
        authConfig.loginUrl = loginProvider.additionalParams['loginUrl'];
        authConfig.issuer = loginProvider.additionalParams['authority'];
        authConfig.skipIssuerCheck = loginProvider.additionalParams['validateIssuer'] === 'false';
        authConfig.clientId = loginProvider.clientId;
        authConfig.responseType = 'id_token';
        authConfig.redirectUri = window.location.origin + '/account/login';
        authConfig.scope = 'openid profile';
        authConfig.requestAccessToken = false;
        return authConfig;
    }

    // private facebookLoginStatusChangeCallback(resp) {
    //     if (resp.status === 'connected') {
    //         const model = new ExternalAuthenticateModel();
    //         model.authProvider = ExternalLoginProvider.FACEBOOK;
    //         model.providerAccessCode = resp.authResponse.accessToken;
    //         model.providerKey = resp.authResponse.userID;
    //         model.singleSignIn = UrlHelper.getSingleSignIn();
    //         model.returnUrl = UrlHelper.getReturnUrl();

    //         this._mountainTokenAuthService.externalAuthenticate(model)
    //             .subscribe((result: ExternalAuthenticateResultModel) => {
    //                 if (result.waitingForActivation) {
    //                     this._messageService.info('You have successfully registered. Waiting for activation!');
    //                     return;
    //                 }

    //                 this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
    //             });
    //     }
    // }

    // private getWsFederationConnectConfig(loginProvider: ExternalLoginProvider): any {
    //     let config = {
    //         clientId: loginProvider.clientId,
    //         popUp: true,
    //         callback: this.wsFederationLoginStatusChangeCallback.bind(this)
    //     } as any;

    //     if (loginProvider.additionalParams['tenant']) {
    //         config.tenant = loginProvider.additionalParams['tenant'];
    //     }

    //     return config;
    // }

    public openIdConnectLoginCallback(resp: any) {
        this.initExternalLoginProviders(() => {
            let openIdProvider = _.filter(this.externalLoginProviders, { name: 'OpenIdConnect' })[0];
            let authConfig = this.getOpenIdConnectConfig(openIdProvider);

            this.oauthService.configure(authConfig);

            /*  abp.ui.setBusy(); */

            this.oauthService.tryLogin().then(() => {
                let claims = this.oauthService.getIdentityClaims();

                const model = new ExternalAuthenticateModel();
                model.authProvider = ExternalLoginProvider.OPENID;
                model.providerAccessCode = this.oauthService.getIdToken();
                model.providerKey = claims['sub'];
                model.singleSignIn = UrlHelper.getSingleSignIn();
                model.returnUrl = UrlHelper.getReturnUrl();

                this._mountainTokenAuthService.externalAuthenticate(model)
                    .pipe(finalize(() => {/*  abp.ui.clearBusy();  */ }))
                    .subscribe((result: ExternalAuthenticateResultModel) => {
                        if (result.waitingForActivation) {
                            // this._messageService.info('You have successfully registered. Waiting for activation!');
                            return;
                        }

                        this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
                    });
            });
        });
    }

    // private googleAequitasLoginStatusChangeCallback(refreshToken) {
    //     if (refreshToken) {
    //         const model = new ExternalAuthenticateModel();
    //         model.authProvider = ExternalLoginProvider.AEQUITAS;
    //         model.providerAccessCode = refreshToken;

    //         model.providerKey = "dummy-key";
    //         model.singleSignIn = UrlHelper.getSingleSignIn();
    //         model.returnUrl = UrlHelper.getReturnUrl();

    //         this.RMSService.getRhithmFeatures().subscribe(x => {
    //             if (x.ssoSettings_UseAequitasFromMongo) {
    //                 this._mountainTokenAuthService.externalAuthenticate(model)
    //                     .pipe(finalize(() => { abp.ui.clearBusy(); }))
    //                     .subscribe((result: any) => {
    //                         this.externalAuthenticateAequitasCallback(result);
    //                     });
    //             }
    //             else {
    //                 this._mountainTokenAuthService.externalAuthenticate(model).pipe(finalize(() => { abp.ui.clearBusy(); }))
    //                     .subscribe((result: any) => {
    //                         this.externalAuthenticateAequitasCallback(result);
    //                     });
    //             }
    //         })
    //     }
    // }
    // externalAuthenticateAequitasCallback(result) {
    //     if (result.waitingForActivation) {
    //         this._messageService.info('You have successfully registered. Waiting for activation!');
    //         return;
    //     }
    //     this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
    // }


    private googleLoginStatusChangeCallback(refreshToken: any) {
        debugger
        this.store.dispatch(isLoginLoader({ isLoding: true }));
        if (refreshToken) {
            const model = new ExternalAuthenticateModel();
            model.authProvider = ExternalLoginProvider.GOOGLE;
            model.providerAccessCode = refreshToken;

            model.providerKey = "dummy-key";
            model.appVersion = 2;
            model.singleSignIn = UrlHelper.getSingleSignIn();
            model.returnUrl = UrlHelper.getReturnUrl();

            this._mountainTokenAuthService.externalAuthenticate(model).pipe(finalize(() => { this.store.dispatch(isLoginLoader({ isLoding: false })) }))
                .subscribe((result: ExternalAuthenticateResultModel) => {
                    if (result.waitingForActivation) {
                        // this._messageService.info('You have successfully registered. Waiting for activation!');
                        return;
                    }

                    this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
                }, error => { this.store.dispatch(isLoginLoader({ isLoding: false })); });
        }
    }

    // public wsFederationLoginStatusChangeCallback(errorDesc, token, error, tokenType) {
    //     let user = this.wsFederationAuthenticationContext.getCachedUser();

    //     const model = new ExternalAuthenticateModel();
    //     model.authProvider = ExternalLoginProvider.WSFEDERATION;
    //     model.providerAccessCode = token;
    //     model.providerKey = user.profile.sub;
    //     model.singleSignIn = UrlHelper.getSingleSignIn();
    //     model.returnUrl = UrlHelper.getReturnUrl();

    //     this._mountainTokenAuthService.externalAuthenticate(model)
    //         .subscribe((result: ExternalAuthenticateResultModel) => {
    //             if (result.waitingForActivation) {
    //                 this._messageService.info('You have successfully registered. Waiting for activation!');
    //                 this._router.navigate(['account/login']);
    //                 return;
    //             }

    //             this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
    //         });
    // }

    public classLinkConnectLoginCallback(resp: any) {

        this.initExternalLoginProviders(() => {
            let openIdProvider = _.filter(this.externalLoginProviders, { name: 'ClassLink' })[0];
            let authConfig = this.getClasslinkConnectConfig(openIdProvider);

            this.oauthService.configure(authConfig);

            // abp.ui.setBusy();

            this.oauthService.tryLoginImplicitFlow().then(() => {
                let claims = this.oauthService.getIdentityClaims();

                const model = new ExternalAuthenticateModel();
                model.authProvider = ExternalLoginProvider.CLASSLINK;
                model.providerAccessCode = this.oauthService.getAccessToken();
                model.providerKey = claims['sub'];
                model.singleSignIn = UrlHelper.getSingleSignIn();
                model.returnUrl = UrlHelper.getReturnUrl();

                this.RMSService.getRhithmFeatures().subscribe(x => {
                    if (x.ssoSettings_UseClasslinkFromMongo) {
                        this._mountainTokenAuthService.externalAuthenticate(model)
                            .pipe(finalize(() => { /* abp.ui.clearBusy(); */ }))
                            .subscribe((result: any) => {
                                this.externalAuthenticateClassLinkCallback(result);
                            });
                    }
                    else {
                        this._mountainTokenAuthService.externalAuthenticate(model).pipe(finalize(() => { /* abp.ui.clearBusy(); */ }))
                            .subscribe((result: any) => {
                                this.externalAuthenticateClassLinkCallback(result);
                            });
                    }
                })
            });
        });
    }

    externalAuthenticateClassLinkCallback(result: any) {
        if (result.waitingForActivation) {
            // this._messageService.info('You have successfully registered. Waiting for activation!');
            return;
        }
        this.credentialsService.setCookie(
            'Abp.TenantId',
            result.tenantId.toString(),
            null,
        );
        this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
    }

    public cleverConnectLoginCallback(resp: any) {

        this.initExternalLoginProviders(() => {
            let openIdProvider = _.filter(this.externalLoginProviders, { name: 'Clever' })[0];
            let authConfig = this.getCleverConnectConfig(openIdProvider);

            this.oauthService.configure(authConfig);

            /*     abp.ui.setBusy(); */

            this.oauthService.tryLoginImplicitFlow().then(() => {
                let claims = this.oauthService.getIdentityClaims();

                this._activatedRoute.queryParams.subscribe((param) => {
                    let accessCode = param['code'];
                    const model = new ExternalAuthenticateModel();
                    model.authProvider = ExternalLoginProvider.CLEVER;
                    model.providerAccessCode = accessCode;
                    // model.providerKey = claims['sub'];
                    model.providerKey = "dummy-key";
                    model.appVersion = 2;
                    model.singleSignIn = UrlHelper.getSingleSignIn();
                    model.returnUrl = UrlHelper.getReturnUrl();

                    this.RMSService.getRhithmFeatures().subscribe(x => {
                        if (x.ssoSettings_UseCleverFromMongo) {
                            this._mountainTokenAuthService.externalAuthenticate(model)
                                .pipe(finalize(() => { /* abp.ui.clearBusy(); */ }))
                                .subscribe((result: any) => {
                                    this.externalAuthenticateCallback(result);
                                });
                        }
                        else {
                            this._mountainTokenAuthService.externalAuthenticate(model).pipe(finalize(() => { /* abp.ui.clearBusy(); */ }))
                                .subscribe((result: any) => {
                                    this.externalAuthenticateCallback(result);
                                });
                        }
                    })

                });
            });

        });
    }

    externalAuthenticateCallback(result: any) {
        if (result.statusCode == HttpStatusCode._400) {
            location.href = AppConsts.CleverRedirectUrl;
        }
        if (result.waitingForActivation) {
            // this._messageService.info('You have successfully registered. Waiting for activation!');
            return;
        }
        this.credentialsService.setCookie(
            'Abp.TenantId',
            result.tenantId.toString(),
            null,
        );
        this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
    }

    // externalCanvasAuthenticateAfterLogin(url?:string){
    //     const request = new CanvasAuthenticateModel();
    //     request.returnUrl = ""
    //     this._mountainCanvasAuthServiceProxy.connectCanvas(request).subscribe((result: CanvasAuthenticateResultModel) => {
    //         if(result.redirectUrl && result.redirectUrl !== ""){
    //             // new ScriptLoaderService().load(result.redirectUrl).then((res) => {
    //             // })
    //             location.href  = result.redirectUrl
    //             // const initialReturnUrl = UrlHelper.getReturnUrl();

    //         }
    //     });

    // }

    // connectCanvasCallback(state: string, code: string) {
    //     const request = new CanvasAuthenticateCallbackModel()
    //     request.code = code;
    //     request.state = state;
    //     this._mountainCanvasAuthServiceProxy.connectCanvasCallback(request).subscribe((result: CanvasAuthenticateResultModel) => {
    //         if(result){
    //             this._router.navigate(
    //                 ['/app/admin/tenantSettings'],{
    //                     queryParams: {
    //                         canvasRedirect: true
    //                     }
    //                 })
    //         }
    //     })
    // }


    /**
    * Microsoft login is not completed yet, because of an error thrown by zone.js: https://github.com/angular/zone.js/issues/290
    */
    // private microsoftLogin() {
    //     this._logService.debug(WL.getSession());
    //     const model = new ExternalAuthenticateModel();
    //     model.authProvider = ExternalLoginProvider.MICROSOFT;
    //     model.providerAccessCode = WL.getSession().access_token;
    //     model.providerKey = WL.getSession().id; // How to get id?
    //     model.singleSignIn = UrlHelper.getSingleSignIn();
    //     model.returnUrl = UrlHelper.getReturnUrl();

    //     this._mountainTokenAuthService.externalAuthenticate(model)
    //         .subscribe((result: ExternalAuthenticateResultModel) => {
    //             if (result.waitingForActivation) {
    //                 this._messageService.info('You have successfully registered. Waiting for activation!');
    //                 return;
    //             }

    //             this.login(result.accessToken, result.encryptedAccessToken, result.expireInSeconds, false, '', result.returnUrl);
    //         });
    // }

}