<div class="modal-container">
  <button
    class="modalCancelBtn close"
    (click)="closeModal()"
    mat-dialog-close
    align="end"
  >
    <i class="icon icon-e-remove"></i>
  </button>
  <!-- TODO: Update image -->
  <img src="assets/artboard-1-copy-44@2x.png" alt="Admin privilage" />
  <p>
    We're working on it! This feature is not yet available in Nucleus UI. You
    can switch to the Classic UI for access.
  </p>
  <button (click)="closeModal()" class="btn btn-primary">Got it</button>
</div>
