import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, finalize } from 'rxjs';
import { DistrictTenantListDto, IsTenantAvailableInput, IsTenantAvailableOutput, TenantAvailabilityState, TenantServiceProxy, UserServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { CredentialsService } from '../credentials.service';
import { UntypedFormGroup } from '@angular/forms';
import * as Swal from 'sweetalert2'
import { AccountAppServiceProxy, SendPasswordResetCodeInput } from 'src/shared/service-proxies/service-proxies-v2';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  model: SendPasswordResetCodeInput = new SendPasswordResetCodeInput();
  // @ViewChild('customMessage', { static: true }) customMessage: CustomMessageComponent;
  error$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  saving = false;
  tenantListCopy: DistrictTenantListDto[];
  userHasMultipleTenants: Boolean = false;
  tenantList: DistrictTenantListDto[];
  tenantName: any;
  selectedTenant: any = null;
  isTenantData = false;
  isForgotPasswordPass = false;
  isLoading: boolean = false
  isDistrictAccount = false;
  constructor(
    private _accountServiceMountain: AccountAppServiceProxy,
    private _userServiceProxyMountain: UserServiceProxy,
    private _tenantServiceProxyMountain: TenantServiceProxy,
    private _credentialsService: CredentialsService,
  ) {

  }
  onNextSubmit() {
    this.getTenantNameByEmailId()
  }
  save(): void {
    if (this.model.emailAddress.trim().length > 0) {
      this.saving = true;
      this._accountServiceMountain.sendPasswordResetCode(this.model)
        .pipe(finalize(() => { this.saving = false; }))
        .subscribe(() => {
          let msg = "Please check your email. If the address you entered is correct you will see a reset link there.";
          Swal.default.fire({
            title: 'Email sent',
            text: msg,
            icon: 'success',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Okay',
            confirmButtonColor: '#2693ff',
            iconColor: '#2693ff',
          }).then((result) => {
            window.location.href = window.location.origin;
          })
        });
    }
  }
  compareObjects(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.id === option2.id : option1 === option2;
  }
  getDisplayValue(option: any): string {
    return option ? option.name : ''; // Adjust the property based on your object structure
  }
  getDisplayValueById(id: any): string {
    return id ? this.tenantList.filter(x => x.id == id)[0].name : ''; // Adjust the property based on your object structure
  }
  getTenantNameByEmailId(): void {
    this.isLoading = true
    if (this.model.emailAddress) {
      this._userServiceProxyMountain
        .getTenantsByEmailAddress(this.model.emailAddress)
        .subscribe((result) => {
          if (result && result.length > 0) {
            this.isLoading = false;
            this.error$.next(false);
            let tenanatlist: any = [];
            this.tenantListCopy = result;
            if (result.length > 0) {
              result.forEach(item => {
                if (item.id == null) {
                  item.roleName = item.roleName == "DistrictOwner" ? "District Admin" : item.roleName;
                  tenanatlist.push({ id: null, name: `Login as ${item.roleName} ` });
                } else {
                  item.roleName = item.roleName == "Principal" ? "Campus Admin" : item.roleName;
                  tenanatlist.push({ id: item.id, name: `Login as ${item.roleName} from ${item.name}` });
                }
              })
            }
            this.tenantList = tenanatlist;

            this.isTenantData = true;
            if (this.tenantList.length > 1) {
              this.userHasMultipleTenants = true;
              this._credentialsService.setCookie('Abp.TenantId', this.tenantList[0].id, 30)
              this.isForgotPasswordPass = true;
            } else {
              this.userHasMultipleTenants = false;
              this.tenantName = this.tenantList[0].name;
              this._credentialsService.setCookie('Abp.TenantId', this.tenantList[0].id, 30)
            }
            if (this.tenantList.length == 1) {
              this.model.tenantId = this.tenantList[0].id;
              this.save();
              return;
            }

            if (this.tenantName != undefined) {
              this.save();
            }
          }
          else {
            this.isLoading = false;
            this.error$.next(true);
          }
        });
    } else {
      this.userHasMultipleTenants = false;
      this.isForgotPasswordPass = false;
      this.isTenantData = false;
    }
  }
  switchTenant(tenant: DistrictTenantListDto): void {
    this.model.tenantId = null
    let item = this.tenantListCopy.filter(x => x.id == tenant.id);
    let input = new IsTenantAvailableInput();
    if (!tenant.name) {
      this._credentialsService.setCookie('Abp.TenantId', undefined, 30)
      return;
    }

    if (tenant.name) {
      this.tenantName = tenant.name;
      input.name = tenant.name;
    } else if (tenant.tenancyName) {
      input.tenancyName = tenant.tenancyName;
    }

    if (item.length > 0) {
      let element = item[0];
      if (!element.name) {
        this.isDistrictAccount = true;
        this._credentialsService.setCookie('Abp.TenantId', undefined, 30)
        return;
      }
      input.name = element.name != null ? element.name : element.roleName;
    }
    if (input.name.trim().length > 0) {
      this.isDistrictAccount = false;
      this._tenantServiceProxyMountain
        .isTenantAvailable(input)
        .subscribe((result: IsTenantAvailableOutput) => {
          this.model.tenantId = result.tenantId;
          switch (result.state) {
            case TenantAvailabilityState._1:
              this._credentialsService.setCookie('Abp.TenantId', result.tenantId, 30)
              return;
            case TenantAvailabilityState._2:
              // this.message.warn(
              //   this.l("This school is not active. Please contact the school administrator.", tenant.tenancyName)
              // );
              break;
            case TenantAvailabilityState._3: //NotFound
              // this.message.warn(
              //   this.l(
              //     "There is no school defined with name {0}",
              //     tenant.tenancyName
              //   )
              // );
              break;
            case TenantAvailabilityState._4:
              // this.message.warn(
              //   this.l(
              //     "We are unable to switch to this school. Please contact the Rhithm team for further assistance.",
              //     tenant.tenancyName
              //   )
              // );
              break;
          }
        });
    }
  }
  onBack() {
    window.location.href = window.location.origin;
  }

}
