<div class="spinner-wrapper" *ngIf="!questionAndSelectedOptions?.questionName">
    <mat-spinner strokeWidth="2"></mat-spinner>

</div>
<div class="container-fluid" *ngIf="questionAndSelectedOptions?.questionName">
    <div class="main-header">
        <div class="header-wrapper">
            <div class="cncl-btn-box">
                <mat-icon (click)="closeModal()" svgIcon="close-icon" title="close"></mat-icon>
            </div>
            <div class="qst-icons-wrapper">
                <div class="question-icons">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <ng-container *ngFor="let item of questionType;let index = index">
                            <button type="button" class="btn p-0" [disabled]="selectedQuestionIndex !== index"> <img
                                    class="tabs" [src]="item.questionType.iconURL" width="45"
                                    ondragstart="return false;" />
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="question-count-text">
                <p>Questions {{selectedQuestionIndex + 1}} of {{totalCount}}</p>
            </div>
            <div *ngIf="questionAndSelectedOptions != null">
                <span *ngIf="textToSpeechChecked" class="question-type" toggleAudioButton
                    [audioText]="questionAndSelectedOptions.questionName" [isAudioOnText]="true"
                    [leftSpace]="questionAndSelectedOptions.questionName.length">
                    {{questionAndSelectedOptions?.questionName}}</span>
                <span *ngIf="!textToSpeechChecked"
                    class="question-type">{{questionAndSelectedOptions?.questionName}}</span>
            </div>
        </div>

    </div>
    <div class="main-body mt-4">
        <div class="question-opts-wrapper">
            <div class="question-opts">
                <ng-container
                    *ngFor="let questionOption of questionAndSelectedOptions?.questionOptionList; let i = index">
                    <div class="opt-wrapper"
                        (click)="questionOptionClick($event, i,questionAndSelectedOptions, questionOption)"
                        (dblclick)="questionOptionClick($event, i,questionAndSelectedOptions, questionOption)">
                        <div *ngIf="textToSpeechChecked" class="option_size"
                            [ngClass]="{'selected-Question':selectedOptionIndex === i}" toggleAudioButton
                            [audioText]="questionOption.name">
                            <div class="option_content">
                                <img class="" [src]="questionOption?.iconURL" ondragstart="return false;" />
                            </div>
                            <div class="text_set">
                                {{questionOption?.name}}
                            </div>
                        </div>
                        <ng-container *ngIf="!textToSpeechChecked">
                            <div class="option_size" [ngClass]="{'selected-Question':selectedOptionIndex === i}">
                                <div class="option_content">
                                    <img class="" [src]="questionOption?.iconURL" ondragstart="return false;" />
                                </div>
                                <div class="text_set">
                                    {{questionOption?.name}}

                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="isAllowComment" class="comment-area-wrapper mt-5">
            <div class="comment-area">
                <p class="comment-area-label">Anything you would like to add ? (Optional)</p>
            </div>
            <div class="comment-box">
                <textarea class="comment-textarea" rows="3" (change)="changeComment($event)" ondragstart="return false;"
                    ondrop="return false;" (keyup)="onWordCount($event)" [(ngModel)]="userComment" maxlength="2000"
                    onpaste="return false;">{{userComment}}</textarea>
            </div>
            <span class="comment-word-count">
                {{wordCount}}/2000
            </span>

        </div>
    </div>
    <div class=" main-footer mt-3">
        <div class="footer-wrapper">

            <button mat-icon-button [disabled]="selectedQuestionIndex <= 0"
                [ngClass]="{'button_disabled':isDisabled == true}" (click)="switchToPreviousQuestion()">
                <mat-icon class="next-back-arr-size" svgIcon="back-arr-icon" title="back"></mat-icon>
            </button>
            <button mat-icon-button [disabled]="disableNextButton" (click)="switchToNextQuestion()">
                <mat-icon class="next-back-arr-size" svgIcon="next-arr-icon" title="next"></mat-icon>
            </button>

        </div>
    </div>
</div>