<h3 translate></h3>
<h3 class="mb-3"><b>Reset Password</b></h3>
<p>Please enter your new password.</p>
<div class="form-style">
    <form novalidate>
        <div class="form-group">
            <label class="d-block">
                <input type="password" class="form-control" #password="ngModel" minlength="3" name="password"
                    [(ngModel)]="model.password" placeholder="Password" />
                <span hidden translate>Password</span>
            </label>
            <div class="text-danger" *ngIf="password.errors?.['minlength']">Passwords must be at least 3 characters.
            </div>
        </div>
        <div class="form-group">
            <label class="d-block">
                <input type="password" class="form-control" name="passwordRepeat" [(ngModel)]="confirmPass"
                    placeholder="Password (repeat)" />
                <span hidden translate>Password Repeat</span>
            </label>
            <div class="text-danger" *ngIf="confirmPass !== model.password">Passwords do not match!</div>
        </div>
        <div class="footer-btn-set">
            <button class="btn btn-secondary back-btn" name="backe" (click)="onBack()" type="button">
                <span translate style="color: #000000;"><i class="fa fa-arrow-left"></i>&nbsp;Back</span>
            </button>
            <button class="btn btn-primary" name="submit"
                [disabled]="password.errors?.['minlength'] || !model.password || confirmPass !== model.password"
                (click)="save()" type="button">
                <i class="fas fa-cog fa-spin" *ngIf="this.isLoading"></i>
                <span translate style="color: #fff;">Submit</span>
            </button>
        </div>
    </form>
</div>