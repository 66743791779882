<h3 translate></h3>
<img src="assets/rhithm-logo.svg" class="img-fluid py-3" style="width:50%" />
<div class="form-style">
  <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
    <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
      Username or password incorrect.
    </div>
    <div class="form-group">
      <label class="d-block">
        <input type="text" class="form-control" formControlName="username" autocomplete="username"
          [placeholder]="'Username' | translate" (change)="getTenantNameById()" />
        <span hidden translate>Username</span>
        <small [hidden]="
                    loginForm.controls.username.valid ||
                    loginForm.controls.username.untouched
                  " class="text-danger" translate>
          Username is required
        </small>
      </label>
      <label class="d-block">
        <div *ngIf="userHasMultipleTenants">
          <!-- <div class="form-control"> -->
          <mat-select [disableOptionCentering]="true" panelClass="myPanelClass1" class="form-control tenant-mat-select"
            formControlName="tenantname" [compareWith]="compareObjects">
            <mat-select-trigger>
              {{getDisplayValue(loginForm.controls.tenantname.value)}}
            </mat-select-trigger>
            <mat-option *ngFor="let em of tenantList" [value]="em" class="mat-opt"
              (click)="switchTenant(em)">{{getDisplayValue(em)}}</mat-option>
          </mat-select>
          <!-- </div> -->
        </div>
      </label>
      <label class="d-block">
        <input type="password" class="form-control" formControlName="password" autocomplete="current-password"
          [placeholder]="'Password' | translate" required />
        <span hidden translate>Password</span>
        <small [hidden]="
                    loginForm.controls.password.valid ||
                    loginForm.controls.password.untouched
                  " class="text-danger" translate>
          Password is required
        </small>
      </label>
      <div class="login-actions">
        <div class="">
          <label class="form-check-label">
            <input type="checkbox" formControlName="remember" />
            <span translate>Remember me</span>
          </label>
        </div>
        <div>
          <a routerLink="/account/forgot-password" id="forget-password" class="">{{"Forgot Password"}}</a>
        </div>
      </div>
    </div>
    <button class="btn btn-primary w-100" type="submit" [disabled]="loginForm.invalid || isLoading">
      <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
      <span translate style="color: #fff;">Login</span>
    </button>
  </form>
</div>