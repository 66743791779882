<div class="actions-container flex-between top-section">
  <div class="flex-between header-action">
    <a
      href="javascript:void(0)"
      *ngIf="isBackBtnActive"
      (click)="onGoBack()"
      class="back-icon"
    >
      <mat-icon svgIcon="back-arrow-icon" title="Go back"></mat-icon>
    </a>
    <h4 class="dark-title-text-2">{{ getBannerText() }}</h4>
    <p *ngIf="subText" class="h2subheadline pl-2">{{ getBannerSubText() }}</p>
  </div>
  <div class="header-buttons text-right">
    <button
      type="button"
      *ngIf="showCancelBtn"
      (click)="onGoBack()"
      class="button -disabled -secondary customBtn mb-1"
    >
      Cancel
    </button>

    <button
      type="submit"
      (click)="onSubmit()"
      [disabled]="disableBtn"
      class="button -primary customBtn mb-1"
    >
      Save
    </button>
  </div>
</div>
