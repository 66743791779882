<div class="modal-main bg-white">
    <div class="modal-body">
        <div class="modal-head px-4">
            <div class="main-header-wrapper">
                <h3 class="font-weight-bold mb-3 text-dark">Get my class in Rhithm</h3>
                <mat-icon class="mat-close" (click)="close()" svgIcon="close-icon" title="close"></mat-icon>
            </div>
            <h5 class="mt-3">Choose Participants</h5>
        </div>
        <div class="px-4 class-p-tree">
            <p-tree [value]="allParticipants" selectionMode="checkbox" [(selection)]="selectedParticipants"
                (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnSelect($event)"
                [(selection)]="selectedParticipants" [filter]="true"
                placeholder="Search for classroom or student"></p-tree>
        </div>
    </div>
    <div class="dialog-footer dialog-footer mx-4">
        <!-- <button type="button" class="btn btn-default" (click)="close()">{{"Cancel"}}</button>
        <button [disabled]="selectedParticipants && selectedParticipants.length == 0" (click)="save()"
            class="btn btn-primary">
            <span>Okay</span>
        </button> -->
        <div>
            <securly-button color="secondary" (clickEvent)="close()">Cancel</securly-button>
        </div>
        <div>
            <securly-button [disabled]="selectedParticipants && selectedParticipants.length == 0" (clickEvent)="save()"
                color="primary">
                Okay</securly-button>
        </div>
    </div>
</div>