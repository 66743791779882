

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { untilDestroyed } from '@core';
import { AuthenticationService } from '../authentication.service';
import { UiCustomizationSettingsDto, UserLoginInfoDto, UserServiceProxy, GetCurrentLoginInformationsOutputBasic, TokenAuthServiceProxy, AuthenticateModel, DistrictTenantListDto, IsTenantAvailableInput, IsTenantAvailableOutput, TenantAvailabilityState, TenantServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { LocalStorageService } from '@app/shared/local-storage.service';
import { CredentialsService } from '../credentials.service';
@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
})
export class LoginEmailComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: UntypedFormGroup;
  isLoading = false;
  isLoadUI: boolean = false;
  tenantListCopy: DistrictTenantListDto[];
  tenantList: DistrictTenantListDto[];
  userHasMultipleTenants: boolean = false;
  tenantName: string;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private UserServiceProxy: UserServiceProxy,
    private _localStorage: LocalStorageService,
    private _tenantServiceProxy: TenantServiceProxy,
    private credentialsService: CredentialsService
  ) {
    window.addEventListener("load", event => {
      var image = document.querySelector('img');
      var load = image.complete;
      this.isLoadUI = image.complete

    })
    this.createForm();
    _localStorage.removeItem('user-email')
    _localStorage.removeItem('user-name')
  }

  ngOnInit() {
    // ;
    // setTimeout(async () => {
    //   if (AppConsts.isFeatureEnabled(FeatureFlagEnum.UseSecurlySso)) {
    //     ;
    //     await this.authenticationService.SecurelyIdentifyAsync()
    // }
    // }, 500);


  }

  ngOnDestroy() { }

  login() {
    this.isLoading = true;
    const login$ = this.authenticationService.login(this.loginForm.value);
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }), untilDestroyed(this))
      .subscribe({
        next: (v) => {
          window.location.href = '/'
          // this.router.navigate(['/dashboard/main'], { replaceUrl: true });
          // setTimeout(() => {
          //   window.location.reload()
          // }, 200);
        },
        error: (e) => this.error = e,
        complete: () => console.info('complete')
      })
  }



  getTenantNameById(): void {
    var emailAddress = this.loginForm.value.username;
    if (emailAddress) {
      this.UserServiceProxy
        .getTenantsByEmailAddress(emailAddress)
        .subscribe((result) => {
          if (result && result.length > 0) {
            let tenanatlist: any = [];

            this.tenantListCopy = result;
            if (result.length > 0) {
              result.forEach(item => {
                if (item.id == null) {
                  item.roleName = item.roleName == "DistrictOwner" ? "District Admin" : item.roleName;
                  tenanatlist.push({ id: null, name: `Login as ${item.roleName} ` });
                } else {
                  item.roleName = item.roleName == "Principal" ? "Campus Admin" : item.roleName;
                  tenanatlist.push({ id: item.id, name: `Login as ${item.roleName} from ${item.name}` });
                }
              })
            }
            this.tenantList = tenanatlist;
            if (this.tenantList.length > 1) {
              this.userHasMultipleTenants = true;
              // abp.multiTenancy.setTenantIdCookie(null);
              // abp.multiTenancy.setTenantIdCookie(
              //     this.tenantList[0].id
              // );
              if (this.tenantList[0].id) {
                this.credentialsService.setCookie('Abp.TenantId', this.tenantList[0].id, 30)
              }
              this.loginForm.get('tenantname').setValue(this.tenantList[0]);
            } else {
              this.userHasMultipleTenants = false;
              this.loginForm.get('tenantname').setValue(this.tenantList[0]);
              // abp.multiTenancy.setTenantIdCookie(
              //     this.tenantList[0].id
              // );
              if (this.tenantList[0].id) {
                this.credentialsService.setCookie('Abp.TenantId', this.tenantList[0].id, 30)
              }

            }
          } else {
            this.userHasMultipleTenants = false;
            // this.processEmailAddressFromCookie();
          }
        });
    } else {
      this.userHasMultipleTenants = false;
      // this.processEmailAddressFromCookie();
    }
  }
  compareObjects(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.id === option2.id : option1 === option2;
  }
  getDisplayValue(option: any): string {
    return option ? option.name : ''; // Adjust the property based on your object structure
  }

  switchTenant(tenant: DistrictTenantListDto): void {
    ;
    let item = this.tenantListCopy.filter(x => x.id == tenant.id);
    let input = new IsTenantAvailableInput();
    if (tenant.name) {
      input.name = tenant.name;
    } else if (tenant.tenancyName) {
      input.tenancyName = tenant.tenancyName;
    }
    if (item.length > 0) {
      let element = item[0];
      if (!element.name) {
        // abp.multiTenancy.setTenantIdCookie(undefined);
        return;
      }
      input.name = element.name != "" ? element.name : element.roleName;
    }
    if (input.name != null) {
      ;
      this._tenantServiceProxy
        .isTenantAvailable(input)
        .subscribe((result: IsTenantAvailableOutput) => {
          switch (result.state) {
            case TenantAvailabilityState._1:
              // abp.multiTenancy.setTenantIdCookie(result.tenantId);
              if (result.tenantId) {
                this.credentialsService.setCookie('Abp.TenantId', result.tenantId, 30)
              }
              return;
            case TenantAvailabilityState._2:
              // this.message.warn(
              //     this.l("This school is not active. Please contact the school administrator.", tenant.tenancyName)
              console.log("This school is not active. Please contact the school administrator.", tenant.tenancyName)
              // );
              break;
            case TenantAvailabilityState._3: //NotFound
              // this.message.warn(
              //     this.l(
              //         "There is no school defined with name {0}",
              //         tenant.tenancyName
              //     )
              // );
              console.log("There is no school defined with name {0}", tenant.tenancyName)
              break;
            case TenantAvailabilityState._4:
              // this.message.warn(
              //     this.l(
              //         "We are unable to switch to this school. Please contact the Rhithm team for further assistance.",
              //         tenant.tenancyName
              //     )
              // );
              console.log("We are unable to switch to this school. Please contact the Rhithm team for further assistance.", tenant.tenancyName)
              break;
          }
        });
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      tenantname: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }

}
