<div
  class="chat-messages"
  [ngClass]="{ class: isClassChat, 'student-lyt': !!isStudentClassChat }"
  #chatMessageContainer
>
  <div class="chat-date" [ngClass]="{ class: isClassChat }">
    <span *ngIf="selectedChat?.startDate">
      {{ selectedChat?.startDate | date: 'longDate' }}
      <!-- {{ selectedChat?.startDate | date: 'shortTime' }} -->
    </span>
  </div>
  <div class="chat" *ngFor="let message of messages">
    <div class="messages" [ngClass]="message?.fromTeacher ? 'mine' : 'yours'">
      <div class="message last">
        {{ message.text }}
        <span class="date-received" *ngIf="!message?.fromTeacher">{{
          message?.dateReceived | date: 'shortTime'
        }}</span>
        <span class="date-sent" *ngIf="message?.fromTeacher">{{
          message?.dateSent | date: 'shortTime'
        }}</span>
      </div>
    </div>
  </div>
</div>
