// import { RDateTimeService } from "@app/shared/helper/RDateTimeService";
import { FeatureFlagEnum, RhithmFeatureFlagDto } from "./FeatureFlags";

export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = "{TENANCY_NAME}";

    static rhithmFeatureFlags: RhithmFeatureFlagDto[];
    static DefaultAssessmentTypeId: number;
    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static mountainRemoteServiceBaseUrl: string;
    static mountainRemoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static toggleRedirectUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static dashboardVideoUrl: string;
    static flowRegulatorKey: string;
    // Rhithm Canvas dto
    static canvassettings: any = {};
    static gainSightKey: string;
    static readonly google = {
        tagManagerAccount: "",
    };
    static readonly rmsUrls = {
        metricReaderServiceUrl: '',
        loginServiceUrl: '',
        loginServiceAwsUrl: '',
        featuresServiceUrl: ''
    }

    static readonly securlySso = {
        baseUrl: '',
        clientId: '',
    };

    static subscriptionExpireNootifyDayCount: number;

    static readonly googleMapApiKey = "AIzaSyCG0UzJ0lNngdjKVkEixslgUek1WcP0_JU";
    static readonly appBaseUrlProd = "https://portal.rhithm.app";

    static readonly azureTextToSpeech = {
        subscriptionKey: "",
        serviceRegion: "",
        speechTokenbaseUrl: "",
    };

    static readonly firebaseConfig = {
        apiKey: "",
        authDomain: "",
        databaseURL: "",
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: "",
        measurementId: "",
    };

    static readonly safariWebPushConfig = {
        webserviceURL: "",
        websitePushID: "",
    };

    static localeMappings: any = [];

    static readonly pusher = {
        cluster: "",
        app_key: "",
    };

    // App version
    static appVersion: string;
    static appEnvironmentName: string;

    static readonly userManagement = {
        defaultAdminUserName: "admin",
    };

    static readonly localization = {
        defaultLocalizationSourceName: "Rhithm",
    };

    static readonly authorization = {
        encrptedAuthTokenName: "enc_auth_token",
        // userNameOrEmailAddress: "username_or_email_address",
        userNameOrEmailAddress: "_ruoe",
    };

    static readonly grid = {
        defaultPageSize: 10,
    };

    // Rhithm Session Statuses
    static readonly sessionStatusStarted = false;
    static readonly sessionStatusClosed = true;
    static readonly sessionStartedNotificationName = "App.Rhithm.StartSession";
    static readonly sessionEndNotificationName = "App.Rhithm.EndSession";
    static readonly showActivityNotificationName = "App.Rhithm.ShowActivity";
    static readonly riskAlertNotificationName =
        "App.Rhithm.SendRiskAlertNotification";
    static readonly commentNotificationName =
        "App.Rhithm.SendAssessmentCommentNotification";
    static readonly sendHeaderNotificationName =
        "App.Rhithm.SendHeaderNotification";
    static readonly headerNotification = "HeaderNotification";

    static readonly SendAddedAsNotification =
        "App.Rhithm.SendAddedAsRiskAlertWatcherNotification";

    // Rhithm Session Types
    static readonly sessionTypeSelf = "Myself";
    static readonly sessionTypeDescendant = "Descendant";
    static readonly sessionTypeBoth = "Both";
    static readonly sessionTypeGroup = "Group";

    // Rhithm Session Option Types
    static readonly sessionOptionPlayOnAll =
        "Play Activity On All Participants Devices";
    static readonly sessionOptionPlayOnMy = "Play Activity On My Device";
    static readonly sessionOptionLogoutParticipants =
        "Log participants out after activity";
    static readonly logOutOptionId = 3;
    //RiskAlert
    static readonly riskAlertQuickViewMaxLength = 3;

    // Rhithm Session Sub Option
    static readonly individualizedActivity = "Individualized Activity";
    static readonly groupActivity = "Group Activity";
    static readonly individualizedActivityId = 1;
    static readonly groupActivityId = 2;

    // Permissions
    static readonly girMessage = "You’re about to disable GIR Assessment for this district. Please make sure that this district has a default assessment turned on for its students.";
    static readonly simpleTwoMessage = "You’re about to disable Simple Two Assessment for this district. Please make sure that this district has a default assessment turned on for its students.";
    static readonly girMessageForAllSchools = "You’re about to disable GIR Assessment for All Schools. Please make sure that All Schools  has a default assessment turned on for its students.";
    static readonly simpleTwoMessageForAllSchools = "You’re about to disable Simple Two Assessment for All Schools. Please make sure that All Schools has a default assessment turned on for its students.";
    static readonly girMessageSingleSchool = "You’re about to disable GIR Assessment for this School. Please make sure that this School has a default assessment turned on for its students.";
    static readonly simpleTwoMessageSingleSchool = "You’re about to disable Simple Two Assessment for this School. Please make sure that this School has a default assessment turned on for its students.";

    // Survey
    static readonly typeSurvey = 2
    static readonly Districtgroups = "Districtgroups"
    static readonly forAdminsFromDistrict = "forSchools"
    static readonly Principalgroups = "Principalgroups"

    // General
    static readonly underscore = "_";
    static readonly desh = "-";
    static readonly pageNumberOne = 1;
    static readonly restrictedBasicAssessmentDistrictId = 20317;
    static readonly restrictedBasicAssessmentDistrictIdWalkerCounty = 20148;
    static readonly restrictedBasicAssessmentDistrictIdDCMO = 20361;
    static readonly District_DallasISD = 20099;
    static readonly count9 = 9;
    static readonly count12 = 12;

    // Assessment Type Ids
    static readonly getInRhithmSimpleTwoQuest = 10032;

    // static readonly utcTZDifference = RDateTimeService.getUtcTzDifference();
    static readonly dateTimeFormat = "YYYY/MM/DD HH:mm";
    static readonly dateTimeFormatUsVersion = "MM/DD/YYYY HH:mm";

    // Providers
    static readonly classLink = "ClassLink";
    static readonly Clever = "Clever";
    static readonly CleverRedirectUrl = "https://clever.com/login";
    static readonly GoogleClassroom = "GoogleClassroom";
    static readonly Aequitas = "Aequitas";

    // CustomeMessage
    static readonly info = "Info";
    static readonly success = "Success";
    static readonly warn = "Warning";
    static readonly error = "Error";
    static readonly confirm = "Confirm";
    static readonly block = "Block";

    static readonly listView = "listView";
    static readonly diverseListView = "diverseListView";
    static readonly completionListView = "completionListView";
    static readonly completionDiverseListView = "completionDiverseListView";
    static readonly exportingListView = `You're exporting the current list view for`;

    static readonly shcoolAlreadyExist =
        "Following schools are already in these districts.";

    // Custom message events
    static readonly backspace = "Backspace";

    // Rhithm Session Flow Types
    static readonly createSessionSelectedTeacherOrStaff =
        "createSessionSelectedTeacherOrStaff";
    static readonly inviteSessionForSelectedPrincipalAndDistrict =
        "inviteSessionForSelectedPrincipalAndDistrict";
    static readonly inviteSessionForSelectedPrincipal =
        "inviteSessionForSelectedPrincipal";
    static readonly startRhithmSessionForBoth = "startRhithmSessionForBoth";
    static readonly onClassSelect = "onClassSelect";
    static readonly onGroupSelect = "onGroupSelect";
    static readonly deletingUser = "Deleting User";

    // tree view types

    static readonly Teacher = "Teacher";
    static readonly Principal = "Principal";
    static readonly Student = "Student";
    static readonly Class = "Class";
    static readonly District = "DistrictMe";
    static readonly DistrictOwner = "DistrictOwner";
    static readonly Grade = "Grade";
    static readonly Staff = "Staff";
    static readonly School = "School";
    static readonly Group = "Group";
    static readonly GradeTeachers = "GradeTeachers";
    static readonly GradeStudents = "GradeStudents";

    static readonly tabSchools = "Schools";
    static readonly tabStaff = "Staff";
    static readonly tabAdmin = "Admins";
    static readonly tabGrades = "Grades";
    static readonly tabTeachers = "Teachers";
    static readonly tabStudents = "Students";
    static readonly tabMe = "Me";
    static readonly tabClasses = "Classes";
    static readonly tabResponse = "Response Groups";
    static readonly tabGroups = "Groups";

    static readonly Schedule = "Schedule";

    // sortBy
    static readonly Score = "Score";
    static readonly Alphabetically = "Alphabetically";
    static readonly AlphabeticallyLastName = "AlphabeticallyLastName";

    // sortField
    static readonly score = "score";
    static readonly displayName = "displayName";

    static readonly Admin = "Admin";

    // widget
    static readonly hideWidget = "hideWidget";

    // Define route
    static readonly loginUrl = "/account/login";

    static readonly hostUrl = "/account/host";
    static readonly loginPassUrl = "/account/loginpass";
    static readonly loginEmail = "/account/loginemail";

    static readonly Freemium = "Freemium";
    //Header Notification Tabs
    static readonly TabSession = "sesion";
    static readonly TabEvent = "event";
    static readonly TabAlert = "alert";
    static readonly TabComment = "comment";

    static readonly IncreaseHeaderNotificationCountOnScroll = 5;

    //event scheduling
    static readonly NonRecurringEvent = "#0c3b49";
    static readonly RecurringEvent = "#01657d";
    static readonly DisabledEvent = "#c3c3c3";

    //zones
    static readonly VeryLowFrequency = "Very Low Energy";
    static readonly LowFrequency = "Low Energy";
    static readonly OptimalFrequency = "Optimal Energy";
    static readonly HighFrequency = "High Energy";
    static readonly VeryHighFrequency = "Very High Energy";

    //zones tooltip
    static readonly VeryLowFrequencyToolTip =
        "Answer at this end of the spectrum indicate experiencing strong low energy states of dysregulation. Options include having a Dull mind, experiencing Exhaustion or Hunger, and rating one’s social life as Bad.";
    static readonly LowFrequencyToolTip =
        "Answers in this zone indicate experiencing low energy states that are not ideal for learning or well-being. Options include a Foggy mental state, Sleepiness, Sadness, physical Discomfort, and social Disconnection.";
    static readonly OptimalFrequencyToolTip =
        "This is the ideal learning zone! Answers in this zone indicate optimal states of regulation for effective learning and well-being. Answers include being Clear, Focused, Content, Happy, and feeling physically and socially Good or Great!";
    static readonly HighFrequencyToolTip =
        "Answers in this zone indicate a cautionary space of higher energy dysregulation. Answers include being Distracted, Hyper, Anxious, having physical Injuries, or social Disagreements.";
    static readonly VeryHighFrequencyToolTip =
        "Answers at this end of the spectrum indicate a very high energy level of dysregulation. Answers include Racing thoughts, Out of Control energy, Anger, Sickness, or social Conflict.";

    static readonly questionThresholdLenght = 45;
    // language source name
    static readonly SourceNameRhithm = "Rhithm";

    static readonly getInRhithimBasic = "get in rhithm basic";

    static readonly rmsFunctionPath = {
        metricReaderService: {
            IsHealthy:
                "api/IsHealthy?code=rdYPwjC7e3/tr0bcNTU6CqoTTLePB/6Is97zzuaZTzHDqBr6YASTAA==",
        },
        loginService: {
            SyncSqlMongo_SyncTenants: "api/SyncSqlMongo/SyncTenants",
            SyncSqlMongo_SyncAll: "api/SyncSqlMongo/SyncAll",
            Login_ExternalAuthenticate: "api/Login/ExternalAuthenticate",
            Login_Authenticate: "api/Login/Authenticate",
        },
        featuresService: {
            GetRhithmFeatures: "/api/GetRhithmFeatures",
        },
    };

    static readonly Labels = {
        Activate: "Activate",
        Deactivate: "Deactivate",
    };
    static uploadRequest: boolean = false;
    static isTenant: boolean = false;
    // Selected language
    static readonly selectedEnglish = "English";
    static readonly selectedSpanish = "Español (Spanish)";


    static isFeatureEnabled(feature: FeatureFlagEnum): boolean {
        try {
            var selectedFeature = this.rhithmFeatureFlags.find(
                (f) => f.feature === feature
            );
            // if (feature == 0) {
            //     selectedFeature.isEnabled = true;
            // }
            return selectedFeature.isEnabled.toString() == "true";
        } catch (exception) {
            console.error(`something went wrong ... ${exception}`);
            return false;
        }
    }
}
