import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordInput, ResetPasswordOutput } from './../../../shared/service-proxies/service-proxies';
import { AccountAppServiceProxy, PasswordComplexitySetting, ResolveTenantIdInput, ProfileServiceProxy as ProfileServiceProxyMountain } from './../../../shared/service-proxies/service-proxies';

import { finalize } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { error } from 'console';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {

  model: ResetPasswordInput = new ResetPasswordInput();
  passwordComplexitySetting: PasswordComplexitySetting = new PasswordComplexitySetting();
  saving = false;
  confirmPass: '';
  isLoading: boolean = false;
  constructor(
    private _accountServiceMountain: AccountAppServiceProxy,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _loginService: LoginService,
    private _appSession: AppSessionService,
    private _profileServiceMountain: ProfileServiceProxyMountain
  ) {
  }

  ngOnInit(): void {
    this._profileServiceMountain.getPasswordComplexitySetting().subscribe(result => {
      this.passwordComplexitySetting = result.setting;
    });

    if (this._activatedRoute.snapshot.queryParams['c']) {
      this.model.c = this._activatedRoute.snapshot.queryParams['c'];

      this._accountServiceMountain.resolveTenantId(new ResolveTenantIdInput({ c: this.model.c })).subscribe((tenantId) => {
        this._appSession.changeTenantIfNeeded(
          tenantId
        );
      });
    } else {
      this.model.userId = this._activatedRoute.snapshot.queryParams['userId'];
      this.model.resetCode = this._activatedRoute.snapshot.queryParams['resetCode'];

      this._appSession.changeTenantIfNeeded(
        this.parseTenantId(
          this._activatedRoute.snapshot.queryParams['tenantId']
        )
      );
    }
  }

  save(): void {
    this.saving = true;
    this.isLoading = true;
    this._accountServiceMountain.resetPassword(this.model)
      .pipe(finalize(() => { this.saving = false; }))
      .subscribe((result: ResetPasswordOutput) => {
        this.isLoading = false;
        this._router.navigate(['account/login']);
        if (!result.canLogin) {
          this._router.navigate(['account/login']);
          return;
        }

        // Autheticate
        this.saving = true;
        this._loginService.authenticateModel.userNameOrEmailAddress = result.userName;
        this._loginService.authenticateModel.password = this.model.password;
        this._loginService.authenticate(() => {
          this.saving = false;
        });
      }, error => {
        this.isLoading = false;
      });
  }

  parseTenantId(tenantIdAsStr?: string): number {
    const parsedId = tenantIdAsStr ? parseInt(tenantIdAsStr) : undefined;
    return isNaN(parsedId) ? undefined : parsedId;
  }
  onBack() {
    window.location.href = window.location.origin;
  }
}
