import { createAsyncAction } from "@app/util";
import { createAction, props } from "@ngrx/store";
import { SessionAssessmentTypeDto } from "src/shared/service-proxies/service-proxies";
import { DashboardDropdownNameAndvalueDto } from "src/shared/service-proxies/service-proxies-v2";

export const updateDashboardSortData = createAction(
  '[Class] Update class sort data',
  props<{ direction: boolean; columns: string }>()
);
export const loadDistrictAssessmentType = createAsyncAction(
  '[District Assessment Type] Get list of DistrictAssessmentType',
  props<any>(),
  props<{ items: SessionAssessmentTypeDto[] }>(),
  props<{ message: string }>()
);
export const loadTenantAssessmentType = createAsyncAction(
  '[Tenant Assessment Type] Get list of TenantAssessmentType',
  props<any>(),
  props<{ items: SessionAssessmentTypeDto[] }>(),
  props<{ message: string }>()
);

export const loadViewType = createAsyncAction(
  '[School list type] get list of viewType',
  props<any>(),
  props<{ items: DashboardDropdownNameAndvalueDto[] }>(),
  props<{ message: string }>()
);
export const loadTeacherSearchType = createAsyncAction(
  '[Teacher Search Type] Get list of TeacherSearchType',
  props<any>(),
  props<{ items: SessionAssessmentTypeDto[] }>(),
  props<{ message: string }>()
);
export const loadPrincipalSearchType = createAsyncAction(
  '[Principal Search Type] Get list of PrincipalSearchType',
  props<any>(),
  props<{ items: SessionAssessmentTypeDto[] }>(),
  props<{ message: string }>()
);
export const loadDistrictSearchType = createAsyncAction(
  '[District Search Type] Get list of DistrictSearchType',
  props<any>(),
  props<{ items: SessionAssessmentTypeDto[] }>(),
  props<{ message: string }>()
);

export const loadDashboardSessionTable = createAsyncAction(
  '[District Search Type] Get list of DashboardSessionTable',
  props<any>(),
  props<{ items: any[] }>(),
  props<{ message: string }>()
)
export const loadDashboardSessionTableV2 = createAsyncAction(
  '[District Search Type] Get list of DashboardSessionTableV2',
  props<any>(),
  props<{ items: any[] }>(),
  props<{ message: string }>()
)

export const loadAssessmentQuestionTypesWithOptions = createAsyncAction(
  '[District Search Type] Get list of assessmentQuestionTypesWithOptions',
  props<any>(),
  props<{ items: any[] }>(),
  props<{ message: string }>()
)

export const exportDashboardSessionTable = createAsyncAction(
  '[District Search Type] Get File of  DashboardSessionTable',
  props<any>(),
  props<{ items: any[] }>(),
  props<{ message: string }>()
)
export const loadClassesByStudentId = createAsyncAction(
  '[Load students classes] load classes by student id',
  props<any>(),
  props<{ items: any[] }>(),
  props<{ message: string }>()
)
