import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Input,
  AfterContentInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, AfterContentInit {
  range: UntypedFormGroup;
  @Input() defaultDate: { start: Date; end: Date };
  @ViewChild('datepickerFooter', { static: false })
  datepickerFooter: ElementRef;
  @ViewChild('picker', { static: false }) datepicker: MatDatepicker<any>;
  @Output() dateRangeChange = new EventEmitter<any>();
  constructor(private formBuilder: UntypedFormBuilder) {
    this.range = this.formBuilder.group({
      start: [''],
      end: [''],
    });
  }

  ngOnInit() {}
  ngAfterContentInit() {
    if (this.defaultDate?.start && this.defaultDate?.end) {
      this.range.setValue({
        start: this.defaultDate.start,
        end: this.defaultDate.end,
      });
    }
  }

  requireBothInputs() {
    if (this.startFormControl.value && !this.endFormControl.value) {
      this.endFormControl.addValidators(Validators.required);
      this.endFormControl.updateValueAndValidity();
    } else if (this.endFormControl.value && !this.startFormControl.value) {
      this.startFormControl.addValidators(Validators.required);
      this.startFormControl.updateValueAndValidity();
    } else {
      this.endFormControl.clearValidators();
      this.startFormControl.clearValidators();
      this.startFormControl.updateValueAndValidity();
      this.endFormControl.updateValueAndValidity();
    }
  }

  onDateChange(type: string, event: any) {
    this.requireBothInputs();
    this.dateRangeChange.emit({ type, event });
  }

  clear() {
    this.range.setValue({
      start: '',
      end: '',
    });
    const param = { type: '', event: {} };
    this.requireBothInputs();
    this.dateRangeChange.emit(param);
    this.datepicker.close();
  }

  onOpen() {
    this.appendFooter();
  }

  private appendFooter() {
    const matCalendar = document.getElementsByClassName(
      'mat-datepicker-content'
    )[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }

  datePickerValid(type: string): boolean {
    if (
      type === 'start' &&
      this.range.dirty &&
      this.startFormControl.invalid &&
      ((!this.startFormControl.value && this.endFormControl.value) ||
        this.startFormControl.invalid)
    ) {
      return false;
    }
    if (
      type === 'end' &&
      this.range.dirty &&
      ((this.startFormControl.value && !this.endFormControl.value) ||
        this.endFormControl.invalid)
    ) {
      return false;
    }
    return true;
  }

  datePickerEndBeforeStart() {
    return (
      Date.parse(this.endFormControl.value) <
      Date.parse(this.startFormControl.value)
    );
  }

  datePickerInputBlur() {
    if (!this.startFormControl.value && !this.endFormControl.value) {
      this.startFormControl.markAsUntouched();
      this.endFormControl.markAsUntouched();
    }
  }
  get startFormControl() {
    return this.range.controls.start;
  }
  get endFormControl() {
    return this.range.controls.end;
  }
  get dateRangeSelected(): boolean {
    return this.range.value.start && this.range.value.end;
  }
}
