import { createAction, props } from "@ngrx/store";
import { createAsyncAction } from "./util";

export const loadColumnSettings = createAsyncAction(
  '[Table] Load Grid Column Settings',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()
);
export const updateColumnSettings = createAsyncAction(
  '[Table] Update Grid Column Settings',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()
);
export const resetColumnSettings = createAsyncAction(
  '[Table] Reset Grid Column Settings',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()
);
export const resetTable = createAction(
  '[Users] Reset Table Data',
  props<any>()
);
export const isLoginLoader = createAction(
  '[Login] Login Loader',
  props<any>()
);
export const getAppConfig = createAction(
  '[App Config] Get Appconfig Data',
  props<any>(),
);
export const loadCurrentLoginInfo = createAction(
  '[Current Login Info] Get Current Login Data',
  props<any>(),

);
export const loadUserConfiguration = createAction(
  '[User Configuration] Get User Configuration Data',
  props<any>(),
);
export const changeLanguage = createAsyncAction(
  '[Change Language] Change User Language',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()

);
export const updateAppVersion = createAsyncAction(
  '[Update App Version] Update App Version',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()

);