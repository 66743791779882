import { finalize, switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Credentials, CredentialsService } from './credentials.service';
import { AuthenticateModel, AuthenticateResultModel, RhithmCookieUserInfoDto, TokenAuthServiceProxy, UserServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { UrlHelper } from '@app/shared/helpers/UrlHelper';
import { Router } from '@angular/router';
import { untilDestroyed } from '@app/@core';
import { AppConsts } from 'src/shared/AppConsts';
// import { TokenService } from 'abp-ng2-module';
import * as SecurlyAuth from '../../assets/securlyPlatform.js';
import { XmlHttpRequestHelper } from 'src/shared/helpers/XmlHttpRequestHelper';
import { FeatureFlagEnum } from 'src/shared/FeatureFlags';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  rememberMe: boolean;
  error: string | undefined;
  rhithmUserInfoCookieName: string = "RhithmUserInfo";
  SecurlyToken: string = "SecurlyToken";


  constructor(
    private credentialsService: CredentialsService,
    private _tokenAuthServiceProxy: TokenAuthServiceProxy,
    private _router: Router,
    private _userServiceProxy: UserServiceProxy,
    // private _tokenService: TokenService,
    // private _utilsService: UtilsService,
  ) { }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */


  login(context: LoginContext): Observable<Credentials> {
    // Replace by proper authentication call
    let AuthModel: AuthenticateModel = new AuthenticateModel();
    AuthModel.password = context.password;
    AuthModel.twoFactorRememberClientToken = null;
    AuthModel.singleSignIn = UrlHelper.getSingleSignIn();
    AuthModel.returnUrl = UrlHelper.getReturnUrl();
    AuthModel.rememberClient = false;
    AuthModel.userNameOrEmailAddress = context.username;

    return this._tokenAuthServiceProxy.authenticate(AuthModel).pipe(
      switchMap((authenticateResult: AuthenticateResultModel) => {

        let tokenExpireDate = (new Date(new Date().getTime() + 1000 * authenticateResult.expireInSeconds))
        let data = {
          tokenExpireDate: tokenExpireDate,
          accessToken: authenticateResult.accessToken
        };

        this.credentialsService.setCredentials(data, context.remember);

        return of(data);
      }),
      catchError((err: any) => {
        alert("error");
        return of(null); // You can handle the error case here
      })
    );
  }



  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */


  logout(reload?: boolean, returnUrl?: string) {
    return new Promise((resolve, reject) => {
      let getTenantIdCookie = this.credentialsService.getCookie('Abp.TenantId')
      let customHeaders = {
        'Abp.TenantId': getTenantIdCookie
      };
      XmlHttpRequestHelper.ajax(
        true,
        'GET',
        AppConsts.mountainRemoteServiceBaseUrl + '/api/TokenAuth/LogOut',
        customHeaders,
        null,
        () => {
          // abp.auth.clearToken();
          this.credentialsService.setCredentials(null);
          this.credentialsService.removeCookie('RhithmUserInfo')
          this.credentialsService.removeCookie('securly_token')
          if (returnUrl) {
            location.href = returnUrl;
          } else {
            if (AppConsts.isFeatureEnabled(FeatureFlagEnum.UseSecurlySso)) {
              this.SecurelyInitAsync().then(() => {
                // abp.auth.clearToken();
                this.credentialsService.setCredentials(null);
                this.credentialsService.removeCookie('RhithmUserInfo')
                this.credentialsService.removeCookie('securly_token')
                this.setRhithmloginInfoToCookie(null);

                SecurlyAuth.Logout().then(() => {
                  setTimeout(() => {
                    location.href = '';
                  }, 1500);
                });
              });
            }
            else {
              location.href = '';
            }

          }
          resolve(true);
        }
      );

    });
  }

  /**
   * Gets the XSRF-TOKEN value from the browser cookies
   * @returns XSRF-TOKEN cookie value
   */
  getXSRFToken(): string {
    let XSRFToken = '';
    const value = `; ${document.cookie}`;
    const parts = value.split(`; XSRF-TOKEN=`);
    if (parts.length === 2) {
      XSRFToken = parts.pop().split(';').shift();
    }
    return XSRFToken;
  }

  async SecurelyInitAsync() {

    // Set your product's oauth clientid here - "securly_example_app" will only be allowed for testing
    //temp do this 
    await SecurlyAuth.Init(AppConsts.securlySso.clientId, AppConsts.securlySso.baseUrl);

  }
  getRhithmloginInfoFromCookie(): RhithmCookieUserInfoDto {
    var cookieJsonValue = this.credentialsService.getCookie(this.rhithmUserInfoCookieName);
    cookieJsonValue = decodeURIComponent(cookieJsonValue)
    if (!cookieJsonValue) {
      return null;
    } else {
      let cookieUserInfo = RhithmCookieUserInfoDto.fromJS(JSON.parse(cookieJsonValue));

      if (cookieUserInfo.userId > 0) {
        return cookieUserInfo;
      } else {
        return null;
      }
    }
  }

  setRhithmloginInfoToCookie(cookieValue: RhithmCookieUserInfoDto): void {

    if (cookieValue === null) {
      this.credentialsService.removeCookie(this.rhithmUserInfoCookieName);
    }
    else {
      this.credentialsService.setCookie
        (
          this.rhithmUserInfoCookieName,
          JSON.stringify(cookieValue),
          (new Date().getTime() + 365 * 86400000), // 1 year
        );
    }
  }

  async SecurelyIdentifyAsync() {
    try {
      await this.SecurelyInitAsync();
      if (location.href.indexOf('account/user-not-exist') > 0) {
        return;
      }

      //if user coming from clever impersonate then do not check securly token instead direct use rhithm token            
      if (location.href.indexOf('account/login?provider=clever') > 0) {

        console.log('redirection for clever impersonate')
        return;
      }
      // Set your product's oauth clientid here - "securly_example_app" will only be allowed for testing

      //check securly Cookie
      var securlyTokenInfo = await SecurlyAuth.Identify();
      var securlyTokenEmailAddress = securlyTokenInfo.email;

      //check Rhithm Cookie
      var rhithmCookieInfo = this.getRhithmloginInfoFromCookie();

      var getDistrictId = this.credentialsService.getCookie('Abp.districtId')
      var getTenantId = this.credentialsService.getCookie('Abp.TenantId')
      var districtId = parseInt(getDistrictId)
      var tenantId = parseInt(getTenantId)
      if (districtId) {
        this._userServiceProxy
          .getRhithmCookiesUserInfo(securlyTokenEmailAddress)
          .subscribe((response) => {
            if (response) {
              let filteredObject = null
              response.map(x => {
                if (tenantId && x.districtId == districtId && x.tenantId == tenantId) {
                  filteredObject = x;
                } else if ((!tenantId || tenantId == undefined) && x.districtId == districtId) {
                  filteredObject = x;
                }
              });
              this.credentialsService.setCookie('Abp.TenantId', tenantId, new Date(new Date().getTime() + 5 * 365 * 86400000))
              this.credentialsService.removeCookie('Abp.tenantId')
              this.credentialsService.removeCookie('Abp.districtId')
              this.setRhithmloginInfoToCookie(filteredObject);
              setTimeout(() => {
                location.href = '/';
              }, 50);

            }

          })
      } else {
        if (rhithmCookieInfo === null || securlyTokenInfo.email != rhithmCookieInfo.emailAddress) {
          this._userServiceProxy
            .getRhithmCookiesUserInfo(securlyTokenEmailAddress)
            .subscribe((result) => {
              if (result) {
                if (result.length > 1) {
                  this._router.navigate(['account/user-select-role'], { state: { cookieArray: result } });
                } else {
                  this.setRhithmloginInfoToCookie(result[0]);
                  location.href = '/';
                }
              } else {
                this.SecurelyInitAsync().then(() => {
                  SecurlyAuth.Logout().then(() => {
                    // Redirect to user does not exist and try login again
                    this._router.navigate(['account/user-not-exist'], {
                      queryParams: {
                        email: securlyTokenInfo.email
                      }
                    });

                  });
                });
              }
            });
        } else {
          if (securlyTokenInfo.email != rhithmCookieInfo.emailAddress) {
            this.logout();
            this.SecurlyLogin();
          } else {
            this.setRhithmloginInfoToCookie(rhithmCookieInfo);
            location.href = '/';
          }
        }
      }

    }
    catch (_e) {

      this.SecurlyLogin();
    }
  }

  // Call Login to initiate a top level login flow.  This will present the customer with a GUI
  // if they're not logged into the the central system, but otherwise will return immediatly.
  SecurlyLogin() {
    ;
    let url = (window as any).location.href;
    if (location.href.indexOf('account/forgot-password') > 0) {
      return;
    }
    if (location.href.indexOf('account/user-invitation') > 0) {
      return;
    }
    if (location.href.indexOf('account/reset-password') > 0) {
      return;
    }
    SecurlyAuth.Login(url);
  }

}
