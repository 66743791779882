import { AfterViewInit, Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { Store } from '@ngrx/store';
import { RhithmActivityServiceProxy, RhithmActivityVideoDto, VideoProviderServiceProxy } from 'src/shared/service-proxies/service-proxies';
import * as reducers from '../../../reducers';
import { AuthenticationService } from '@app/auth/authentication.service';
import { AppConsts } from 'src/shared/AppConsts';


declare var amp: any;



@Component({
    selector: 'app-videomodal',
    templateUrl: './videomodal.component.html',
    styleUrls: ['./videomodal.component.scss'],
})
export class VideomodalComponent implements AfterViewInit {

    @ViewChild('video', { static: false }) videoPlayer: { nativeElement: any; };

    isPlay = false;
    height: "100%";
    public version: string = '2.2';
    player: any;
    autoplay = "true"
    width = "100%";
    VideoUrl: SafeResourceUrl;
    pageData: string;
    girVideo: any;
    videoIteration = 0;
    videosArray: RhithmActivityVideoDto[];
    selectedLanguageVideosArray: RhithmActivityVideoDto[];
    videoNextNavBtnHidden: boolean = true;
    videoPreviousNavBtnHidden: boolean = true;
    autoPlayVideoUrl: string = "?autoplay=1&mute=1&enablejsapi=1";
    nextVideo = false;
    isRhithmVideoUserDisplay: number;
    rhithmVideoUrl: SafeResourceUrl;
    rhithmActivityId: number;
    currentVideoLanguage: string = 'en';
    ids = 0;
    isActive: boolean = true;
    languages: any;
    authenticationService: AuthenticationService;
    rhithmVideoSubtitleEN: string = "";
    rhithmVideoSubtitleSP: string = "";
    studentLogout: any;
    teacherLanguageChange: any;
    myPlayer: any;
    constructor(
        private dialogRef: MatDialogRef<VideomodalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private _rhithmActivitiesServiceProxy: RhithmActivityServiceProxy,
        private _videoProviderProxyMoutain: VideoProviderServiceProxy,
        public domSanitizer: DomSanitizer,
        private _appSession: AppSessionService,
        private _route: Router,
        private injector: Injector,
        private store: Store<reducers.State>,
        private _appSessionService: AppSessionService,
    ) {
        this.authenticationService = injector.get(AuthenticationService)
        this.VideoUrl = data?.apiData?.url;
        this.pageData = data?.pageData;
        this.girVideo = data?.apiData;
        this.studentLogout = this._appSessionService.isGranted('Pages.Rhithm.Student');
        this.teacherLanguageChange = this._appSessionService.isGranted('Pages.Rhithm.Teacher');

        this.store.select(reducers.getLanguages).subscribe(res => {
            this.languages = res;
            this.store.select(reducers.getUserCurrentLanguage).subscribe(res => {
                this.currentVideoLanguage = res?.name;
            })
        })

    }

    ngAfterViewInit(): void {
        // this.rhithmVideo(); 
        if (!this.VideoUrl && this.pageData == 'girModal') {
            this.getRhithmVideos();
            this.getVideoProviderProxy();
        }
    }



    getRhithmVideos() {
        this._rhithmActivitiesServiceProxy
            .getAllVideosForActivityType(this.girVideo?.sessionId, this._appSession.userId)
            .subscribe(
                (result) => {
                    if (result && result.length > 0) {
                        // if (this._appSession.isGranted("Pages.Rhithm.Teacher") || this._appSession.isGranted("Pages.Rhithm.Principal")
                        //     || this._appSession.isGranted("Pages.Rhithm.Student")) {
                        //     localStorage.setItem('isHomeVideoActivity', this.girVideo?.sessionId.toString())
                        //     let route = "/app/teacher/home/";
                        //     route = this._appSession.isGranted("Pages.Rhithm.Teacher")
                        //         ? '/app/teacher/home/' + this.girVideo?.sessionId
                        //         // ? "app/teacher/home"
                        //         : route;
                        //     route = this._appSession.isGranted("Pages.Rhithm.Principal")
                        //         ? '/app/principal/home/' + this.girVideo?.sessionId
                        //         : route;
                        //     route = this._appSession.isGranted("Pages.Rhithm.DistrictOwner")
                        //         ? "app/district/live-rhithm-session"
                        //         : route;
                        //     route = this._appSession.isGranted("Pages.Rhithm.Student")
                        //         ? '/app/student/home/' + this.girVideo?.sessionId
                        //         : route;
                        //     // if (!this.isPuzzleSurveyShown && this.feature_DA180 && this.isPuzzleBoolean) {
                        //     //     this.surveyModal.show(route)
                        //     // } else {
                        //         this._route.navigate([route]);
                        //         this._route.navigate([route], { state: { details: result, providerId: this.isRhithmVideoUserDisplay } });
                        //     // }

                        // }
                        // else {
                        // this.isRithmVideoShown = true;
                        var randomArrayValue = Math.floor(
                            Math.random() * result.length
                        );
                        this.videoIteration = randomArrayValue;
                        if (result.length === 1) {
                            this.videosArray = result;
                            this.videoNextNavBtnHidden = false;
                            this.videoPreviousNavBtnHidden = false;
                            this.setAssessmentVideo();
                        } else if (result.length > 1) {
                            this.videosArray = result;
                            this.videoNextNavBtnHidden = true;
                            this.videoPreviousNavBtnHidden = true;
                            this.setAssessmentVideo();
                        }
                        // }
                    }
                    else {
                        this.closeModal();
                    }
                },
                (err) => {
                    this.closeModal();
                    // let message =
                    //     this.l("Oops!WeWereUnableToPullUpOurActivitiesForYouAtTheMoment.PleaseTryAgainLater.");
                    // this.customMessage.open(AppConsts.error, message, null);
                }
            );
    }

    getVideoProviderProxy() {
        if (this._appSession.tenantId !== null) {
            this._videoProviderProxyMoutain.get(this._appSession.tenantId, this._appSession.userId).subscribe((result: any) => {
                this.isRhithmVideoUserDisplay = result['provider'];
            })
        } else {
            this.isRhithmVideoUserDisplay = 0;
        }
    }
    getSubtitleEndPath(videoLink: any) {
        let fileName = videoLink.replace(/^.*[\\\/]/, '').replace(".mp4", '');
        // this.rhithmVideoSubtitleEN = 'assets/sampleFiles/friday.vtt';
        this.rhithmVideoSubtitleEN = AppConsts.appBaseUrl + `/subtitles/${fileName}-en.vtt`;
        this.rhithmVideoSubtitleSP = AppConsts.appBaseUrl + `/subtitles/${fileName}-es.vtt`;
    }
    setAssessmentVideo() {
        this.nextVideo = true;
        if (!this.isStudentOnly()) {
            this.selectedLanguageVideosArray = this.videosArray.filter((v) => v.language == this.currentVideoLanguage);
        } else {
            this.selectedLanguageVideosArray = Array.from(this.videosArray);
        }
        if (this.selectedLanguageVideosArray.length) {
            let randomArrayValue = Math.floor(Math.random() * this.selectedLanguageVideosArray.length);
            if (this.isRhithmVideoUserDisplay === 0) {
                this.getSubtitleEndPath(this.selectedLanguageVideosArray[randomArrayValue].url);
                this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                    this.selectedLanguageVideosArray[randomArrayValue].url + this.autoPlayVideoUrl
                );
            } else if (this.isRhithmVideoUserDisplay === 1) {
                // azureMediaURL not work for now So i am using url in this condition
                this.getSubtitleEndPath(this.selectedLanguageVideosArray[randomArrayValue].url);
                this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                    this.selectedLanguageVideosArray[randomArrayValue].azureMediaURL + this.autoPlayVideoUrl
                );
            } else {
                this.getSubtitleEndPath(this.selectedLanguageVideosArray[randomArrayValue].url);
                this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                    this.selectedLanguageVideosArray[randomArrayValue].url + this.autoPlayVideoUrl
                );
            }

            this.videoIteration = randomArrayValue;
            this.rhithmActivityId = this.selectedLanguageVideosArray[randomArrayValue].rhithmActivityId
        }
        // setTimeout(() => {
        var vid = document.getElementById("session-video");
        vid.onplay = () => {
            this.isPlay = true;
        };
        // }, 1000);
    }
    isStudentOnly() {
        return this._appSession.isGranted('Pages.Rhithm.Student');
    }



    rhithmVideo() {
        this.isPlay = true;
        if (this.VideoUrl && this.pageData !== 'girModal') {
            this.myPlayer = amp(this.videoPlayer.nativeElement, {
                nativeControlsForTouch: false,
                autoplay: this.autoplay,
                controls: true,
                width: this.width,
                logo: { enabled: false },
                ampAds: {
                    mainProgram: {
                        source: [
                            {
                                src: this.VideoUrl,
                            },
                        ],
                    },
                },
            });
        }
        else {
            this.myPlayer = amp(this.videoPlayer.nativeElement, {
                nativeControlsForTouch: false,
                autoplay: this.autoplay,
                controls: true,
                width: this.width,
                id: this.ids,
                logo: { enabled: false },
                ampAds: {
                    mainProgram: {
                        source: [
                            {
                                src: this.rhithmVideoUrl && this.rhithmVideoUrl['changingThisBreaksApplicationSecurity'],
                            },
                        ],
                    },
                },
            });
            this.myPlayer.ngComponent = this;
            this.player = this.myPlayer.ngComponent;
        }
    }

    moveToNextVideo() {
        if (this.selectedLanguageVideosArray) {
            this.isPlay = true;
            // let randomArrayValue = Math.floor(Math.random() * this.selectedLanguageVideosArray.length);
            if (this.videoIteration === this.selectedLanguageVideosArray.length - 1) {
                this.videoIteration =
                    this.videoIteration - (this.selectedLanguageVideosArray.length - 1);

                if (this.isRhithmVideoUserDisplay === 0) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                } else if (this.isRhithmVideoUserDisplay === 1) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].azureMediaURL +
                        this.autoPlayVideoUrl
                    );
                } else {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                }

                this.rhithmActivityId = this.selectedLanguageVideosArray[this.videoIteration].rhithmActivityId;
            } else {
                this.isActive = false;
                setTimeout(() => (this.isActive = true));
                this.videoIteration++;
                this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                if (this.isRhithmVideoUserDisplay === 0) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                } else if (this.isRhithmVideoUserDisplay === 1) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].azureMediaURL +
                        this.autoPlayVideoUrl
                    );
                } else {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                }
                this.rhithmActivityId = this.selectedLanguageVideosArray[this.videoIteration].rhithmActivityId;

                // this.rhithmVideo();
            }
            // setTimeout(() => {
            //     var sessionVideo: any = document.getElementById("session-video");
            //     sessionVideo.play();
            // }, 1000);
            this.onLogUserVideoStatus();
            this.nextVideo = false;
            this.playNextVideo();
        }
    }

    moveToPreviousVideo() {
        if (this.selectedLanguageVideosArray) {
            this.isPlay = true;
            // let randomArrayValue = Math.floor(Math.random() * this.selectedLanguageVideosArray.length);
            if (this.videoIteration === 0) {
                this.videoIteration = this.selectedLanguageVideosArray.length - 1;
                if (this.isRhithmVideoUserDisplay === 0) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                } else if (this.isRhithmVideoUserDisplay === 1) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].azureMediaURL +
                        this.autoPlayVideoUrl
                    );
                } else {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                }
                this.rhithmActivityId = this.selectedLanguageVideosArray[this.videoIteration].rhithmActivityId;

            } else {
                this.isActive = false;
                setTimeout(() => (this.isActive = true));
                this.videoIteration--;

                if (this.isRhithmVideoUserDisplay === 0) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                } else if (this.isRhithmVideoUserDisplay === 1) {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].azureMediaURL +
                        this.autoPlayVideoUrl
                    );
                } else {
                    this.getSubtitleEndPath(this.selectedLanguageVideosArray[this.videoIteration].url);
                    this.rhithmVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.selectedLanguageVideosArray[this.videoIteration].url +
                        this.autoPlayVideoUrl
                    );
                }
                this.rhithmActivityId = this.selectedLanguageVideosArray[this.videoIteration].rhithmActivityId;

            }
            // setTimeout(() => {
            //     var sessionVideo: any = document.getElementById("session-video");
            //     sessionVideo.play();
            // }, 1000);
            this.onLogUserVideoStatus();
            this.nextVideo = false;
            this.playNextVideo();
        }

    }

    changeLanguageForVideo(name: string) {
        this.currentVideoLanguage = name;
        this.setAssessmentVideo();
        this.playNextVideo();
        this.isPlay = true;
        // setTimeout(() => {
        //     var sessionVideo: any = document.getElementById("session-video");
        //     sessionVideo.play();
        // }, 1000);
    }

    playNextVideo() {
        // Dynamically load the amp player control
        this.nextVideo = true;

        var myOptions = {
            nativeControlsForTouch: false,
            autoplay: true,
            logo: { enabled: false },
            controls: true,
            width: '320',
            height: '200',
            poster: '',
        };
        var myPlayer2 = amp('session-video', myOptions);
        myPlayer2.src(
            [{
                src: this.rhithmVideoUrl && this.rhithmVideoUrl['changingThisBreaksApplicationSecurity'],
            }],
            [
                { src: this.rhithmVideoSubtitleEN, srclang: "en", kind: "subtitles", label: "English", default: true },
                { src: this.rhithmVideoSubtitleSP, srclang: "es", kind: "subtitles", label: "Spanish", default: false },
            ]
        );
        myPlayer2.textTracks()[0].mode = "showing"

        myPlayer2.ngComponent = this;
    }
    onLogUserVideoStatus() {
        this._rhithmActivitiesServiceProxy.logUserVideoStatus(this.rhithmActivityId, this._appSession.userId, this.girVideo?.sessionId).
            subscribe();
    }
    closeModal(): void {
        this.isPlay = false;
        if (this.myPlayer) {
            this.myPlayer.dispose();
        }
        this.dialogRef.close();
    }

    goToDashboard() {
        this.closeModal();
        this._route.navigate(['/dashboard/main'])
    }

    logout() {
        this.authenticationService.logout();
    }

}
