<div class="container-fluid">
  <div class="dialog-main">
    <div class="header-wrapper">
      <div class="cursor_pointer"><span (click)="closeSelf()">
          <mat-icon svgIcon="close-icon" title="close"></mat-icon></span>
      </div>
      <span *ngIf="isNote">
        <strong>Notes</strong>
      </span>
    </div>
    <div class="">
      <p style="text-align: center;" *ngIf="!isNote">
        {{customMsg}}
      </p>
      <ng-container *ngIf="isNote">

        <table mat-table [dataSource]="userNoteList" class="customtable">
          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>Date </th>
            <td mat-cell *matCellDef="let element"> {{element.date | date :'d/M/yy, h:mm a' }} </td>
          </ng-container>
          <ng-container matColumnDef="Category">
            <th mat-header-cell *matHeaderCellDef> Category </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="Emoji">
            <th mat-header-cell *matHeaderCellDef> Emoji </th>
            <td mat-cell *matCellDef="let element"> <img [src]="element.iconBase64String" alt="" width="30"> </td>
          </ng-container>
          <ng-container matColumnDef="Note">
            <th mat-header-cell *matHeaderCellDef> Note </th>
            <td mat-cell *matCellDef="let element" class="u-comment"> {{element.comment}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </ng-container>
    </div>
    <div class="buttton-wrapper" *ngIf="!isNote">
      <div>
        <securly-button color="secondary" (clickEvent)="closeSelf()" id="msg_dialog_cls_btn">Okay</securly-button>
      </div>
    </div>

  </div>
</div>