<div class="container-fluid">
    <div class="dialog-main">
        <div class="header-wrapper">
            <div class="cursor_pointer"><span (click)="closeModal()"><mat-icon svgIcon="close-icon"
                        title="close"></mat-icon></span>
            </div>
        </div>
        <div class="">
            <p style="text-align: center;">
                {{"You Haven't Answered This Question Yet!"}}
                <br>
                {{"Would you like to move on or answer the question?"}}
            </p>
        </div>
        <div class="buttton-wrapper">
            <div class="action-button">
                <securly-button color="secondary" (clickEvent)="skipCurrentQuestionAndMoveOnToNext()">Move
                    On</securly-button>
            </div>
            <div class="action-button">
                <securly-button color="primary" (clickEvent)="answertheQuestion()">Answer the Question</securly-button>
            </div>
        </div>

    </div>
</div>