import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AttemptSessionModalComponent } from '@app/shared/modal/attempt-session-modal/attempt-session-modal.component';

@Component({
  selector: 'app-dashboard-student',
  templateUrl: './dashboard-student.component.html',
  styleUrls: ['./dashboard-student.component.scss']
})
export class DashboardStudentComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this._dialog.open(AttemptSessionModalComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openDashboard() {
    this._router.navigate(['/app/dashboard/main']);
  }
}
