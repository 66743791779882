import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, PlatformLocation } from '@angular/common';
import { RootComponent } from './root.component';
import { RootRoutingModule } from './root-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppModule, metaReducers } from '@app/app.module';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import { reducers } from './app/reducers/index';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from '@app/router/router.effects';
import { AccountsEffects } from '@app/accounts/accounts.effects';
import { AppEffects } from '@app/app.effects';
import { SettingEffects } from '@app/settings/settings/settings.effects';
import { WellnessTipsEffects } from '@app/wellness-tips/wellness-tips.effects';
import { DashboardEffects } from '@app/dashboard/dashboard.effect';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared';
import { LanguageServiceProxy } from './shared/service-proxies/service-proxies';
import { CustomRouterStateSerializer } from '@app/router/utils';
import { CoreService } from '@app/core/core.service';
import { WindowRef } from '@app/core/window.interface';
import { AppComponent } from '@app/app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { RhithmHttpInterceptor } from '@app/@core/http/rhithmHttpInterceptor';
import { AppConsts } from './shared/AppConsts';
import { AppPreBootstrap } from './AppPreBootstrap';
import { SharedEffects } from '@app/shared/shared.effects';
import { AuthenticationService } from '@app/auth/authentication.service';
import { SecurlySnackBarModule } from '@securly-engineering/securly-styles';

export function appInitializerFactory(
  injector: Injector,
  platformLocation: PlatformLocation) {
  return () => {
    return new Promise<boolean>((resolve, reject) => {
      AppConsts.appBaseHref = getBaseHref(platformLocation);
      let appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;
      AppPreBootstrap.run(appBaseUrl, async () => {
        let appSessionService: AppSessionService = injector.get(AppSessionService);
        await appSessionService.init().then((result) => {
          resolve(true)
        }, (err) => {
          reject(err);
        });
      }, resolve, reject);
    });
  };
}
function getDocumentOrigin() {
  if (!document.location.origin) {
    return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
  }

  return document.location.origin;
}
export function getBaseHref(platformLocation: PlatformLocation): string {
  let baseUrl = platformLocation.getBaseHrefFromDOM();
  if (baseUrl) {
    return baseUrl;
  }

  return '/';
}
@NgModule({
  declarations: [
    RootComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RootRoutingModule,
    AppModule,
    MatDialogModule,
    MatIconModule,
    AngularMaterialModule,
    HttpClientModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    // StoreModule.forRoot(REDUCER_TOKEN, {
    //   metaReducers,
    //   runtimeChecks: {
    //     strictStateImmutability: true,
    //     strictActionImmutability: true
    //   }
    // }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([RouterEffects, AccountsEffects, AppEffects, SettingEffects, WellnessTipsEffects, DashboardEffects, SharedEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),

    TranslateModule.forRoot(),

    SharedModule,
    SecurlySnackBarModule
  ],
  providers: [
    AppSessionService,
    LanguageServiceProxy,
    AuthenticationService,
    CoreService,
    WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RhithmHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector, PlatformLocation],
      multi: true
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: Location, useClass: HashLocationStrategy },
  ],
  bootstrap: [RootComponent],
})
export class RootModule { }
