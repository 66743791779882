import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { CredentialsService } from './credentials.service';
import { UrlHelper } from 'src/shared/helpers/UrlHelper';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _appSessionService: AppSessionService
  ) { }

  canActivateInternal(data: any, state: RouterStateSnapshot): boolean {
    if (UrlHelper.isInstallUrl(location.href)) {
      return true;
    };
    if (!this._appSessionService.user) {
      this._router.navigate(['/account/login']);
      return false;
    }

    if (!data || !data['permission']) {
      return true;
    }

    if (this._appSessionService.isGranted(data['permission'])) {
      return true;
    }
    this._router.navigate([this.selectBestRoute()]);
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivateInternal(route.data, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivateInternal(route.data, null);
  }

  selectBestRoute(): string {

    if (!this._appSessionService.user) {
      return '/account/login';
    }

    // if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
    //     return '/app/admin/hostDashboard';
    // }

    if (this._appSessionService.isGranted('Pages.Rhithm.Student')) {
      return '/app/student';
    }

    return '/app/dashboard/main';
  }
}
