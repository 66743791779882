import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private classLink = new BehaviorSubject(null);
  classLinkRedirection = this.classLink.asObservable();

  private cleverLink = new BehaviorSubject(null);
  cleverRedirection = this.cleverLink.asObservable();
  private isSocialLoginLoader = new BehaviorSubject(false);
  socialLoginLoader = this.isSocialLoginLoader.asObservable();


  private googleClassRoom = new BehaviorSubject(null);
  googleClassRoomRedirection = this.googleClassRoom.asObservable();
  private isMaintenance = new BehaviorSubject(null);
  isMaintenanceService = this.isMaintenance.asObservable();
  private isDashboardReset = new BehaviorSubject(null);
  dashboardReset = this.isDashboardReset.asObservable();

  private routeUrl = new BehaviorSubject(null);
  routeUrlService = this.routeUrl.asObservable();

  private toolkitSearch = new BehaviorSubject(null);
  onSearch = this.toolkitSearch.asObservable();

  private roleSelect = new BehaviorSubject(null);
  selectedRole = this.roleSelect.asObservable();

  private gradeLevelIds = new BehaviorSubject(null);
  selectingGradeIds = this.gradeLevelIds.asObservable();

  private teacherTipsSearch = new BehaviorSubject(null);
  onSearchTeachertips = this.teacherTipsSearch.asObservable();

  private responseGroupIds = new BehaviorSubject(null);
  selectingResponseGroupIds = this.responseGroupIds.asObservable();

  private assessmentTypeId = new BehaviorSubject(null);
  selectingAssessmentypeId = this.assessmentTypeId.asObservable();

  private dashboardMainSearch = new BehaviorSubject(null);
  onSearchDashboardMain = this.dashboardMainSearch.asObservable();

  private selectingTabAndOption = new BehaviorSubject(null);
  onSelectedTabAndOption = this.selectingTabAndOption.asObservable();

  private selectingDate = new BehaviorSubject(null);
  onSelectedDateType = this.selectingDate.asObservable();

  private selectingQuestionOption = new BehaviorSubject(null);
  onSelectedQuestionOption = this.selectingQuestionOption.asObservable();

  private sessionChanged = new BehaviorSubject(false);
  onSessionChanged = this.sessionChanged.asObservable();

  private dataSteam = new Subject<any>();
  watchStorage(): Observable<any> {
    return this.dataSteam.asObservable();
  }

  sendData(key: string, data: any) {
    this.dataSteam.next({ key, data });
  }

  onToolkitSearch(data: any) {
    this.toolkitSearch.next(data);
  }

  onRoleSelect(data: any) {
    this.roleSelect.next(data);
  }

  onSelectingGradeLevelIds(data: any) {
    this.gradeLevelIds.next(data);

  }
  onMaintenance(url: string) {
    this.isMaintenance.next(url);
  }
  onRouteUrl(url: string) {
    this.routeUrl.next(url);
  }
  onTeachertipsSearch(data: any) {
    this.teacherTipsSearch.next(data)
  }

  onSelectingResponseGroupIds(data: any) {
    this.responseGroupIds.next(data);

  }

  onSelectingAssesmentType(data: number) {
    this.assessmentTypeId.next(data);
  }

  onDashboardMainSearch(data: any) {
    this.dashboardMainSearch.next(data)
  }
  onDashboardReset(data: any) {
    this.isDashboardReset.next(data)
  }

  onSelectViewTypeAndOption(data: any) {
    this.selectingTabAndOption.next(data)
  }

  onSelectingDateType(data: any) {
    this.selectingDate.next(data)
  }

  onSelectingQuestionOption(data: any) {
    this.selectingQuestionOption.next(data)
  }

  onUserSessionChanged(bool: boolean) {
    this.sessionChanged.next(bool)
  }
  classLinkRedirectionState(data: any) {
    this.classLink.next(data)
  }
  onSocialLoginLoader(data: any) {
    this.isSocialLoginLoader.next(data)
  }

  cleverRedirectionState(data: any) {
    this.cleverLink.next(data)
  }

  googleClassRoomRedirectionState(data: any) {
    this.googleClassRoom.next(data)
  }
}
