import { Component, Inject, ElementRef, HostListener } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

export interface SnackbarInputs {
  type: string;
  message: string;
  interval?: number;
}

@Component({
  selector: 'failure-snackbar-component',
  template: `
    <div
      class="d-flex justify-content-between"
      [ngClass]="
        data.type === 'error'
          ? 'snack-bar-container-error'
          : 'snack-bar-container'
      "
    >
      <div
        [ngClass]="data.type === 'error' ? 'snackbarError' : 'snackbarCheck'"
      >
        <i *ngIf="data.type !== 'error'" class="icon icon-check-single"></i>
        <i *ngIf="data.type === 'error'" class="icon icon-warning"></i>
      </div>
      <div class="text-baseline d-flex align-items-center">
        {{ data.message }}
      </div>
      <a
        href="javascript:void(0)"
        class="cancelBtn-snackbar"
        mat-raised-button
        (click)="snackBarRef.dismiss()"
      >
        <i class="icon icon-e-remove"></i>
      </a>
    </div>
  `,
  styles: [
    `
      .text-baseline {
        word-break: break-word;
      }
      .cancelBtn-snackbar {
        font-size: 1.5em;
        align-self: center;
        position: relative;
        padding: 0.33em;
        margin-top: 0.25em;
      }
      .cancelBtn-snackbar i {
        position: relative;
        top: 37%;
        font-size: 16px;
      }
    `,
  ],
})
export class FailureSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarInputs,
    public snackBarRef: MatSnackBarRef<FailureSnackbarComponent>,
    private elRef: ElementRef
  ) {
    setTimeout(() => {
      snackBarRef.dismiss();
    }, this.data?.interval || 5000);
  }
  @HostListener('document:click', ['$event'])
  clickedOutSideSnackbar(event: any) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.snackBarRef.dismiss();
    }
  }
}
