import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetInRhithmModalComponent } from '../get-in-rhithm-modal/get-in-rhithm-modal.component';
import { DashboardSessionTableServiceProxy, UserSessionServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { AppConsts } from 'src/shared/AppConsts';
import { ClassWidgetModalComponent } from '../class-widget-modal/class-widget-modal.component';
import { Store } from '@ngrx/store';
import * as reducers from './../../../reducers';
import { loadAllStartedUserSessions } from '@app/shared/shared.actions';
import { Subject, skip, takeUntil } from 'rxjs';
import * as lodash from 'lodash';

@Component({
  selector: 'app-attempt-session-modal',
  templateUrl: './attempt-session-modal.component.html',
  styleUrls: ['./attempt-session-modal.component.scss']
})
export class AttemptSessionModalComponent implements OnInit, OnDestroy {

  assessmentTypeId: number;
  startedAndIncompleteSessionsArray: any[] = [];
  selectedOrganizationUnitAndUsersList: any[];
  selectedTeacherClass: any;
  sessionType: string;
  getMyselfButtonDisabled: boolean = false;
  isTeacher: boolean = false;
  isLoader: boolean = false;
  isAssessmentDisable: boolean = false
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<reducers.State>,
    private _dialog: MatDialog,
    private _userSessionProxyMoutain: UserSessionServiceProxy,
    private _appSession: AppSessionService,
    private getDefaultAssessmentType: DashboardSessionTableServiceProxy,
  ) {
    this.isTeacher = _appSession.isGranted('Pages.Rhithm.Teacher')
  }

  ngOnInit(): void {
    this.isLoader = true;
    this.getDefaultAssessmentTypeId();
    this.showUnattendedSessionsListModal()
  }
  // remove this later
  getDefaultAssessmentTypeId() {
    this.getDefaultAssessmentType.getDefaultAssessmentType().subscribe(res => {
      if (res) {
        AppConsts.DefaultAssessmentTypeId = res
      }
    });
  }


  openDialog(dialogData?: any) {
    this.getMyselfButtonDisabled = false;
    this._dialog.closeAll();
    const dialogRef = this._dialog.open(GetInRhithmModalComponent, {
      width: '824px',
      minHeight: '587px',
      panelClass: 'get-in-rhithm',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openClassDialog() {
    this._dialog.closeAll();
    const dialogRef = this._dialog.open(ClassWidgetModalComponent, {
      width: '1178px',
      height: '764px',
      panelClass: 'class-widget'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {


      }
    });
  }

  unattended() {
    alert("AAAAAAA")
  }
  showGetInRhithmForMyself() {
    this.getMyselfButtonDisabled = true;
    this.openDialog();
  }

  showGetInRhithmForSelectedSession(unattendedSession: any) {
    this.getMyselfButtonDisabled = false;
    this.openDialog(unattendedSession);
  }


  showUnattendedSessionsListModal() {
    this.store.dispatch(loadAllStartedUserSessions.init({ payload: this._appSession.userId }));
    this.store
      .select(reducers.getAllStartedUserSessions)
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.isAssessmentDisable = res.isAssessmentDisable;

        this.startedAndIncompleteSessionsArray = lodash.cloneDeep(res.getUserSessions?.filter((x: any) => x.isCompleted == false));
        for (var x = 0; x < this.startedAndIncompleteSessionsArray.length; x++) {
          let createdOn = this.startedAndIncompleteSessionsArray[x].creationTime.toString();
          this.startedAndIncompleteSessionsArray[x].createdOn = createdOn;
        }
        this.isLoader = false;
      });
  }

  closeModal() {
    this._dialog.closeAll();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
