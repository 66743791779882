import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertCsv',
})
export class ConvertCsvPipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    if (value) {
      if (args.indexOf('toCSV') > -1) {
        return value.replace(/, /g, ',');
      } else if (args.indexOf('fromCSV') > -1) {
        return value.replace(/,/g, ', ');
      }
    }
    return value;
  }
}
