<div class="container-fluid">
    <div class="content-wrapper">
        <div class="gif-wrapper">
            <img src="assets/GetInRhithm-trans2.gif" alt="" srcset="">
        </div>
        <div class="btn-wrapper">
            <div class="act-btn">
                <securly-button color="primary" (clickEvent)="openDialog()">
                    <mat-icon svgIcon="icon-share-user-w" class="on-load" title="user"></mat-icon>
                    {{'GetInRhithm' | languagePipe}}
                </securly-button>
            </div>
            <div class="act-btn">
                <securly-button color="primary" (clickEvent)="openDashboard()">
                    <mat-icon svgIcon="icon-dashboard-w" class="on-load" title="dashboard"></mat-icon>
                    Go to Dashboard
                </securly-button>
            </div>
        </div>
    </div>
</div>