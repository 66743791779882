import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent implements OnInit {
  @Input() options: { caption: any; id: any }[];
  @Input() selectedOptionId: any;
  @Output() onToggleSelectionChange = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  onChange(event: MatButtonToggleChange) {
    this.onToggleSelectionChange.emit(event);
  }
}
