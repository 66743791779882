import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { CoreService } from '@app/core/core.service';
import { Store } from '@ngrx/store';
import * as reducers from './reducers';
import { changeLanguage, loadColumnSettings, loadCurrentLoginInfo, resetColumnSettings, updateAppVersion, updateColumnSettings } from './app.actions';
import { httpErrorResponseHandler } from './shared/http-error-response-handler';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageServiceProxy, UserServiceProxy } from 'src/shared/service-proxies/service-proxies';

@Injectable()
export class AppEffects {

  loadColumnSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadColumnSettings.init),
      map((action: any) => action),
      switchMap((payload) => {
        return this.mdmCoreService
          .createHttp('GET_GRID_COLUMN_SETTINGS', {}, {}, payload)
          .pipe(
            map((res: any) => {
              if (res?.status === 200) {
                return loadColumnSettings.success(res);
              }
            }),
            catchError((errData) => {
              return httpErrorResponseHandler(errData, {
                defaultMessage:
                  'An unexpected issue occured while attempting to load Column Settings. Please try again.',
                errorMapping: {},
                failureAction: loadColumnSettings.failure,
              });
            })
          );
      })
    );
  });
  updateColumnSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateColumnSettings.init),
      map((action: any) => action),
      switchMap((payload) => {
        return this.mdmCoreService
          .createHttp('UPDATE_GRID_COLUMN_SETTINGS', {}, payload, {})
          .pipe(
            map((res: any) => {
              if (res) {
                return updateColumnSettings.success(res);
              }
            }),
            catchError((errData: HttpErrorResponse) => {
              return httpErrorResponseHandler(errData, {
                defaultMessage: 'Failed to Update Column Settings.',
                errorMapping: {},
                failureAction: updateColumnSettings.failure,
                unauthorizedError:
                  "Sorry, you don't have permissions to perform this action.",
              });
            })
          );
      })
    );
  });
  resetColumnSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetColumnSettings.init),
      map((action: any) => action),
      switchMap((payload) => {
        return this.mdmCoreService
          .createHttp('RESET_GRID_COLUMN_SETTINGS', {}, {}, payload)
          .pipe(
            map((res: any) => {
              if (res) {
                return resetColumnSettings.success(res);
              }
            }),
            catchError((errData: HttpErrorResponse) => {
              return httpErrorResponseHandler(errData, {
                defaultMessage: 'Failed to Reset Column Settings.',
                errorMapping: {},
                failureAction: resetColumnSettings.failure,
                unauthorizedError:
                  "Sorry, you don't have permissions to perform this action.",
              });
            })
          );
      })
    );
  });
  changeLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeLanguage.init),
      map((action: any) => action),
      mergeMap((payload) => {
        return this._languageService.changeLanguage(payload.payload).pipe(
          map((res: any) => {
            if (res) {
              return changeLanguage.success(res);
            }
          }),
          catchError((error) => {
            return httpErrorResponseHandler((error), {
              defaultMessage: 'Failed to change language.',
              errorMapping: {},
              failureAction: changeLanguage.failure,
              unauthorizedError:
                'Sorry, you do not have permission to change language.',
            })
          })
        )
      })
    )
  })
  updateAppVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAppVersion.init),
      map((action: any) => action),
      mergeMap((payload) => {
        return this._userSessionService.updateAppVersion(payload.payload).pipe(
          map((res: any) => {
            if (res) {
              return updateAppVersion.success(res);
            }
          }),
          catchError((error) => {
            return httpErrorResponseHandler((error), {
              defaultMessage: 'Failed to change language.',
              errorMapping: {},
              failureAction: updateAppVersion.failure,
              unauthorizedError:
                'Sorry, you do not have permission to change language.',
            })
          })
        )
      })
    )
  })
  constructor(
    private actions$: Actions,
    private mdmCoreService: CoreService,
    private _languageService: LanguageServiceProxy,
    private _userSessionService: UserServiceProxy,
    private store: Store<reducers.State>

  ) { }
}
