import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";


@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        req = req.clone({
            setHeaders: {
              'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=0'
            }
          });

        // req.headers.set('Content-Type', 'application/json');
        // req.headers.set('Accept', 'text/plain');
        // const authReq = req.clone({
        //     headers: req.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate, max-age=0'),
        // });

        return next.handle(req);
    }

}
