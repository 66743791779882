import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { Shell } from './shell/shell.service';
import { DashboardStudentComponent } from './dashboard/dashboard-student/dashboard-student.component';
import { AuthenticationGuard } from './auth';

export const routes: Routes = [

  {
    path: 'app',
    component: AppComponent,
    children: [
      Shell.childRoutes([

        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        {
          path: 'student',
          component: DashboardStudentComponent,
          data: { permission: 'Pages.Rhithm.Student' },
          canActivate: [AuthenticationGuard],
        },
        {
          path: 'dashboard',
          loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), //Lazy load main module
        },
        {
          path: 'wellnesstips',
          loadChildren: () => import('./wellness-tips/wellness-tips.module').then(m => m.WellnessTipsModule), //Lazy load main module
        },
        {
          path: 'settings',
          loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), //Lazy load main module
        },
      ])
    ]
  },
  // Fallback when no prior route is matched

  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
