<div class="container d-flex h-100">
    <div class="main-wrapper">
        <div class="row no-gutters">
            <div class="col-md-6 d-none d-md-block">
                <img id="side_image" src="assets/school-login-0120@2x.png" class="img-fluid pr-4"
                    style="min-height:100%;" />
            </div>
            <div class="col-md-6 bg-white p-5 right-box">
                <router-outlet></router-outlet>

            </div>
        </div>
    </div>
</div>