import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { assessmentTypes, createEmojiRiskAlert, createNoteAlert, createTempoRiskAlert, getAdmins, getAlertRecipients, getClasses, getEmojiAssessmentQuestions, getGrades, getGroups, getStudents, getTeachers, loadRiskAlert } from "./settings.actions";
import { map, mergeMap } from 'rxjs';
import {
    AssessmentTypeServiceProxy, RiskAlertTemplateServiceProxy,
    RiskAlertsServiceProxy,
    DistrictsServiceProxy as MountainDistrictServiceProxy,
    RiskAlertsServiceProxy as MountainRiskAlertsServiceProxy
} from "src/shared/service-proxies/service-proxies";
import { catchError } from 'rxjs/operators';
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { RiskAlertsServiceProxy as MountainRiskAlertsService, RiskAlertsTemplateServiceProxy } from "src/shared/service-proxies/service-proxies-v2"


@Injectable()
export class SettingEffects {
    constructor(
        private actions$: Actions,
        private _riskAlertProxyService: RiskAlertsTemplateServiceProxy,
        private _emojiRiskAlertService: RiskAlertTemplateServiceProxy,
        private _assessmentType: AssessmentTypeServiceProxy,
        private _mountainDistrictService: MountainDistrictServiceProxy,
        private _riskAlerMountainService: RiskAlertsServiceProxy,
        private _mountainRiskAlertsService: MountainRiskAlertsServiceProxy,
        private _riskAlertsService: MountainRiskAlertsService,
        private _tempoRiskAlert: RiskAlertTemplateServiceProxy,
        private _noteRiskAlert: RiskAlertTemplateServiceProxy

    ) { }
    loadRiskAlert$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadRiskAlert.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._riskAlertProxyService.getRiskAlertTemplatesByUserId(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadRiskAlert.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get risk alert list.',
                            errorMapping: {},
                            failureAction: loadRiskAlert.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load risk alert.',
                        })
                    })
                )
            })
        )
    })

    createEmojiRiskAlert$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEmojiRiskAlert.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._emojiRiskAlertService.createOrUpdateEmojiAssessmentAlert(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return createEmojiRiskAlert.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to pin class.',
                            errorMapping: {},
                            failureAction: createEmojiRiskAlert.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load risk alert.',
                        })
                    })
                )
            })
        )
    })

    assessmentTypes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(assessmentTypes.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._assessmentType.filterDistrictAssessmentTypeUserIds(payload.payload).pipe(
                    map((res) => {
                        if (res) {
                            return assessmentTypes.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to pin class.',
                            errorMapping: {},
                            failureAction: assessmentTypes.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load risk alert.',
                        })
                    })
                )
            })
        )
    })

    getGrades$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getGrades.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._riskAlerMountainService.getDistrictGradeLevelsForDropDown(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return getGrades.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to pin class.',
                            errorMapping: {},
                            failureAction: getGrades.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load risk alert.',
                        })
                    })
                )
            })
        )
    })

    getClasses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getClasses.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._mountainRiskAlertsService.getRiskAlertRespondentClassTab(payload.payload.page, payload.payload.pageSize, "", undefined, undefined).pipe(
                    map((res: any) => {
                        if (res) {
                            return getClasses.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to pin class.',
                            errorMapping: {},
                            failureAction: getClasses.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load risk alert.',
                        })
                    })
                )
            })
        )
    })

    getGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getGroups.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._mountainRiskAlertsService.getRiskAlertRespondentGroupTab(undefined, payload.payload.page, payload.payload.pageSize, "").pipe(
                    map((res: any) => {
                        if (res) {
                            return getGroups.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to pin class.',
                            errorMapping: {},
                            failureAction: getGroups.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load risk alert.',
                        })
                    })
                )
            })
        )
    })

    getStudents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getStudents.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._riskAlerMountainService
                    .getRiskAlertRespondentStudentTab(undefined, undefined, payload.payload.page, payload.payload.pageSize, undefined, "", undefined).pipe(
                        map((res: any) => {
                            if (res) {
                                return getStudents.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get students.',
                                errorMapping: {},
                                failureAction: getStudents.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load students.',
                            })
                        })
                    )
            })
        )
    })

    getTeachers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getTeachers.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._riskAlerMountainService
                    .getRiskAlertRespondentTeacherTab(undefined, payload.payload.page, payload.payload.pageSize, undefined, "").pipe(
                        map((res: any) => {
                            if (res) {
                                return getTeachers.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get Teachers.',
                                errorMapping: {},
                                failureAction: getTeachers.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load Teachers.',
                            })
                        })
                    )
            })
        )
    })

    getAdmins$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAdmins.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._riskAlerMountainService
                    .getRiskAlertRespondentPrincipalTab(undefined, payload.payload.page, payload.payload.pageSize, undefined, "").pipe(
                        map((res: any) => {
                            if (res) {
                                return getAdmins.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get admins.',
                                errorMapping: {},
                                failureAction: getAdmins.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load admins.',
                            })
                        })
                    )
            })
        )
    })

    getEmojiAssessmentQuestions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEmojiAssessmentQuestions.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._riskAlertsService
                    .getEmojiAssessmentAlertsParametersByAssessmentTypeId(payload.payload).pipe(
                        map((res: any) => {
                            if (res) {
                                return getEmojiAssessmentQuestions.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get admins.',
                                errorMapping: {},
                                failureAction: getEmojiAssessmentQuestions.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load admins.',
                            })
                        })
                    )
            })
        )
    })

    getAlertRecipients$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAlertRecipients.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._mountainRiskAlertsService
                    .getAlertRecipientsDropDown(payload.payload.page, payload.payload.pageSize, undefined, undefined, true).pipe(
                        map((res: any) => {
                            if (res) {
                                return getAlertRecipients.success({ items: res });
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get admins.',
                                errorMapping: {},
                                failureAction: getAlertRecipients.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load admins.',
                            })
                        })
                    )
            })
        )
    })

    createTempoRiskAlert$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createTempoRiskAlert.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._tempoRiskAlert
                    .createOrUpdateTempoScoreAlert(payload.payload).pipe(
                        map((res: any) => {
                            if (res) {
                                return createTempoRiskAlert.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get admins.',
                                errorMapping: {},
                                failureAction: createTempoRiskAlert.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load admins.',
                            })
                        })
                    )
            })
        )
    })

    createNoteRiskAlert$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createNoteAlert.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._noteRiskAlert
                    .createOrUpdateNoteAlert(payload.payload).pipe(
                        map((res: any) => {
                            if (res) {
                                return createNoteAlert.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get note alerts.',
                                errorMapping: {},
                                failureAction: createNoteAlert.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load note alerts.',
                            })
                        })
                    )
            })
        )
    })



}