import { Component, OnInit, OnDestroy } from '@angular/core';
import { WindowRef } from '@app/core/window.interface';
import { FailureSnackbarComponent } from '@app/shared/failure-snackbar.component';
import { MatIconsService } from '@app/shared/mat-icons-service';
import { SuccessSnackbarComponent } from '@app/shared/success-snackbar.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main',
  template: `<securly-snack-bar></securly-snack-bar>
  <router-outlet></router-outlet>`,
})
export class RootComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  browserId = Date.now();
  componentMap = {
    APP_ERROR_MESSAGE: [FailureSnackbarComponent, 'mat-snack-bar-failure'],
    SETUP_WIZARD_OUS_RESYNCED: [
      SuccessSnackbarComponent,
      'mat-snack-bar-success',
    ],
    CHANGE_PASSWORD_SUCCESS: [
      SuccessSnackbarComponent,
      'mat-snack-bar-success',
    ],
    CHANGE_PASSWORD_FAILURE: [
      FailureSnackbarComponent,
      'mat-snack-bar-failure',
    ],
    APP_POLLING_FAILURE: [FailureSnackbarComponent, 'mat-snack-bar-failure'],
  };
  constructor(
    private iconsService: MatIconsService,
    private winRef: WindowRef,
  ) {
    this.winRef = winRef;
  }

  async ngOnInit() {
    this.iconsService.init();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
