import { createAsyncAction } from '../util';
import { props } from '@ngrx/store';

export const loadAllAccounts = createAsyncAction(
  '[Accounts] Load all accounts',
  props<{ payload: any }>(),
  props<{ accounts: any }>(),
  props<{ message: string }>()
);

export const lookupAccounts = createAsyncAction(
  '[Accounts] Lookup  accounts',
  props<{ payload: any }>(),
  props<{ accounts: any }>(),
  props<{ message: string }>()
);

export const getAccountId = createAsyncAction(
  '[Accounts] Get Account by ID',
  props<{ customerID: number }>(),
  props<{ accounts: any }>(),
  props<{ message: string }>()
);

export const createAccount = createAsyncAction(
  '[Accounts] Create  account',
  props<any>(),
  props<any>(),
  props<{ message: string }>()
);

export const editAccount = createAsyncAction(
  '[Accounts] Edit account',
  props<any>(),
  props<any>(),
  props<{ message: string }>()
);
