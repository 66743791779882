<button color="primary" mat-stroked-button [matMenuTriggerFor]="columnMenu">
  <ng-content></ng-content>
</button>
<mat-menu #columnMenu="matMenu">
  <div
    class="va-mat-table-dragable-container"
    cdkDropList
    dkDropListLockAxis="y"
    cdkDropListOrientation="vertical"
  >
    <div
      class="dragable-row"
      *ngFor="let column of columnInfo; let i = index"
      cdkDrag
      [cdkDragData]="{ columnIndex: i, columnTitle: column.id }"
      (cdkDragDropped)="columnMenuDropped($event)"
    >
      <div *ngIf="column.id !== 'checkbox'">
        <mat-icon cdkDragHandle><i class="fas fa-bars"></i></mat-icon>
        <mat-checkbox
          [disabled]="
            column?.disable ||
            column.preventBeingHidden ||
            column.id === 'userName' ||
            column.id === 'className' ||
            (onlyOneColumnShown() && !column.hidden)
          "
          [checked]="!column.hidden"
          (click)="$event.stopPropagation()"
          (change)="toggleSelectedColumn(column.id)"
        >
          {{ column.name }}
        </mat-checkbox>

        <mat-card class="va-mat-table-drag-preview" *cdkDragPreview>
          <mat-icon>drag_indicator</mat-icon>
          <mat-checkbox [checked]="!column.hidden">
            {{ column.name }}
          </mat-checkbox>
        </mat-card>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-primary-outline"
      (click)="onReset($event)"
    >
      Reset to Default
    </button>
  </div>
</mat-menu>
