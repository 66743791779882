import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { DataService } from '@app/shared/data.service';
import { VideomodalComponent } from '@app/shared/modal/videomodal/videomodal.component';
import * as moment from 'moment';
import { AppConsts } from 'src/shared/AppConsts';
import { CreateOrEditSessionDto, CreateOrEditUserAnswerDto, GetInRhithmSessionDto, GetUserSessionForViewDto, QuestionServiceProxy as QuestionServiceProxyMountain, SessionsServiceProxy, UserAnswersServiceProxy, UserSessionServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { SkipQuestionModalComponent } from '../skip-question-modal/skip-question-modal.component';
import { CustomMsgModalComponent } from '../custom-msg-modal/custom-msg-modal.component';
import { Subject, skip, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import * as reducers from './../../../reducers';
import { loadQuestionAllAndSelectedOption } from '@app/shared/shared.actions';

@Component({
  selector: 'app-get-in-rhithm-modal',
  templateUrl: './get-in-rhithm-modal.component.html',
  styleUrls: ['./get-in-rhithm-modal.component.scss']
})
export class GetInRhithmModalComponent implements OnInit, OnDestroy {

  skipCount: number = 0;
  wordCount: number = 0;
  userComment: string = "";
  assessmentTypeId: number;
  questionType: any[] = [];

  totalCount: number = 0;
  selectedQuestionIndex: number = 0;
  selectedOptionIndex?: number;

  type: string;
  isCreateByMyself: boolean = false;

  questionAndSelectedOptions: any = null;
  sessionId: number;
  userAnswerDto: CreateOrEditUserAnswerDto = new CreateOrEditUserAnswerDto();
  questionId: number = 0;
  questionOptionId: number = null;


  disableNextButton: boolean = false;
  disablePreviousButton: boolean = false;
  isDisabled: boolean = false;
  moveOnToNextQuestion: boolean = false;
  isAllowComment: boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();
  createdUserId: any;
  textToSpeechChecked: boolean;

  constructor(
    private store: Store<reducers.State>,
    private _userSessionProxyMoutain: UserSessionServiceProxy,
    private _questionServiceProxyMoutain: QuestionServiceProxyMountain,
    private _questionServiceProxyMountain: QuestionServiceProxyMountain,
    private _sessionsServiceProxyMountain: SessionsServiceProxy,
    private _appSession: AppSessionService,
    private _dataService: DataService,
    private _userAnswerService: UserAnswersServiceProxy,

    private dialogRef: MatDialogRef<GetInRhithmModalComponent>,
    @Inject(MAT_DIALOG_DATA) private unattendedSessionData: any,
    private _dialog: MatDialog
  ) {


  }

  ngOnInit(): void {
    this.textToSpeechChecked = this._appSession.user.isAccessibilityAudioAllow;
    this.showUnattendedSessionsListModal();
    if (this.selectedQuestionIndex === 0) {
      this.isDisabled = true;
    }
    if (this.unattendedSessionData?.sessionId) {
      this.createdUserId = this.unattendedSessionData.createdUserId;
      this.sessionId = this.unattendedSessionData.sessionId;
    }
    if (this.unattendedSessionData?.assessmentTypeId) {
      this.assessmentTypeId = this.unattendedSessionData.assessmentTypeId;
    } else {
      this.assessmentTypeId = AppConsts.DefaultAssessmentTypeId;
    }
    this.getInRhithmForSelectedSession();
  }


  showUnattendedSessionsListModal() {
    this._userSessionProxyMoutain.getAllStartedUserSessions(this._appSession.userId).subscribe((res: GetInRhithmSessionDto) => {
      this.isAllowComment = res.isAllowComment;
    })
  }

  getQuestionAllAndSelectedOption(isNext?: boolean, previous?: boolean, disablePreviousButton?: boolean) {
    let questionId: number;
    if (isNext) {
      questionId = this.questionId + 1;
    } else {
      questionId = this.questionId;
    }
    if (previous) {
      questionId = this.questionId - 1;
    }
    this.questionAndSelectedOptions = null;
    this._questionServiceProxyMoutain.getQuestionAllAndSelectedOption(questionId, this._appSession.userId, this.sessionId).subscribe((res) => {
      // result.questionName = this.dl(result, result.questionName);
      if (previous) {
        disablePreviousButton = false
      }
      this.moveOnToNextQuestion = false;
      let questionName = this._appSession.dl(res, res.questionName);
      res.questionName = questionName ? questionName : res.questionName;

      // will remove this if condition Once it handles from backend.
      if (res.questionOptionList) {
        res.questionOptionList.forEach((option: any) => {
          // option.name = this.dl(option, option.name);
          let questionOptionName = this._appSession.dl(option, option.name);
          option.name = questionOptionName ? questionOptionName : option.name;

          option.fileName = option.name;
          // let translatedValue: string = this.getTranslation(option);
          // if (translatedValue != null) {
          //     option.name = translatedValue;
          // }
        });
        //need to done from backend
        // if (res.questionId === 5) {
        //   res.questionOptionList.forEach((option: any) => {
        //     option.id == 24 && option.name === "Good" ? option.fileName = "Good Social" : null;
        //     option.id == 25 && option.name === "Great" ? option.fileName = "Great Social" : null;
        //   });
        // }
      }

      this.questionAndSelectedOptions = res;
      this.disableNextButton = false;
      if (res.userAnswer.length > 0) {
        this.selectedOptionIndex = res.questionOptionList.findIndex(option => option.id === res.userAnswer[0].questionOptionId);
        this.questionOptionId = res.userAnswer[0].questionOptionId;
        this.questionId = res.questionId;
        this.userComment = res.userAnswer[0].comment;
        this.wordCount = res.userAnswer[0].comment?.length ? res.userAnswer[0].comment?.length : 0;
      }
    })

  }



  getQuestionType(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._questionServiceProxyMountain
        .getAllQuestionTypes(
          this.assessmentTypeId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        )
        .subscribe((result) => {
          this.questionType = result.items;
          this.questionId = result?.items[0]?.questionType.id;
          this.totalCount = result.totalCount;
          resolve(true);
        })
    })
  }

  createUserSessionOrGetAlreadyStartedSession() {
    var currentDate = moment();
    let newSession: CreateOrEditSessionDto = new CreateOrEditSessionDto();
    newSession.name = this._appSession.user.name + AppConsts.desh + this._appSession.user.surname +
      AppConsts.underscore + currentDate.format(AppConsts.dateTimeFormat);
    newSession.isClosed = AppConsts.sessionStatusStarted;
    newSession.rCreatorUserId = this._appSession.userId;
    newSession.classId = null;
    newSession.assessmentTypeId = AppConsts.DefaultAssessmentTypeId;

    this._sessionsServiceProxyMountain.createUserSessionOrGetAlreadyStartedSession(newSession).subscribe((res) => {
      this.createdUserId = this._appSession.userId;
      this.sessionId = res.sessionId;
      this._dataService.onUserSessionChanged(true);
    });
  }

  getAllActiveSessions() {
    //Live Sessions
    this._sessionsServiceProxyMountain.getActiveSessionsForUser(this._appSession.userId).subscribe((res) => {
      this._dataService.onUserSessionChanged(true);

    });
  }

  getInRhithmForSelectedSession() {
    this.showRhithmQuestionsForMyself();
  }


  async showRhithmQuestionsForMyself() {
    if (this.unattendedSessionData?.sessionId) {
      await this.getQuestionType().finally();
      this.getQuestionAllAndSelectedOption();
    } else {
      this.createUserSessionOrGetAlreadyStartedSession();
      await this.getQuestionType().finally();
      // this.showUnattendedSessionsListModal();
      this.getAllActiveSessions();
      this.getQuestionAllAndSelectedOption();
    }
  }

  questionOptionClick(event: any, optionIndex: number, question: any, questionOption: any) {
    this.selectedOptionIndex = optionIndex;
    this.questionOptionId = questionOption.id;
    this.questionId = question.questionId;
    if (event.type == "dblclick") {
      this.switchToNextQuestion();
    }
  }
  /* custom msg */
  customMsgModal() {
    let dialogDate = {
      isNote: false,
      customMsg: "Please Provide An Answer For At least One Of The Questions."
    }
    const dialogRef = this._dialog.open(CustomMsgModalComponent, {
      width: '600px',
      data: dialogDate
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }


  /* custom msg end */

  // Next Question
  switchToNextQuestion() {
    if ((this.selectedQuestionIndex === this.questionType.length) || (this.questionOptionId === null || this.questionOptionId === 0)) {
      if ((this.selectedQuestionIndex === this.questionType.length - 1) && (this.skipCount == this.questionType.length)) {
        this.moveOnToNextQuestion = false;
        this.customMsgModal()
        return
      }
      if (this.moveOnToNextQuestion == false) {
        this.openSkipQuestionDialog()
        return;
      }
      if (!this.moveOnToNextQuestion) {
        return
      }
    }
    this.isDisabled = false;
    this.disableNextButton = true
    this.userAnswerDto.isCompleted = false;
    if (this.selectedQuestionIndex === this.questionType.length - 1) {
      this.userAnswerDto.isCompleted = true;
      this.closeModal();
    }
    if (!this.moveOnToNextQuestion) {
      this.skipCount = 0;
      this.userAnswerDto.questionId = this.questionId;
      this.userAnswerDto.questionOptionId = this.questionOptionId;
    }

    this.userAnswerDto.sessionId = this.sessionId;
    this.userAnswerDto.userComment = this.userComment;
    this.userAnswerDto.userId = this._appSession.userId;
    if ((this.userAnswerDto.isCompleted == true && this._appSession.userId == this.createdUserId) && (this._appSession.isGranted("Pages.Rhithm.Student") || this._appSession.isGranted("Pages.Rhithm.Teacher")
      || this._appSession.isGranted("Pages.Rhithm.Principal") || this._appSession.isGranted("Pages.Rhithm.DistrictOwner"))) {
      this.saveUserAnswerAndProgressAndEndSession()
      this._dataService.onUserSessionChanged(true);
    }
    if (this.userAnswerDto.isCompleted == true) {
      this.wordCount = 0;
      this.showRhithmVideoModal()
      this._dataService.onUserSessionChanged(true);
    }
    if (!this.moveOnToNextQuestion) {
      this._userAnswerService.rCreateOrEditUserAnswerAndProgress(this.userAnswerDto).subscribe((res) => { this.wordCount = 0; });
    }
    if (this.selectedQuestionIndex < this.questionType.length - 1) {
      this.selectedQuestionIndex++;
      this.getQuestionAllAndSelectedOption(true);
    }
    this.userComment = "";
    this.userAnswerDto.userComment = ""
    this.questionOptionId = null;
    this.selectedOptionIndex = undefined;
  }

  saveUserAnswerAndProgressAndEndSession() {
    this._userAnswerService.rCreateOrEditUserAnswerAndProgressAndEndSession(this.userAnswerDto).subscribe(res => {
      this.wordCount = 0;
      this.showRhithmVideoModal()
      this._dataService.onUserSessionChanged(true);
    });
  }

  // Previous Question
  switchToPreviousQuestion() {
    if (this.selectedQuestionIndex === 0) {
      return;
    }
    if (this.selectedQuestionIndex === 1) {
      this.isDisabled = true;
    }
    this.disablePreviousButton = true;
    this.userComment = "";
    this.userAnswerDto.userComment = ""
    this.questionOptionId = null;
    this.selectedOptionIndex = undefined;
    this.selectedQuestionIndex--;
    this.questionAndSelectedOptions = null;
    this.getQuestionAllAndSelectedOption(false, true, this.disablePreviousButton);
    // this._questionServiceProxyMoutain.getQuestionAllAndSelectedOption(this.questionId - 1, this._appSession.userId, this.sessionId).subscribe((res) => {
    //   this.wordCount = 0;
    //   this.questionAndSelectedOptions = res;
    //   this.disablePreviousButton = false;
    //   if (res.userAnswer.length > 0) {
    //     this.selectedOptionIndex = res.questionOptionList.findIndex(option => option.id === res.userAnswer[0].questionOptionId);
    //     this.questionOptionId = res.userAnswer[0].questionOptionId;
    //     this.questionId = res.questionId;
    //     this.userComment = res.userAnswer[0].comment;
    //     this.wordCount = res.userAnswer[0].comment?.length ? res.userAnswer[0].comment?.length : 0;
    //   }

    // })

  }

  openSkipQuestionDialog() {
    const dialogRef = this._dialog.open(SkipQuestionModalComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.skipCurrentQuestionAndMoveOnToNext();
      }
    });
  }

  skipCurrentQuestionAndMoveOnToNext() {
    ++this.skipCount;
    this.moveOnToNextQuestion = true;
    this.switchToNextQuestion();
  }

  onWordCount(ev: any) {
    this.wordCount = ev.target.value.length;
  }

  changeComment(ev: any) {
    this.userComment = ev.target.value;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
  showRhithmVideoModal(): void {
    if (!this._dialog.getDialogById('video-modal')) {

      let dataObj = {
        sessionId: this.sessionId
      }
      const dialogRef = this._dialog.open(VideomodalComponent, {
        width: '1100px',
        minHeight: '590px',
        id: 'video-modal',
        data: {
          apiData: dataObj,
          pageData: 'girModal',
        },
        panelClass: 'video-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
