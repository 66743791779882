import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-skip-question-modal',
  templateUrl: './skip-question-modal.component.html',
  styleUrls: ['./skip-question-modal.component.scss']
})
export class SkipQuestionModalComponent implements OnInit {

  // @Output() onSkipNext: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _dialog: MatDialogRef<SkipQuestionModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  skipCurrentQuestionAndMoveOnToNext(){
    this.closeModal(true);
  }

  answertheQuestion(){
    this.closeModal(false);
  }

  closeModal(bool?:boolean): void {
    this._dialog.close(bool);
  }

}
