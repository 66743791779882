<h3 translate></h3>
<img src="assets/rhithm-logo.svg" class="img-fluid py-3" style="width:50%" />
<div class="form-style">
  <form>
    <!-- <div class="alert alert-danger">
      Username or password incorrect.
    </div> -->
    <div class="form-group">
      <label class="d-block">
        <input type="text" [(ngModel)]="userObj" name="userObj" disabled  class="form-control"  autocomplete="username"/>
        <span hidden translate>Username</span>
        <!-- <small [hidden]="
                    loginForm.controls.username.valid ||
                    loginForm.controls.username.untouched
                  " class="text-danger" translate>
          Username is required
        </small> -->
      </label>
      <label class="d-block">
        <div *ngIf="userHasMultipleTenants">
          <mat-select [disableOptionCentering]="true" panelClass="myPanelClass1" class="form-control custom-mat-select" [(ngModel)]="tenantName" name="tenantName" [compareWith]="compareObjects">
            <mat-select-trigger>
                {{ getDisplayValue(tenantName) }}
            </mat-select-trigger>
            <mat-option *ngFor="let em of tenantList" [value]="em" class="mat-opt"
              (click)="switchTenant(em)" [(ngModel)]="tenantName" name="tenantName">{{getDisplayValue(em)}}</mat-option>
          </mat-select>
        </div>
      </label>
    </div>
    <div class="footer-btn-set">
        <button  (click)="logout()" class="btn btn-secondary back-btn" type="submit">
          <!-- <i class="fas fa-cog fa-spin"></i> -->
          <span translate>Back</span>
        </button>
        <button (click)="loginEmail()" class="btn btn-primary continue-btn" type="submit">
          <!-- <i class="fas fa-cog fa-spin"></i> -->
          <span translate style="color: #fff;">Continue</span>
        </button>
    </div>
  </form>
</div>