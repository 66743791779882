import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as reducers from '../../reducers';
import { Store } from '@ngrx/store';
import { Subject, skip, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/shared/local-storage.service';
import { getLanguages } from '../../reducers';
import { ChangeUserLanguageDto, UserServiceProxy, UserSessionServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { changeLanguage, updateAppVersion } from '../../app.actions';
import { MatDialog } from '@angular/material/dialog';
import { AttemptSessionModalComponent } from '@app/shared/modal/attempt-session-modal/attempt-session-modal.component';
import { AuthenticationService } from '@app/auth/authentication.service';
import { DataService } from '@app/shared/data.service';
import { CredentialsService } from '@app/auth/credentials.service';
import { AppConsts } from 'src/shared/AppConsts';
import { HttpParams } from '@angular/common/http';
import { FeatureFlagEnum } from 'src/shared/FeatureFlags';
import { MatTooltip } from '@angular/material/tooltip';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuHidden = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail = '';
  useNucleus = false;
  logoutUrl = '';
  languages: any = null;
  currentLang: any = null;
  isSuperAdmin = false;
  isStudent: boolean = false;
  startedAndIncompleteSessionsLength: number = 0;
  isToggleOn: boolean = false;
  Feature_RHIT4444: any;
  @ViewChild('tooltipqq', { static: true }) tooltip!: any;
  Feature_RHIT4470: boolean;
  isOklahoma: boolean = false;
  constructor(
    private store: Store<reducers.State>,
    private router: Router,
    private credentialsService: CredentialsService,
    private _localStorage: LocalStorageService,
    private _appSessionService: AppSessionService,
    private _dialog: MatDialog,
    private _dataService: DataService,
    private authenticationService: AuthenticationService,
    private _userSessionProxyMoutain: UserSessionServiceProxy,
    private _userSessionService: UserServiceProxy,
  ) { }

  headerIcon = {
    icon: 'rhithm-logo',
    clickEvent: () => { }
  }
  ngAfterViewInit() {
    // // Show the tooltip after the view has been initialized
    // setTimeout(() => this.tooltip.show(), 0);

    // // Hide the tooltip after 5 seconds
    // setTimeout(() => this.tooltip.hide(), 5000);
  }
  ngOnInit() {
    this._appSessionService.isGranted('Pages.UserSessions.Edit');
    this.Feature_RHIT4444 = AppConsts.isFeatureEnabled(FeatureFlagEnum.Feature_RHIT4444);
    this.Feature_RHIT4470 = AppConsts.isFeatureEnabled(FeatureFlagEnum.Feature_RHIT4470);
    this.isStudent = this._appSessionService.isGranted('Pages.Rhithm.Student') == 'true' && !this.Feature_RHIT4470 ? true : false;
    this.isOklahoma = this._appSessionService.user.districtId == 20422 ? true : false;
    this.store.select(getLanguages).subscribe(res => {
      this.languages = res;
      this.store.select(reducers.getUserCurrentLanguage).subscribe(res => {
        this.currentLang = res.name;
      })
    })
    this.logoutUrl = this.setLogoutUrl();
    this.userEmail = localStorage.getItem('user-email');
    this._localStorage.watchStorage().subscribe(local => {
      if (local == 'added') {
        this.userEmail = localStorage.getItem('user-email');
      }
    })
    this._dataService.onSessionChanged.subscribe(res => {
      if (res) {
        this.showUnattendedSessionsListLength()

      } else {
        this.showUnattendedSessionsListLength()
      }
    })
  }



  setLogoutUrl() {
    let url = window.location.href.replace('://', '');
    url =
      'https://' +
      url.substring(url.indexOf('.') + 1, url.indexOf('/')) +
      '/api/v2/logout' +
      '?_csrf=' +
      this.getXSRFToken();
    return url;
  }

  getXSRFToken(): string {
    let XSRFToken = '';
    const value = `; ${document.cookie}`;
    const parts = value.split(`; XSRF-TOKEN=`);
    if (parts.length === 2) {
      XSRFToken = parts.pop().split(';').shift();
    }
    return XSRFToken;
  }

  gotoUsersAccount() {
    this.router.navigate(['/my-account']);
  }

  openDialog() {
    const dialogRef = this._dialog.open(AttemptSessionModalComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  switchToClassicUI() { }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  logout() {
    this.authenticationService.logout();
  }
  onChangeLanguage(lang: string) {
    const input = new ChangeUserLanguageDto();
    input.languageName = lang;
    this.store.dispatch(changeLanguage.init({ payload: input }))
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  async redirectionOnToggle() {
    this.store.dispatch(updateAppVersion.init({ payload: 1 }));
    this.store.select(reducers.canRedirectTVersion1).pipe(skip(1), takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        const baseUrl = AppConsts.toggleRedirectUrl;
        let securlySSO = AppConsts.isFeatureEnabled(FeatureFlagEnum.UseSecurlySso);
        if (securlySSO) {
          let getTenantIdCookie = this.credentialsService.getCookie('Abp.TenantId')
          let districtId = this._appSessionService.user.districtId;

          // Parameters
          const params = new HttpParams()
            .set('tenantId', getTenantIdCookie)
            .set('toggleSwitchVersion', true)
            .set('districtId', districtId)

          // Construct URL with parameters
          const urlWithParams = `${baseUrl}?${params.toString()}`;
          window.location.href = urlWithParams
        }
        else {
          window.location.href = baseUrl
        }
      }
    });
  }


  showUnattendedSessionsListLength() {
    this._userSessionProxyMoutain.getAllStartedUserSessions(this._appSessionService.userId).subscribe((res) => {
      if (res) {
        this.startedAndIncompleteSessionsLength = res.getUserSessions.filter(x => x.isCompleted == false)?.length;
      }
    })
  }
}