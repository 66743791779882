<div class="content-container form-card">
  <img src="assets/artboard-1-copy-39.png" alt="change password image" />
  <h4 class="header">Set new password</h4>
  <form [formGroup]="passwordChangeForm">
    <div class="form-group">
      <label class="label-text" for="password">Password</label>
      <div class="password-field">
        <input
          (blur)="checkPasswordsMatch()"
          [type]="fieldTextType ? 'text' : 'password'"
          class="form-control"
          formControlName="password"
          name="password"
          id="passwordChange"
          placeholder="Password"
        />
        <span class="error-text" *ngIf="checkPasswordMatchError('password')"
          >Passwords do not match.</span
        >
        <span class="error-text" *ngIf="checkMinLengthError('password')"
          >Password must be at least 8 characters.</span
        >
        <div class="input-group-append">
          <span>
            <i
              class="fa"
              [ngClass]="{
                'fa-eye-slash': !fieldTextType,
                'fa-eye': fieldTextType
              }"
              (click)="toggleFieldTextType()"
            ></i>
          </span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="label-text" for="confirmPassword">Confirm Password</label>
      <div class="password-field">
        <input
          (blur)="checkPasswordsMatch()"
          [type]="fieldTextType ? 'text' : 'password'"
          class="form-control"
          formControlName="confirmPassword"
          name="confirmPassword"
          id="confirmPasswordChange"
          placeholder="Confirm Password"
        />
        <span
          class="error-text"
          *ngIf="checkPasswordMatchError('confirmPassword')"
          >Passwords do not match.</span
        >
        <span class="error-text" *ngIf="checkMinLengthError('confirmPassword')"
          >Password must be at least 8 characters.</span
        >
        <div class="input-group-append">
          <span>
            <i
              class="fa"
              [ngClass]="{
                'fa-eye-slash': !fieldTextType,
                'fa-eye': fieldTextType
              }"
              (click)="toggleFieldTextType()"
            ></i>
          </span>
        </div>
      </div>
    </div>
  </form>
  <button
    [disabled]="isFormInvalidOrUntouched()"
    class="btn btn-primary"
    (click)="changePasswordSubmit()"
  >
    Save
  </button>
</div>
