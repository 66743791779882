import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { skip, takeUntil } from 'rxjs/operators';
import * as reducers from '../reducers';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isToggleSidebar: boolean;

  constructor(private router: Router, private store: Store<reducers.State>) {}

  ngOnInit() {}
  /**
   * In case of active class page, hider Sidebar & header,
   * It has different view
   */
  get sideNavigation() {
    return true; // Update - show on active class as well
  }
  /**
   * In case of active class page, hider Sidebar & header,
   * It has different view
   */
  get showHeader() {
    return this.router.url.indexOf('classes/activeclass') === -1;
  }
}
