
import { APP_INITIALIZER, Inject, Injector, NgModule } from '@angular/core';

import * as ngCommon from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatDialogModule } from '@angular/material/dialog';
import { AngularMaterialModule } from './shared/angular-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducers/index';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '@env/environment';
import { CoreModule, ErrorHandlerInterceptor } from '@core';
import { SharedModule } from './shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterEffects } from './router/router.effects';
export const metaReducers = [storeFreeze];
import { CoreService } from './core/core.service';
import { AccountsEffects } from './accounts/accounts.effects';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
  FullRouterStateSerializer,
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomRouterStateSerializer } from './router/utils';
import { AppEffects } from './app.effects';
import { WindowRef } from './core/window.interface';
import { CommonModule, HashLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { RedirectLogoutInterceptor } from './shared/interceptors/redirect-logout.interceptor';
import { SettingEffects } from './settings/settings/settings.effects';
import { AppSessionService } from './shared/common/session/app-session.service';
import { WellnessTipsEffects } from './wellness-tips/wellness-tips.effects';
import { LanguageServiceProxy, PusherCachingServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { DashboardEffects } from './dashboard/dashboard.effect';
import { RhithmHttpInterceptor } from './@core/http/rhithmHttpInterceptor';
import { CacheInterceptor } from './@core/http/cache-interceptor';
import { RealTimeUpdatePusherService } from './shared/common/real-time-update/real-time-update-pusher.service';
@NgModule({
  imports: [
    ngCommon.CommonModule,
    SharedModule,
    ShellModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectLogoutInterceptor,
      multi: true,
    },
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: Location, useClass: HashLocationStrategy },
    RealTimeUpdatePusherService,
    PusherCachingServiceProxy
  ],

})
export class AppModule { }
