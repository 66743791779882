import { Component, OnInit, OnDestroy } from '@angular/core';
import { RealTimeUpdatePusherService } from './shared/common/real-time-update/real-time-update-pusher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private _realTimeUpdatePusherService: RealTimeUpdatePusherService,
  ) {
  }

  async ngOnInit() {
    this._realTimeUpdatePusherService.registerEvents();
  }

  ngOnDestroy() {
  }
}
