import { getRistAlertList } from './../../reducers/index';
import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";
import { DashboardFilterInputDto, RiskAlertDropDownRolesWithUsers, SessionAssessmentTypeDto } from "src/shared/service-proxies/service-proxies";
import { RiskAlertTemplateV2DtoPagedResultDto } from 'src/shared/service-proxies/service-proxies-v2';

export const loadRiskAlert = createAsyncAction(
    '[Risk Alert] Get list of risk alert',
    props<any>(),
    props<{payload: any }>(),
    props<{ message: string }>()
);

export const createEmojiRiskAlert = createAsyncAction(
    '[emoji risk alert] create emoji risk alert',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const assessmentTypes = createAsyncAction(
    '[assessmentTypes] list of assessmentTypes',
    props<any>(),
    props<{ items: SessionAssessmentTypeDto[] }>(),
    props<{ message: string }>()
);

export const getGrades = createAsyncAction(
    '[getGrades] list of grades',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const getClasses = createAsyncAction(
    '[getClasses] list of Classes',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const getGroups = createAsyncAction(
    '[getGroups] list of groups',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const getStudents = createAsyncAction(
    '[getStudents] list of students',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const getTeachers = createAsyncAction(
    '[getTeachers] list of teachers',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const getAdmins = createAsyncAction(
    '[getAdmins] list of admins',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const getEmojiAssessmentQuestions = createAsyncAction(
    '[getEmojiAssessmentQuestions] list of questions and options',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);


export const getAlertRecipients = createAsyncAction(
    '[getRistAlertRecipients] list of getAlertRecipients',
    props<any>(),
    props< { items: RiskAlertDropDownRolesWithUsers[]  }>(),
    props<{ message: string }>()
);
export const createTempoRiskAlert = createAsyncAction(
    '[createTempoRiskAlert] create tempo risk alert',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const createNoteAlert = createAsyncAction(
    '[createNoteAlert] create note risk alert',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);