import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppConsts } from 'src/shared/AppConsts';
import { SwitchTenantResultModel, TokenAuthServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { LoginService } from '../login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserQRCodeReader } from '@zxing/library';
import { CredentialsService } from '../credentials.service';

@Component({
  selector: 'app-sso-qr-login',
  templateUrl: './sso-qr-login.component.html',
  styleUrls: ['./sso-qr-login.component.scss']
})
export class SsoQrLoginComponent implements OnInit {

  @ViewChild("video", { static: false }) videoElement: ElementRef;
  private codeReader: BrowserQRCodeReader;
  qrLogin: any;
  qrhideandshow = false;
  redirectUrl: string = '/'

  constructor(
    private _TokenAuthServiceProxy: TokenAuthServiceProxy,
    public loginService: LoginService,
    private router: Router,
    private _activedRouter: ActivatedRoute,
    private _credentialsService: CredentialsService,
  ) {
    this.codeReader = new BrowserQRCodeReader();
    this.scanQRCode();
    this._activedRouter.queryParams.subscribe((qParam: any) => {
      if (qParam.redirecturl) {
        this.redirectUrl = qParam.redirecturl
      }
    })
  }

  ngOnInit(): void {

  }

  async scanQRCode() {
    this.qrhideandshow = true;
    try {
      const constraints = {
        video: true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(
        constraints
      );
      this.videoElement.nativeElement.srcObject = stream;
      this.codeReader.decodeFromVideoDevice(
        undefined,
        "video",
        (result: any) => {
          if (result) {
            stream.getTracks().forEach((track) => track.stop()); // Stop the camera stream
            this.qrLogin = result.getText();
            this.codeReader.reset();
            this.LoginFromQR(this.qrLogin)
          }
        }
      );
    } catch (err) {
    }
  }

  switchtobuttonview() {
    this.codeReader && this.codeReader.reset();
    document.getElementById("video").textContent = "";
    this.qrhideandshow = false
    this.router.navigate([AppConsts.loginUrl]);
  }

  LoginFromQR(param?: any) {
    let getWindowLink = param;
    this._TokenAuthServiceProxy.qRTokenValidate(
      getWindowLink
    ).subscribe((res: SwitchTenantResultModel) => {
      this._credentialsService.setCookie('securly_token', res.accessToken, 30)
      this.redirectUrl = `${this.redirectUrl}&access_token=${res.accessToken}`
      this.loginService.login(res.accessToken, res.encryptedAccessToken, res.expireInSeconds, false, '', this.redirectUrl);
      this._credentialsService.setCookie('Abp.TenantId', res.tenantId, 30)
    },
      (err: any) => {
        this.scanQRCode();
      });

  }
  onBack() {
    window.location.href = window.location.origin;
  }


}
