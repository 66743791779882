import { Directive, ElementRef, Renderer2, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[appSelectAll]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SelectAllDirective,
    multi: true
  }]
})
export class SelectAllDirective implements AfterViewInit {
  @Input() matSelect: MatSelect;
  @Input() matCheckBox: MatCheckbox;
  @Input() items: any;
  @Input() propName?: string;
  @Output() onChangeValue: EventEmitter<any> = new EventEmitter;
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }
  ngAfterViewInit(): void {
    this.matSelect.valueChange.subscribe((value) => {
      if (value.length == this.items.length) {
        this.matCheckBox.checked = true// Emit the value to the output event
      } else {
        this.matCheckBox.checked = false
      }
    });
    this.matCheckBox.change.subscribe((value) => {
      if (value.checked) {
        if (this.propName) {
          this.matSelect.value = this.items.map((res: any) => {
            return res[this.propName]
          });
        } else {
          this.matSelect.value = this.items;
        }
        this.onChangeValue.emit('onSelectAll')

      } else {
        this.matSelect.value = []
        this.onChangeValue.emit('onSelectAll')
      }
    })
  }
}