import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { httpErrorResponseHandler } from "./http-error-response-handler";
import { loadAllStartedUserSessions, loadAllUserNotes, loadQuestionAllAndSelectedOption } from "./shared.actions";
import { QuestionServiceProxy as QuestionServiceProxyMountain, UserAnswersServiceProxy, UserSessionServiceProxy } from "src/shared/service-proxies/service-proxies";
import { Injectable } from "@angular/core";
@Injectable()
export class SharedEffects {
    constructor(
        private actions$: Actions,
        private _userSessionService: UserSessionServiceProxy,
        private _userAnswersService: UserAnswersServiceProxy,
        private _questionServiceProxyMoutain: QuestionServiceProxyMountain,
    ) { }
    loadAllStartedUserSessions$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAllStartedUserSessions.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userSessionService.getAllStartedUserSessions(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAllStartedUserSessions.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all started user sessions.',
                            errorMapping: {},
                            failureAction: loadAllStartedUserSessions.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all started user sessions..',
                        })
                    })
                )
            })
        )
    })
    loadAllUserNotes$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAllUserNotes.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userAnswersService.getUserNoteDetailsBySessionId({ ...payload.payload }).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAllUserNotes.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all user notes.',
                            errorMapping: {},
                            failureAction: loadAllUserNotes.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all user notes..',
                        })
                    })
                )
            })
        )
    })

    loadQuestionAllAndSelectedOption$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadQuestionAllAndSelectedOption.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._questionServiceProxyMoutain.getQuestionAllAndSelectedOption(
                    payload.payload.questionId,
                    payload.payload.sessionId,
                    payload.payload.userId,
                    ).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadQuestionAllAndSelectedOption.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Question and Selected Option .',
                            errorMapping: {},
                            failureAction: loadQuestionAllAndSelectedOption.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all get Question and Selected Option',
                        })
                    })
                )
            })
        )
    })

}