import { changeLanguage, getAppConfig, isLoginLoader, loadColumnSettings, loadCurrentLoginInfo, loadUserConfiguration, resetColumnSettings, resetTable, updateAppVersion } from "./app.actions";

export interface State {
  notification: any;
  columnSettings: any[];
  resetColumnStatus: boolean;
  resetTable: boolean;
  appConfig: any;
  currentLoginInfo: any;
  userConfiguration: any;
  languages: any;
  changeLang: any;
  localization: any;
  currentLanguage: any;
  canRedirectToV1: boolean;
  loginLoader: boolean;
}

const initialState: State = {
  notification: {},
  columnSettings: [],
  resetTable: false,
  loginLoader: false,
  resetColumnStatus: false,
  appConfig: null,
  currentLoginInfo: null,
  userConfiguration: null,
  languages: null,
  changeLang: null,
  localization: null,
  currentLanguage: null,
  canRedirectToV1: false,
};

// tslint:disable-next-line: typedef
export function reducer(state = initialState, action: any): State {
  let stateToReturn = state; // default

  switch (action.type) {
    case loadColumnSettings.success.type: {
      return Object.assign({}, state, {
        columnSettings: action.body,
      });
    }
    case loadColumnSettings.init.type: { }
    case loadColumnSettings.failure.type: {
      return Object.assign({}, state, {
        columnSettings: [],
      });
    }
    case resetColumnSettings.init.type: {
      return Object.assign({}, state, {
        resetColumnStatus: false,
      });
    }
    case resetColumnSettings.success.type: {
      return Object.assign({}, state, {
        resetColumnStatus: true,
      });
    }
    case resetTable.type: {

      return Object.assign({}, state, {
        resetTable: true,
      });
    }
    case isLoginLoader.type: {
      return Object.assign({}, state, {
        loginLoader: action.isLoding,
      });
    }

    case getAppConfig.type: {
      return Object.assign({}, state, {
        appConfig: action,
      });
    }
    case loadCurrentLoginInfo.type: {
      return Object.assign({}, state, {
        currentLoginInfo: action,
      });
    }
    case loadUserConfiguration.type: {
      return Object.assign({}, state, {
        userConfiguration: action,
        localization: action?.localization,
        languages: action?.localization?.languages,
        currentLanguage: action?.localization?.currentLanguage,
      });
    }
    case changeLanguage.init.type: {
      return Object.assign({}, state, {
        payload: action.payload,
      });
    }
    case changeLanguage.success.type: {

      // return Object.assign({}, state, {
      //   changeLang: action,
      // });
    }
    case updateAppVersion.init.type: {
      return Object.assign({}, state, {
        payload: action.payload,
      });
    }
    case updateAppVersion.success.type: {
      return Object.assign({}, state, {
        canRedirectToV1: true,
      })
    }
    default:
      stateToReturn
      break;
  }
  return stateToReturn;

}
export const getGridColumnSettings = (state: State) => state.columnSettings;
export const getResetGridColumnSettings = (state: State) => state.resetColumnStatus;
export const getResetTable = (state: State) => state.resetTable;
export const getAppConfigData = (state: State) => state.appConfig;
export const getCurrentLoginInfo = (state: State) => state.currentLoginInfo;
export const getUserConfiguration = (state: State) => state.userConfiguration;
export const getAllLanguages = (state: State) => state.languages;
export const getCurrentLanguage = (state: State) => state.currentLanguage;
export const canRedirectToVersion1 = (state: State) => state.canRedirectToV1;
export const getLoginLoader = (state: State) => state.loginLoader;
