import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  Input,
} from '@angular/core';

/*
 * Directive to ellipsify overflowing text and show popover only for ellipsified content
 *
 * Example:
 * <div [disableTooltip] #c="disableTooltip"
 * [matTooltip]="name"
 * [matTooltipDisabled]="c.disableTooltip"
 * matTooltipPosition="above"
 * matTooltipClass="title-tooltip">
 *    <span class="text">{{ name }}</span>
 * </div>
 *
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[disableTooltip]',
  exportAs: 'disableTooltip',
})
export class DisableTooltipDirective {
  domElement: any;
  @Input('disableTooltip') disableTooltip = false;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.domElement = this.elementRef.nativeElement;
    const ellipsify = {
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
      'white-space': 'nowrap',
    };
    Object.keys(ellipsify).forEach((element) => {
      this.renderer.setStyle(this.domElement, `${element}`, ellipsify[element]);
    });
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    const element = this.elementRef.nativeElement;
    this.disableTooltip = element.offsetWidth >= element.scrollWidth;
  }
}
