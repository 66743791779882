import { CompilerOptions, Injector, NgModuleRef, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConsts } from './shared/AppConsts';
import { SubdomainTenancyNameFinder } from './shared/helpers/SubdomainTenancyNameFinder';
import * as _ from 'lodash';
import { UrlHelper } from './shared/helpers/UrlHelper';
import { XmlHttpRequestHelper } from './shared/helpers/XmlHttpRequestHelper';
import { environment } from './environments/environment';
import { FeatureFlagEnum, RhithmFeatureFlagDto } from './shared/FeatureFlags';
import { AppSessionService } from './app/shared/common/session/app-session.service';

export class AppPreBootstrap {

    static run(appRootUrl: string, callback: () => void, resolve: any, reject: any): void {
        AppPreBootstrap.getApplicationConfig(appRootUrl, () => {
            const queryStringObj = UrlHelper.getQueryParameters();
            let toggleFlag = AppConsts.isFeatureEnabled(FeatureFlagEnum.Feature_RHIT4444);
            let securlySSO = AppConsts.isFeatureEnabled(FeatureFlagEnum.UseSecurlySso);
            if (toggleFlag && queryStringObj.toggleSwitchVersion && securlySSO) {
                AppPreBootstrap.getRhithmCookiesUserInfo(queryStringObj);
            }
            AppPreBootstrap.getUserConfiguration(callback);
        });
    }

    private static getRhithmCookiesUserInfo(queryStringObj: any) {
        if (queryStringObj.toggleSwitchVersion) {
            this.removeCookie('securly_token')
            this.removeCookie('Abp.TenantId')
            this.removeCookie('RhithmUserInfo')
            if (queryStringObj.tenantId != null && queryStringObj.tenantId != 'null') {
                this.setCookie(
                    'Abp.tenantId',
                    queryStringObj.tenantId,
                    new Date(new Date().getTime() + 5 * 365 * 86400000),
                );
            }
            if (queryStringObj.districtId != null && queryStringObj.districtId != 'null') {
                this.setCookie(
                    'Abp.districtId',
                    queryStringObj.districtId,
                    new Date(new Date().getTime() + 5 * 365 * 86400000),
                );
            }
        }
    }

    static bootstrap<TM>(moduleType: Type<TM>, compilerOptions?: CompilerOptions | CompilerOptions[]): Promise<NgModuleRef<TM>> {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    }

    private static getApplicationConfig(appRootUrl: string, callback: () => void) {
        let type = 'GET';
        let url = appRootUrl + 'assets/' + environment.appConfig;
        let customHeaders = [
            {
                name: 'Abp.TenantId',
                value: 'null'
            }];

        XmlHttpRequestHelper.ajax(false, type, url, customHeaders, null, (result: any) => {
            const subdomainTenancyNameFinder = new SubdomainTenancyNameFinder();
            const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.appBaseUrl);

            AppConsts.appBaseUrlFormat = result.appBaseUrl;
            AppConsts.toggleRedirectUrl = result.toggleRedirectUrl;
            AppConsts.remoteServiceBaseUrlFormat = result.remoteServiceBaseUrl;
            AppConsts.localeMappings = result.localeMappings;
            AppConsts.appVersion = result.appVersion;
            AppConsts.appEnvironmentName = result.appEnvironmentName;
            AppConsts.pusher.cluster = result.pusher.cluster;
            AppConsts.pusher.app_key = result.pusher.app_key;
            AppConsts.azureTextToSpeech.subscriptionKey = result.azureTextToSpeech.subscriptionKey;
            AppConsts.azureTextToSpeech.serviceRegion = result.azureTextToSpeech.serviceRegion;
            AppConsts.azureTextToSpeech.speechTokenbaseUrl = result.azureTextToSpeech.speechTokenbaseUrl;
            AppConsts.rmsUrls.metricReaderServiceUrl = result.rmsUrls.metricReaderServiceUrl;
            AppConsts.rmsUrls.loginServiceUrl = result.rmsUrls.loginServiceUrl;
            AppConsts.rmsUrls.loginServiceAwsUrl = result.rmsUrls.loginServiceAwsUrl;
            AppConsts.rmsUrls.featuresServiceUrl = result.rmsUrls.featuresServiceUrl;

            if (result.securlySso != undefined) {
                AppConsts.securlySso.baseUrl = result.securlySso.baseUrl;
                AppConsts.securlySso.clientId = result.securlySso.clientId;
            }

            if (tenancyName == null) {
                AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                AppConsts.mountainRemoteServiceBaseUrl = result.apiBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                // AppConsts.googleMapApiKey = result.googleMapApiKey;

            } else {
                AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                AppConsts.mountainRemoteServiceBaseUrl = result.apiBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                // AppConsts.googleMapApiKey = result.googleMapApiKey;

            }

            var featuresServiceUrl = AppConsts.mountainRemoteServiceBaseUrl + '/api/Config/GetAllFeatureFlags';

            XmlHttpRequestHelper.ajax(false, 'GET', featuresServiceUrl, null, null, (result: any) => {

                const featureFlagEnumStringList = Object.keys(FeatureFlagEnum).filter((item) => {
                    return isNaN(Number(item));
                });

                var list: Array<RhithmFeatureFlagDto> = [];

                featureFlagEnumStringList.forEach(function (featureFlagEnumString: string) {
                    const FormatedFeatureName = featureFlagEnumString.substring(0, 1).toLowerCase() + featureFlagEnumString.substring(1)
                    let obj = new RhithmFeatureFlagDto();
                    obj.feature = FeatureFlagEnum[featureFlagEnumString];
                    obj.featureName = featureFlagEnumString;
                    obj.isEnabled = result[FormatedFeatureName] == "true" ? true : false;
                    list.push(obj);
                });

                AppConsts.rhithmFeatureFlags = list;

                callback();
            });

        });
    }

    private static getUserConfiguration(callback: () => void): any {
        const cookieLangValue = this.getCookie('Abp.Localization.CultureName');
        const token = this.getCookie('securly_token');
        let userLoginInfoCookieValue: any;

        if (AppConsts.isFeatureEnabled(FeatureFlagEnum.UseSecurlySso)) {
            userLoginInfoCookieValue = decodeURIComponent(this.getCookie("RhithmUserInfo"));
        }

        let requestHeaders = {
            '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue),
            'Abp.TenantId': this.getCookie('Abp.TenantId')
        };

        if (token) {
            requestHeaders['Authorization'] = 'Bearer ' + token;
        }

        if (AppConsts.isFeatureEnabled(FeatureFlagEnum.UseSecurlySso)) {
            if (userLoginInfoCookieValue != "null") {
                requestHeaders['r.lInfo'] = userLoginInfoCookieValue;
            }
        }


        XmlHttpRequestHelper.ajax(true, 'GET', AppConsts.mountainRemoteServiceBaseUrl + '/api/User/GetAllUserConfiguration', requestHeaders, null, (response: any) => {
            // let appSessionService = injector.get(AppSessionService)
            _.merge(AppSessionService, response?.result.auth);
            _.merge(AppSessionService, response);
            // appSessionService.grantedPermissions = response?.result.auth?.grantedPermissions;
            callback();
        });
    }

    private static getCookie(name: string): string | null {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this is the cookie we are looking for
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null; // Cookie not found
    }

    private static removeCookie(name: string): void {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    private static setCookie(name: string, value: string | number, days: any = 7): void {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + days);

        const cookieValue = encodeURIComponent(value) + (days ? `; expires=${expirationDate.toUTCString()}` : '');

        document.cookie = `${name}=${cookieValue}; path=/`;
    }
}
