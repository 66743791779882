<div class="spinner-wrapper" *ngIf="isLoader">
    <mat-spinner strokeWidth="2"></mat-spinner>

</div>
<div class="container-fluid" *ngIf="!isLoader">
    <div class="dialog-main" *ngIf="isAssessmentDisable">
        <div class="header-wrapper">
            <div class="cursor_pointer"><span (click)="closeModal()"><mat-icon svgIcon="close-icon"
                        title="close"></mat-icon></span></div>
            <div>
                <h5 class="heading_color">{{"Get In Rhithm"}}</h5>
            </div>
        </div>
        <div class="disable-msg">
            <p>
                <strong>⚠️ Sorry! The Get In Rhithm feature is disabled for students at this moment by your District
                    Admin.</strong>
            </p>
        </div>
    </div>


    <div class="dialog-main" *ngIf="!isAssessmentDisable">
        <div class="header-wrapper">
            <div class="cursor_pointer"><span (click)="closeModal()"><mat-icon svgIcon="close-icon"
                        title="close"></mat-icon></span></div>
            <div>
                <h5 class="heading_color">{{startedAndIncompleteSessionsArray.length === 0 ? "Get In Rhithm" : "On Going
                    Session"}}</h5>
            </div>
        </div>
        <div class="btns-wrapper">
            <ng-container
                *ngIf="(!startedAndIncompleteSessionsArray || startedAndIncompleteSessionsArray.length === 0)">

                <div class="buttons w-100">
                    <button class="btn btn-primary w-100" (click)="showGetInRhithmForMyself()">{{'GetMyselfInRhithm' |
                        languagePipe}}</button>
                </div>

            </ng-container>

            <ng-container *ngIf="startedAndIncompleteSessionsArray && startedAndIncompleteSessionsArray.length > 0 ">
                <ng-container *ngFor="let unattendedSession of startedAndIncompleteSessionsArray; let i = index">
                    <div class="buttons w-100">
                        <button class="btn btn-primary w-100"
                            (click)="showGetInRhithmForSelectedSession(unattendedSession)">{{
                            unattendedSession.sessionName}}
                            &nbsp;{{unattendedSession.createdOn | date: 'MM/dd/yyyy - hh:mm a' }}
                        </button>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isTeacher">
                <div class="buttons w-100">
                    <button class="btn btn-primary w-100" (click)="openClassDialog()">{{'GetMyClassInRhithm' |
                        languagePipe}}</button>
                </div>

            </ng-container>
        </div>

    </div>
</div>