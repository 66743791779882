

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInfoDto, UiCustomizationSettingsDto } from '../../../../shared/service-proxies/service-proxies';
import { UserServiceProxy as UserServiceProxyMountain, UserLoginInfoDto, TenantLoginInfoDto, GetCurrentLoginInformationsOutput, GetCurrentLoginInformationsOutputBasic } from '../../../../shared/service-proxies/service-proxies';
import { UserServiceProxy } from '../../../../shared/service-proxies/service-proxies';
import { environment } from '@env/environment';
import { PlatformLocation } from '@angular/common';
import { Store } from '@ngrx/store';
import * as reducers from '../../../reducers';
import { getAppConfig, loadCurrentLoginInfo, loadUserConfiguration } from '@app/app.actions';
import { getAppConfigData } from '../../../app.reducer';
import { XmlHttpRequestHelper } from 'src/shared/helpers/XmlHttpRequestHelper';
import { LocalStorageService } from '@app/shared/local-storage.service';
import { AppConsts } from 'src/shared/AppConsts';
import { FeatureFlagEnum, RhithmFeatureFlagDto } from 'src/shared/FeatureFlags';
import { SubdomainTenancyNameFinder } from 'src/shared/helpers/SubdomainTenancyNameFinder';
import { PermissionsService } from '@app/shared/permissions.service';
import { CredentialsService } from '@app/auth/credentials.service';
@Injectable()
export class AppSessionService {

    private _user: UserLoginInfoDto;
    private _appConfig: any;
    private _tenant: TenantLoginInfoDto;
    private _application: ApplicationInfoDto;
    private _theme: UiCustomizationSettingsDto;
    private _permissions: any;
    private _currentLanguage: any;

    constructor(
        private _userServiceProxyMountain: UserServiceProxy,
        private _platformLocation: PlatformLocation,
        private _localStorage: LocalStorageService,
        private _credentialService: CredentialsService,
        private store: Store<reducers.State>,
    ) {
    }
    get currentLanguage(): any {
        return this._currentLanguage;

    }

    set currentLanguage(val: any) {
        this._currentLanguage = val;
    }
    set grantedPermissions(val: any) {
        this._permissions = val;
    }
    get grantedPermissions(): any {
        return this._permissions;

    }
    get application(): ApplicationInfoDto {
        return this._application;

    }

    set application(val: ApplicationInfoDto) {
        this._application = val;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }
    get appConfig(): any {
        return this._appConfig;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenancyName(): string {
        return this._tenant ? this.tenant.tenancyName : '';
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }

    getShownLoginName(): string {
        const userName = this._user.userName;

        return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
    }

    get theme(): UiCustomizationSettingsDto {
        return this._theme;
    }

    set theme(val: UiCustomizationSettingsDto) {
        this._theme = val;
    }



    async init(): Promise<UiCustomizationSettingsDto> {
        return new Promise<UiCustomizationSettingsDto>((resolve, reject) => {
            this._userServiceProxyMountain.getCurrentLoginInformations().toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._permissions = AppSessionService['grantedPermissions'] ? AppSessionService['grantedPermissions'] : null;
                this._tenant = result.tenant;
                this._theme = result.theme;
                ;
                if (result?.user?.emailAddress) {
                    this._localStorage.setItem('user-email', result.user.emailAddress)
                    this._localStorage.setItem('user-name', result.user.name)
                }
                let userConfigs: any = AppSessionService['result'] ? AppSessionService['result'] : null;
                this._currentLanguage = userConfigs?.localization?.currentLanguage;
                this.store.dispatch(loadUserConfiguration(userConfigs));
                this.store.dispatch(loadCurrentLoginInfo(result));
                resolve(result.theme)
            }, (err) => {
                this.logout();
                reject(err)
            });
        });
    }

    initBasic(): Promise<UiCustomizationSettingsDto> {
        return new Promise<UiCustomizationSettingsDto>((resolve, reject) => {
            this._userServiceProxyMountain.getCurrentLoginInformationsBasic().toPromise().then((result: GetCurrentLoginInformationsOutputBasic) => {
                this._user = result.user;
                this._theme = result.theme;

                resolve(result.theme);
            }, (err) => {
                reject(err);
            });
        });
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }
        this._credentialService.setCookie('Abp.TenantId', tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        let isTenant = tenantId > 0;

        if (!isTenant && !this.tenant) { // this is host
            return true;
        }

        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }

    logout() {
        localStorage.removeItem("dateType");
        localStorage.removeItem("treeSelectedTab");
        localStorage.removeItem("selectedDate");
        localStorage.removeItem("selectedParentOption");
        localStorage.removeItem("selectedSubOption");
        localStorage.clear();
    }
    getCookie(name: string): string | null {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this is the cookie we are looking for
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null; // Cookie not found
    }
    isGranted(permissionName: string) {
        return this.grantedPermissions[permissionName] != undefined && this.grantedPermissions[permissionName] != false ? this.grantedPermissions[permissionName] : false;
    }
    dl(source: any, defaultVal: string): string {
        if (!source) {
            return defaultVal;
        }

        if (this.currentLanguage.name == 'en') {
            return defaultVal;
        }

        if (!source.translations || source.translations.length == 0) {
            return defaultVal;
        }

        let result: string;
        source.translations.forEach((element: any) => {
            if (element.language == this.currentLanguage.name) {
                result = element.name;
            }
        });

        return result;
    }
}
