import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() messages: any;
  @Input() selectedChat: any;
  @Input() isClassChat = false;
  @Input() isStudentClassChat: boolean;
  @ViewChild('chatMessageContainer') chatMessageContainer: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {}

  ngOnDestroy() {}
}
