import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";
import { MediaLinksDto, PDGroupsDropdownDto } from "src/shared/service-proxies/service-proxies";
import { NameAndIdDto } from "src/shared/service-proxies/service-proxies-v2";


export const loadResponseGroups = createAsyncAction(
    '[Response Groups] Get list of response group',
    props<any>(),
    props<{ items: PDGroupsDropdownDto[] }>(),
    props<{ message: string }>()
);


export const getToolkitList = createAsyncAction(
    '[Toolkit List] Get list of Toolkits',
    props<any>(),
    props<{ payload:any }>(),
    props<{ message: string }>()
);

export const getGradesList = createAsyncAction(
    '[Grades List] Get list of Grades',
    props<any>(),
    props<{  items: NameAndIdDto[] }>(),
    props<{ message: string }>()
);

export const getAllTeacherTips = createAsyncAction(
    '[teacherTips List] Get list of teacherTips',
    props<any>(),
    props<{  payload:any }>(),
    props<{ message: string }>()
);