import { Component, OnInit } from '@angular/core';
import { AppConsts } from 'src/shared/AppConsts';
import { FeatureFlagEnum } from 'src/shared/FeatureFlags';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {


  }

}
