export class AppEditionExpireAction {
    static DeactiveTenant = "DeactiveTenant";
    static AssignToAnotherEdition = "AssignToAnotherEdition";
}

export enum RhithmDataFileType {
    CsvImport,
    AssessmentIcons,
    GoogleClassRoomEmail,
    RhithmActivityVideoCsv,
    SessionEventCsv,
    GroupOrganizationUnitCsv,
    BulkUserInviteCsvs
}


export enum SsoProviderName {
    ClassLink,
    Clever,
    Google,
    None,
}

export enum RealTimeEventEnum {
    UpdateStudentDashboard = "update-student-dashboard",
    UpdateDistrictLiveSessionDashboard = "update-district-live-session-dashboard",
    UpdateDistrictDashboard = "update-district-dashboard",
    UpdatePrincipalLiveSessionDashboard = "update-principal-live-session-dashboard",
    UpdatePrincipalDashboard = "update-principal-dashboard",
    UpdateTeacherAllPrincipalsDashboards = "update-teacher-all-principals-dashboards",
    UpdateTeacherDashboard = "update-teacher-dashboard",
    UpdateStudentAllTeachersDashboards = "update-student-all-teachers-dashboards",
    UpdateTeacherLiveSessionDashboard = "update-teacher-live-session-dashboard",
    UpdateStudentLiveSessionDashboard = "update-student-live-session-dashboard",
    StartActivitySingleParticipant = "start-activity-single-participant",
    SendSessionClosedNotification = "send-session-closed-notification",
    StartActivityMultipleParticipants = "start-activity-multiple-participants",
    SendHeaderNotification = "send-header-notification",
    SendSessionStartedNotification = "send-session-started-notification",
    SendRiskAlertNotification = "send-risk-alert-notification",
    SendAssessmentCommentNotification = "send-assessment-comment-notification",
    SendAddedAsRiskAlertWatcherNotification = "send-added-as-risk-alert-watcher-notification",
    SendLiveSessionNotification = "send-live-session-notification",
    SendGoogleDataSyncedNotification = "send-google-data-synced-notification",
    SendGoogleDataSyncStartNotification = "send-google-data-sync-start-notification"

}


export enum RealTimePublicEventEnum {
    TriggerHardRefreshForUsers = "send-hard-refresh-notification",
    TriggerClearCacheForUsers = "send-clear-cache-notification"

}

export enum RiskAlertType {
    EmojiAssessmentAlert = "EmojiAssessmentAlert",
    TempScoreAlert = "TempScoreAlert"
}

export enum RiskAlertChannelEnum {
    Email = "Email",
    InApp = "InApp",
    Web="Web"
}

export enum RiskAlertChannelMountainEnum {
    Email = 0,
    InApp = 1,
    Web = 2,
}

export enum DateRangeTypeMountainEnum {
    Day = 0,
    Week = 1,
    Month = 2,
    Year = 3,
}

export enum DayOfWeekMountain {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}