<div *ngIf="isSsoLogin || isLoading" class="spinner-wrapper">
  <mat-spinner strokeWidth="2"></mat-spinner>
</div>
<div *ngIf="!isSsoLogin && !isLoading" class="container-fluid">
  <div *ngIf="loginService.externalLoginProviders.length > 0">
    <div class="links-wrapper">
      <ng-container *ngFor="let provider of loginService.externalLoginProviders">
        <a (click)="externalLogin(provider)">
          <img [src]="'assets/signin-' + provider.name.toLowerCase() + '.png'" />
        </a>
      </ng-container>
      <a [routerLink]="['/account/loginemail']">
        <img src="assets/signin-email.png" alt="" />
      </a>
      <a [routerLink]="['/account/qrlogin']">
        <img src="assets/MicrosoftTeams-qr-image.png" alt="" />
      </a>
    </div>
  </div>
</div>