<securly-header class="custom-header" [headerIcon]="headerIcon">
  <div action-items *ngIf="Feature_RHIT4444 && (!isStudent && !isOklahoma)">
    <div class="row">
      <div class="col">
        <mat-slide-toggle id='redirectToggle' (click)="redirectionOnToggle()"
          matTooltip="Switch back to Rhithm's classic v1 layout for a familiar experience."
          matTooltipClass="custom-tooltip" #tooltipqq disableRipple>
        </mat-slide-toggle>

      </div>
    </div>
  </div>
  <div action-items>
    <button [ngClass]="startedAndIncompleteSessionsLength > 0 ? 'getinrhithm-btn-g' : 'getinrhithm-btn'"
      (click)="openDialog()">
      <ng-container *ngIf="startedAndIncompleteSessionsLength > 0; else elseTemplate">
        <mat-icon svgIcon="icon-share-user-g"></mat-icon>
      </ng-container>
      <ng-template #elseTemplate>
        <mat-icon svgIcon="icon-share-user"></mat-icon>
      </ng-template>
      <span [ngClass]="startedAndIncompleteSessionsLength > 0 ? 'g' : ''">{{'GetInRhithm' | languagePipe}}</span>
    </button>
  </div>
  <!-- <div action-items *ngIf="!isStudent">
    <div ngbDropdown>
      <a id="help-dropdown" ngbDropdownToggle>
        <div class="dropdwon-toggle">
          <span class="badge badge-pill badge-danger">3</span>
          <span>Risk alerts </span>
          <mat-icon svgIcon="icon-angledown"></mat-icon>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="help-dropdown"
        class="dropdown-menu appSettingsDropdown dropdown-menu-right">
        <a class="dropdown-item" href="https://lounge.securly.com/forum/" target="_blank">
          Join the discussion
        </a>
        <a class="dropdown-item" href="https://docs.securly.com/docs" target="_blank">
          Classroom User's Guide
        </a>
      </div>
    </div>

  </div> -->
  <!-- <div action-items>
    <div ngbDropdown>
      <a id="help-dropdown" ngbDropdownToggle>
        <div class="dropdwon-toggle">

          <mat-icon svgIcon="icon-email"></mat-icon>
          <span>Inbox </span>
          <mat-icon svgIcon="icon-angledown"></mat-icon>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="help-dropdown"
        class="dropdown-menu appSettingsDropdown dropdown-menu-right">
        <a class="dropdown-item" href="https://lounge.securly.com/forum/" target="_blank">
          Join the discussion
        </a>
        <a class="dropdown-item" href="https://docs.securly.com/docs" target="_blank">
          Classroom User's Guide
        </a>
      </div>
    </div>

  </div> -->
  <div action-items>
    <div ngbDropdown class="langDropdown">
      <a id="help-dropdown" ngbDropdownToggle>
        <div class="dropdwon-toggle">
          <i class="fas fa-globe-americas languageIconSize"></i>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="help-dropdown"
        class="dropdown-menu appSettingsDropdown dropdown-menu-right">

        <ng-container *ngFor="let item of languages">
          <a [ngClass]="currentLang == item.name ? 'dropdown-item item-selected' : 'dropdown-item'"
            (click)="onChangeLanguage(item.name)">
            {{item.displayName}}
          </a>
        </ng-container>
      </div>
    </div>


  </div>

  <div action-items>

    <div ngbDropdown>
      <a id="asd-dropdown" class="nav-link" ngbDropdownToggle>
        <div class="dropdwon-toggle">
          <mat-icon svgIcon="icon-border-user"></mat-icon>
          <mat-icon svgIcon="icon-angledown"></mat-icon>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="asd-dropdown"
        class="userAccountDropdown appSettingsDropdown dropdown-menu dropdown-menu-right">
        <div class="dropdown-item">
          <mat-icon svgIcon="icon-border-user"></mat-icon>
          <span>{{userEmail}}</span>
        </div>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item" (click)="logout()">
          <mat-icon svgIcon="icon-out"></mat-icon>
          <span>Logout</span>
        </div>

      </div>
    </div>
  </div>
</securly-header>