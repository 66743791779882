
export enum FeatureFlagEnum {
    UseSecurlySso = 0,
    Feature_RHIT3900,
    Feature_RHIT4087,
    Feature_DA139,
    Feature_DA142,
    Feature_PL201,
    Feature_LT7,
    Feature_DA149,
    Feature_DA150,
    Feature_PA199,
    Feature_DA156,
    Feature_DA163,
    Feature_DA168,
    Feature_DA167,
    Feature_DA166,
    Feature_DA168_Revert,
    Feature_DA179,
    Feature_PL251,
    Feature_PLAT448,
    Feature_RHIT3616,
    Feature_PA228,
    Feature_PL295,
    Feature_PL299,
    Feature_DA180,
    Feature_PL297,
    Feature_RHIT3422,
    Feature_PL264,
    Feature_RHIT3973,
    Feature_RHIT4045,
    Feature_RHIT4047,
    Feature_RHIT3976,
    Feature_RHIT3990,
    Feature_RHIT3991,
    Feature_RHIT4021,
    Feature_RHIT4020,
    Feature_RHIT3992,
    Feature_RHIT4063,
    Feature_RHIT4110,
    Feature_RHIT4109,
    Feature_RHIT4096,
    Feature_RHIT4141,
    Feature_RHIT4114,
    Feature_RHIT4136,
    Feature_RHIT4143,
    Feature_RHIT4235,
    Feature_RHIT4237,
    Feature_RHIT4347,
    Feature_RHIT4444,
    Feature_RHIT4470,

}

export class RhithmFeatureFlagDto {
    feature: FeatureFlagEnum;
    featureName: string;
    isEnabled: boolean;
}
