export class XmlHttpRequestHelper {

    static ajax(withCredentials: boolean, type: string, url: string, customHeaders: any, data: any, success: any) {
        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    let result = "";
                    if(xhr.responseText.trim().length > 0){
                        result = JSON.parse(xhr.responseText);
                    }
                    success(result);
                } else if (xhr.status !== 0) {
                    // alert(abp.localization.localize('InternalServerError', 'AbpWeb'));
                }
            }
        };

        url += (url.indexOf('?') >= 0 ? '&' : '?') + 'd=' + new Date().getTime();
        xhr.open(type, url, true);
        xhr.withCredentials = withCredentials;

        for (let property in customHeaders) {
            if (customHeaders.hasOwnProperty(property)) {
                xhr.setRequestHeader(property, customHeaders[property]);
            }
        }

        xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate, max-age=0');
        xhr.setRequestHeader('Content-type', 'application/json');
        if (data) {
            xhr.send(data);
        } else {
            xhr.send();
        }
    }
}
