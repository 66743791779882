import { Directive, OnInit, Renderer2, Input, ElementRef } from '@angular/core';
import { updateColumnSettings } from '@app/app.actions';
import { Store } from '@ngrx/store';
import * as reducers from '../../reducers';
@Directive({
  selector: '[resizeColumn]',
})
export class ResizeColumnDirective implements OnInit {
  @Input('resizeColumn') resizable: boolean;

  @Input() index: number;

  private startX: number;

  private startWidth: number;

  private column: HTMLElement;

  private table: HTMLElement;

  private pressed: boolean;

  constructor(private renderer: Renderer2, private el: ElementRef,private store: Store<reducers.State>) {
    this.column = this.el.nativeElement;
  }

  ngOnInit() {
    if (this.resizable) {
      const row = this.renderer.parentNode(this.column);
      const thead = this.renderer.parentNode(row);
      this.table = this.renderer.parentNode(thead);

      const resizer = this.renderer.createElement('span');
      this.renderer.addClass(resizer, 'resize-holder');
      this.renderer.appendChild(this.column, resizer);
      this.renderer.listen(resizer, 'mousedown', this.onMouseDown);
      this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
      this.renderer.listen('document', 'mouseup', this.onMouseUp);

      this.store
      .select(reducers.getGridColumnSettings)
      .subscribe((res: any[]) => {
        const name = this.getColumnName();
        const index = res.findIndex((item) => item.name === name);
        if (index >= 0) {
          this.renderer.setStyle(
            this.column,
            'width',
            `${res[index]?.width}`
          );
        }
      });
    this.store
      .select(reducers.getResetGridColumnSettings)
      .subscribe((res) => {
        if (res) {
          const width = this.column.getAttribute('width');
          if (width) {
            this.renderer.setStyle(this.column, 'width', `${width}px`);
          } else {
            this.renderer.setStyle(this.column, 'width', `200px`);
          }
        }
      });
    }
  }

  onMouseDown = (event: MouseEvent) => {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
  };

  onMouseMove = (event: MouseEvent) => {
    const offset = 35;
    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, 'resizing');

      // Calculate width of column
      const width = this.startWidth + (event.pageX - this.startX - offset);

      const tableCells = Array.from(
        this.table.querySelectorAll('.mat-row')
      ).map((row: any) => row.querySelectorAll('.mat-cell').item(this.index));

      // Set table header width
      this.renderer.setStyle(this.column, 'width', `${width}px`);

      // Set table cells width
      for (const cell of tableCells) {
        this.renderer.setStyle(cell, 'width', `${width}px`);
      }
    }
  };

  onMouseUp = (event: MouseEvent) => {
    const offset = 35;
    if (this.pressed) {
      const matColumnName = this.getColumnName();
      const width = this.startWidth + (event.pageX - this.startX - offset);
      const tableId = this.table.id;

      if (tableId) {
        this.store.dispatch(
          updateColumnSettings.init({
            sortAsc: false,
            width: `${width}px`,
            index: this.index,
            tableName: tableId,
            columnName: matColumnName,
          })
        );
      }
      this.pressed = false;
      this.renderer.removeClass(this.table, 'resizing');
    }
  };
  getColumnName() {
    const columnClassList = Array.from(this.column.classList);
    const matColumn = columnClassList.find(
      (item) => item.indexOf('mat-column-') > -1
    );
    const matColumnName = matColumn.replace('mat-column-', '');
    return matColumnName;
  }
}
