<div class="dialog-main">
    <div class="dialog-body">
        <div class="assess-wrapper" *ngIf="isLoading">
            <div class="assess-label">Assessment</div>
            <div class="assess-dropdown">
                <p-dropdown appendTo="body" name="assessmentType" id="assessmentType" [options]="assessmentTypes"
                    [(ngModel)]="selectedAssessment" placeholder="Select" (onChange)="onSelectAsssessment()"
                    [style]="{'width': '100%','textAlign':'start'}" optionLabel="label" autoWidth="false">
                </p-dropdown>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div>
            <securly-button color="secondary" (clickEvent)="close()">Cancel</securly-button>
        </div>
        <div>
            <securly-button [disabled]="!(selectedAssessment && !isInformantAssisstant)" (clickEvent)="save()"
                color="primary">
                Okay</securly-button>
        </div>
    </div>
</div>