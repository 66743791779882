import { Directive, ElementRef, Input, OnInit, AfterViewInit, Renderer2, HostListener } from '@angular/core';
import { SpeechService } from './speech.service';

@Directive({
    selector: '[toggleAudioButton]',
})
export class AudioDirective implements OnInit, AfterViewInit {

    @Input() audioText: string;
    @Input() isAudioOnText: boolean;
    @Input() leftSpace: number;
    @Input() isLeft?: boolean = true;

    private li: any;
    button: HTMLElement;
    disabled = true;

    constructor(
        private _element: ElementRef,
        private renderer: Renderer2,
        private _speechService: SpeechService
    ) { }

    ngOnInit(): void {
        this.li = this._element.nativeElement;
    }
    ngAfterViewInit(): void {
        if (this.isAudioOnText) {
            // this.li.innerHTML = `<button class="btn btn-light speakerBtn" style="margin-top: -25px; right: 15%;">
            // <i class="fas fa-volume-up"></i></button>`
            //     + this.li.innerHTML;
            // ${(this.leftSpace * 2) + (this.leftSpace / 2)}%
            if (this.isLeft) {
                this.li.innerHTML = `<button class="btn btn-light speakerBtn" 
                style="margin-top: -33px; left: 85%;  position: absolute;">
                <i class="fas fa-volume-up"></i></button>` + this.li.innerHTML;

            } else {
                this.li.innerHTML = `<button class="btn btn-light speakerBtn" 
                style="margin-top: -22px;font-size:6px;top:0; position: absolute;">
                <i class="fas fa-volume-up"></i></button>` + this.li.innerHTML;
            }
        } else {
            this.li.innerHTML = `<button class="btn btn-light speakerBtn" style="position: absolute; top: -39px;right: 0">
            <i class="fas fa-volume-up"></i></button>` + this.li.innerHTML;
        }
        let part: HTMLElement = this.li.querySelector('.speakerBtn');
        part.addEventListener("click", (e) => this.onClickAudio(e));
        this.renderer.setStyle(part, 'visibility', 'hidden');
    }

    @HostListener('mouseover') onMouseOver() {
        this.show();
    }

    @HostListener('mouseout') onMouseOut() {
        this.hide();
    }

    show() {
        let part = this.li.querySelector('.speakerBtn');
        this.renderer.setStyle(part, 'visibility', 'visible');
    }

    hide() {
        let part = this.li.querySelector('.speakerBtn');
        this.renderer.setStyle(part, 'visibility', 'hidden');
    }

    onClickAudio(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        console.log('onClick');
        this._speechService.stopSpeech();
        this._speechService.synthesizeSpeech(this.audioText);
        let part = this.li.querySelector('.speakerBtn');
        this.renderer.setAttribute(part, 'disabled', 'disabled');
        setTimeout(() => this.renderer.removeAttribute(part, 'disabled', ''), 3000);
    }
}
