<h3 translate></h3>
<!-- <img src="assets/rhithm-logo.svg" class="img-fluid py-3" style="width:50%" /> -->
<h3 class="mb-3">
    <b>
        Forgot password?
    </b>
</h3>
<div class="form-style">
    <form novalidate>
        <div class="alert alert-danger" *ngIf="error$ | async" translate>
            Email address is not exist or incorrect.
        </div>
        <p>A password reset link will be sent to your email to reset your password. If you don't get an email within a
            few minutes,
            please re-try.</p>
        <div class="form-group">
            <label class="d-block">
                <input type="text" class="form-control" name="emailAddress" [(ngModel)]="model.emailAddress"
                    placeholder="Email Address" #email="ngModel"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required />
                <span hidden translate>Email Address</span>
                <div class="text-danger" *ngIf="email.errors?.['pattern']">Please enter a valid email address.</div>
            </label>
            <label class="d-block">
                <div *ngIf="isForgotPasswordPass">
                    <mat-select [disableOptionCentering]="true" name="tenantId" panelClass="myPanelClass1"
                        class="form-control tenant-mat-select" [(ngModel)]="selectedTenant"
                        [compareWith]="compareObjects">
                        <mat-select-trigger>
                            {{selectedTenant?.name}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let em of tenantList" [value]="em" class="mat-opt"
                            (click)="switchTenant(em)">{{em?.name}}</mat-option>
                    </mat-select>
                </div>
            </label>
        </div>
        <div class="fp-action">

            <button class="btn btn-secondary back-btn" name="backe" (click)="onBack()" type="button">
                <span translate style="color: #000000;"><i class="fa fa-arrow-left"></i>&nbsp;Back</span>
            </button>
            <button *ngIf="!isForgotPasswordPass" class="btn btn-primary " name="submit"
                [disabled]="!this.model.emailAddress || email.errors?.['pattern']" (click)="onNextSubmit()"
                type="button">
                <i class="fas fa-cog fa-spin" *ngIf="this.isLoading"></i>
                <span translate style="color: #fff;">Submit</span>
            </button>
            <button *ngIf="isForgotPasswordPass" class="btn btn-primary " name="submit"
                [disabled]="!this.model.tenantId" (click)="onNextSubmit()" type="button">
                <i class="fas fa-cog fa-spin" *ngIf="this.isLoading"></i>
                <span translate style="color: #fff;">Submit</span>
            </button>
        </div>
    </form>
</div>