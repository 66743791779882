import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as reducers from '../../reducers';
import { FeatureUnavailableModalComponent } from '@app/shared/components/feature-unavailable-modal/feature-unavailable-modal.component';
import { Store } from '@ngrx/store';
import { skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { icons } from '../../icons';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { Link } from '@securly-engineering/securly-styles';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuHidden = true;
  isActive = true;
  showMenu = '';
  showSubMenu = '';
  menuPosition: number;
  destroy$: Subject<boolean> = new Subject<boolean>();
  classSessionId = '';
  isToggleSidebar: boolean = true;
  sourceLinks: Link[] = [
    {
      url: '/app/dashboard/main',
      title: 'Dashboard',
      icon: 'icon-dashboard',
      isActive: true,
      clickEvent: () => {
      }
    },
    {
      url: '/app/risk-Alerts',
      title: 'Risk Alerts',
      icon: 'user-icon',
      isActive: false,
      clickEvent: () => {
      }
    },
    // {
    //   url: '/app/assessments',
    //   title: 'Assessments',
    //   icon: 'icon-file',
    //   isActive: true,
    //   clickEvent: () => {
    //   }
    // },
    // {
    //   url: '/app/schedule',
    //   title: 'Schedule',
    //   icon: 'icon-calendar',
    //   isActive: true,
    //   clickEvent: () => {
    //   }
    // },
    {
      url: '/app/wellnesstips',
      title: 'Wellness Tips',
      icon: 'icon-hearts-suit',
      isActive: true,
      clickEvent: () => {
      }
    },
    // {
    //   url: '/app/settings/main',
    //   title: 'Settings',
    //   icon: 'icon-settings',
    //   isActive: true,
    //   clickEvent: () => {
    //   }
    // }
  ]
  links: Link[] = [];
  constructor(
    private store: Store<reducers.State>,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _appSessionService: AppSessionService,
  ) {
    const isStudent = this._appSessionService.isGranted('Pages.Rhithm.Student') == 'true' ? true : false;
    // if (isStudent) {
    //   this.links = this.sourceLinks.filter(x => x.title != 'Dashboard');
    // } else {
    this.links = this.sourceLinks;
    // }
  }



  home = {
    icon: 'securly-logo',
    action: () => { }
  };

  ngOnInit() {
    this.classSessionId = this.route.snapshot.params['id'];
  }

  navToHome() {
    this.router.navigate(['/']);
  }

  toggleMenu() {
    const toggler = document.querySelector('.navbar > .toggler');
    const navListContainer = document.querySelector('.navbar > .left-sidebar');
    toggler.classList.toggle('cross');
    navListContainer.classList.toggle('nav-active');
    // tslint:disable-next-line: no-unused-expression
    true;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  eventCalled() {
    this.isActive = !this.isActive;
  }
  displayNotAvailable() {
    const dialogRef: MatDialogRef<FeatureUnavailableModalComponent> = this.dialog.open(
      FeatureUnavailableModalComponent,
      {
        width: '500px',
        height: '400px',
      }
    );
    dialogRef.afterClosed().subscribe(() => { });
  }
  displaySidebar(): boolean {
    return this.isToggleSidebar && !this.classSessionId;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
