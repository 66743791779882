import { getAllTeacherTips, getGradesList, getToolkitList, loadResponseGroups } from "./wellness-tips.actions";


export interface State {
    responseGroupList: any,
    toolKitList:any,
    gradesList:any,
    teacherTipsList:any

}

export const initialState: State = {
    responseGroupList: [],
    toolKitList:[],
    gradesList:[],
    teacherTipsList:[],
}


export function reducer(state = initialState, action: any) {
    let stateToReturn = state;

    switch (action.type) {
        case loadResponseGroups.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                responseGroupList: requestPayload,
            });
        }
        case loadResponseGroups.success.type: {
            return Object.assign({}, state, {
                responseGroupList: action,
            })
        }
        // ToolKit
        case getToolkitList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                toolKitList: requestPayload,
            });
        }
        case getToolkitList.success.type: {
            return Object.assign({}, state, {
                toolKitList: action,    
                
            })
        }
        // Grades List
        case getGradesList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                gradesList: requestPayload,
            });
        }
        case getGradesList.success.type: {
            return Object.assign({}, state, {
                gradesList: action,    
                
            })
        }
        // Teacher Tips
        case getAllTeacherTips.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                teacherTipsList: requestPayload,
            });
        }
        case getAllTeacherTips.success.type: {
            return Object.assign({}, state, {
                teacherTipsList: action,    
                
            })
        }

        default:
            stateToReturn
            break;
    }

    return stateToReturn;
}


export const responseGroups = (state: State) => state?.responseGroupList;
export const toolKit = (state: State) => state?.toolKitList;
export const grades = (state: State) => state?.gradesList;
export const teacherTips = (state: State) => state?.teacherTipsList;