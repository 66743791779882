import { Injectable } from "@angular/core";
import Pusher, { Channel } from "pusher-js";
import { AppConsts } from "./../../../../shared/AppConsts";
import { RealTimeEventEnum, RealTimePublicEventEnum } from "./../../../../shared/AppEnums";
import {
    PusherCachingServiceProxy,
    PusherCachedPayloadDto,
} from "./../../../../shared/service-proxies/service-proxies";
import { AppPusherMessage, PusherMessage } from "./pusher-message.model";
import { Observable } from "rxjs";
import { FeatureFlagEnum } from "./../../../../shared/FeatureFlags";
import { AppSessionService } from "../session/app-session.service";
import { AuthenticationService } from "@app/auth/authentication.service";
import { DataService } from "@app/shared/data.service";

@Injectable()
export class RealTimeUpdatePusherService {
    private pusherClient: Pusher;
    private channel: Channel;
    private appChannel: Channel;
    Feature_PL264: any;

    constructor(
        private pusherCachingService: PusherCachingServiceProxy,
        private _authService: AuthenticationService,
        private _appSession: AppSessionService,
        private _data: DataService,
    ) {


        console.log("Pusher service initiated");
        this.pusherClient = new Pusher(
            AppConsts.pusher.app_key, {
            cluster: AppConsts.pusher.cluster,
        });

        let userId = 0;
        if (this._appSession.userId) {
            userId = this._appSession.userId;
        }
        this.channel = this.pusherClient.subscribe("user-" + userId);
        this.appChannel = this.pusherClient.subscribe("rhithm-app-event");
        this.Feature_PL264 = AppConsts.isFeatureEnabled(FeatureFlagEnum.Feature_PL264);
    }

    registerEvents(): void {

        this.channel.bind(
            RealTimeEventEnum.StartActivitySingleParticipant,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'start-activity-single-participant' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.StartActivitySingleParticipant
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.SendHeaderNotification,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-header-notification' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.SendHeaderNotification
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.SendSessionStartedNotification,
            (message: any) => {
                debugger;
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-session-started-notitification' event received",
                    _msg
                );

                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.SendSessionStartedNotification
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.StartActivityMultipleParticipants,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'start-activity-multiple-participants' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.StartActivityMultipleParticipants
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.SendSessionClosedNotification,
            (message: any) => {
                debugger;
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-session-closed-notification' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.SendSessionClosedNotification
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateStudentDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-student-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateStudentDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateStudentLiveSessionDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-student-live-session-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateStudentLiveSessionDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateTeacherDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-teacher-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateTeacherDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateStudentAllTeachersDashboards,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-student-all-teachers-dashboards' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateTeacherDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateTeacherLiveSessionDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-teacher-live-session-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateTeacherLiveSessionDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateTeacherAllPrincipalsDashboards,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-teacher-all-principals-dashboards' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateTeacherAllPrincipalsDashboards
                );
            }
        );
        this.channel.bind(
            RealTimeEventEnum.SendLiveSessionNotification,
            (message: any) => {
                debugger;
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-live-session-notification' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.SendLiveSessionNotification
                );
            }
        );
        this.channel.bind(
            RealTimeEventEnum.UpdatePrincipalDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-principal-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateTeacherAllPrincipalsDashboards
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdatePrincipalLiveSessionDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-principal-live-session-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdatePrincipalLiveSessionDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateDistrictDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-district-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateDistrictDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateDistrictLiveSessionDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-district-live-session-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateDistrictLiveSessionDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.UpdateDistrictLiveSessionDashboard,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'update-district-live-session-dashboard' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.UpdateDistrictLiveSessionDashboard
                );
            }
        );

        this.channel.bind(
            RealTimeEventEnum.SendRiskAlertNotification,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-risk-alert-notification' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.SendRiskAlertNotification
                );

            }
        );
        this.channel.bind(
            RealTimeEventEnum.SendAssessmentCommentNotification,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-assessment-comment-notification' event received",
                    _msg
                );
                this.processPusherMessage(
                    _msg,
                    RealTimeEventEnum.SendAssessmentCommentNotification
                );
                this.processPusherMessage(_msg,
                    "risk-alert-triggered");
            }
        );


        this.channel.bind(
            RealTimeEventEnum.SendAddedAsRiskAlertWatcherNotification,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-added-as-risk-alert-watcher-notification' event received",
                    _msg
                );

                this.processPusherMessage(_msg,
                    RealTimeEventEnum.SendAddedAsRiskAlertWatcherNotification);
            }
        );

        this.channel.bind(
            RealTimeEventEnum.SendGoogleDataSyncStartNotification,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-google-data-sync-start-notification' event received",
                    _msg
                );

                this.processPusherMessage(_msg,
                    RealTimeEventEnum.SendGoogleDataSyncStartNotification);
            }
        );


        this.appChannel.bind(
            RealTimePublicEventEnum.TriggerHardRefreshForUsers,
            (message: any) => {
                var _msg: AppPusherMessage = JSON.parse(message);

                var json = JSON.parse(_msg.payLoadJson);

                console.log(
                    "Pusher 'send-hard-refresh-notification' current version",
                    AppConsts.appVersion,
                    " latest version ",
                    json.appVersion
                );


                if (AppConsts.appVersion != json.appVersion) {
                    //front end team to open modal with timer
                    console.log("hard reloading");
                    // location.reload(true);
                    // this.tabsFilter.onModalOpen(true)
                }

            }
        );
        this.appChannel.bind(
            RealTimePublicEventEnum.TriggerClearCacheForUsers,
            (message: any) => {

                var _msg: AppPusherMessage = JSON.parse(message);

                var json = JSON.parse(_msg.payLoadJson);

                console.log("Pusher json", json);

                console.log(
                    "Pusher 'send-hard-refresh-notification' current version",
                    AppConsts.appVersion,
                    " latest version ",
                    json.inActiveTenantId
                );

                //For inactive or delete tenant
                if (json.inActiveTenantId != undefined && this._appSession.tenantId == json.inActiveTenantId && this.Feature_PL264) {
                    console.log("Logout User Inactive tenant1", this._appSession.tenantId);
                    console.log("Logout User Inactive tenant2", this._appSession.userId);
                    console.log("Logout User Inactive tenant3", json);
                    console.log("Logout User Inactive tenant4", json.inActiveTenantId);
                    // this.tabsFilter.onModalOpenClearSiteData(true)
                    localStorage.clear();
                    this._authService.logout();
                }
                else if (this._appSession.userId == json.inActiveUserId && this.Feature_PL264) {
                    console.log("Logout User Inactive User1", this._appSession.tenantId);
                    console.log("Logout User Inactive User2", this._appSession.userId);
                    console.log("Logout User Inactive User3", json);
                    console.log("Logout User Inactive User4 tenant", json.inActiveUserId);
                    // this.tabsFilter.onModalOpenClearSiteData(true)
                    localStorage.clear();
                    this._authService.logout();
                }
                // For clear cache for all online users
                else if ((AppConsts.appVersion != json.inActiveTenantId || AppConsts.appVersion != json.inActiveUserId) && !this.Feature_PL264) {

                    //front end team to open modal with timer
                    console.log("hard reloading");
                    // location.reload(true);
                    // // this.tabsFilter.onModalOpenClearSiteData(true)
                }

            }
        );


        this.channel.bind(
            RealTimeEventEnum.SendGoogleDataSyncedNotification,
            (message: any) => {
                var _msg: PusherMessage = JSON.parse(message);
                console.log(
                    "Pusher 'send-google-data-synced-notification' event received",
                    _msg
                );

                this.processPusherMessage(_msg,
                    RealTimeEventEnum.SendGoogleDataSyncedNotification);
            }
        );

    }

    private processPusherMessage(
        message: PusherMessage,
        eventName: string
    ): void {
        console.log("Processing pusher message", message);
        if (message.recipientIds.indexOf(this._appSession.userId) > -1) {
            if (message.heavyPayLoad == true) {
                console.log("Found heavy payload", message.heavyPayLoad);
                this.getPayLoadFromRedisCache(message.payloadId).subscribe(
                    (result: PusherCachedPayloadDto) => {
                        console.log(
                            "Payload from redis for payload id: " +
                            message.payloadId,
                            result
                        );
                        if (result.payload) {
                            let payload = result.payload;
                            console.log("payload", payload);
                            let response = JSON.parse(payload);
                            console.log("response", response);
                            console.log("eventName", eventName);
                            if (response) {
                                // abp.event.trigger(eventName, response);
                            }
                        }
                    },
                    (err) => {
                        console.error(
                            "Unable to get payload from redis for payload id: " +
                            message.payloadId,
                            err
                        );
                    }
                );
            } else {
                let payload = JSON.parse(message.payLoadJson);
                console.log("Found light payload", payload);
                if (payload) {
                    if (
                        eventName ===
                        RealTimeEventEnum.StartActivitySingleParticipant ||
                        eventName ===
                        RealTimeEventEnum.StartActivityMultipleParticipants
                    ) {
                        // this.tabsFilter.onStartActivity(payload);
                    } else if (
                        eventName ===
                        RealTimeEventEnum.SendSessionClosedNotification
                    ) {
                        this._data.onUserSessionChanged(true);
                        // this.tabsFilter.onSessionClosed(payload);
                        //refresh live sessions
                        // this.tabsFilter.onMyActiveSession(true);
                    } else if (
                        eventName ===
                        RealTimeEventEnum.SendSessionStartedNotification
                    ) {
                        this._data.onUserSessionChanged(true);
                    } else if (
                        eventName === RealTimeEventEnum.SendHeaderNotification
                    ) {
                        // this.tabsFilter.onLoadEventNotification(payload);
                    }
                    else if (
                        eventName === RealTimeEventEnum.SendRiskAlertNotification) {
                        // this.tabsFilter.onLoadRiskAlertNotification(payload);
                        // Trigger risk alert notification event here
                    }
                    else if (
                        eventName === RealTimeEventEnum.SendAssessmentCommentNotification) {
                        // this.tabsFilter.onLoadCommentNotification(payload);
                        // Trigger Comment Notification notification event here
                    }
                    else if (
                        eventName === RealTimeEventEnum.SendLiveSessionNotification
                    ) {
                        this._data.onUserSessionChanged(true);
                        //load live active sessions
                        // this.tabsFilter.onMyActiveSession(true);
                    }
                    else if (
                        eventName === RealTimeEventEnum.SendAddedAsRiskAlertWatcherNotification) {
                        // this.tabsFilter.onLoadEventNotification(payload);
                        // Trigger risk alert notification event here
                    } else if (
                        eventName === RealTimeEventEnum.SendGoogleDataSyncStartNotification) {
                        // this.tabsFilter.onGoogleDataSyncStart(payload);
                        // Trigger google data sync start event here
                    } else if (
                        eventName === RealTimeEventEnum.SendGoogleDataSyncedNotification) {
                        // this.tabsFilter.onGoogleDataSynced(payload);
                        // Trigger google data synced event here
                    } else {
                        console.log("Triggering internal event", eventName);
                        // abp.event.trigger(eventName, payload);
                    }
                }
            }
        }
    }

    private getPayLoadFromRedisCache(payloadId: string): Observable<any> {
        return this.pusherCachingService.getPayLoad(payloadId);
    }
}
