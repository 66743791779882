<div *ngIf="qrhideandshow == true" class="scanner-main">
    <div class="scanner-wrapper">
        <div class="laser"></div>
        <span class="scanner-text">Align the QR code within the frame to scan</span>
        <video #video id="video" autoplay></video>
    </div>
    <div class="back-btn-wrapper">
        <button type="button" (click)="onBack()" class="btn btn-light btn-elevate kt-login__btn-secondary"><i
                class="fa fa-arrow-left"></i>Back</button>
    </div>
</div>