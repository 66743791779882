import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { SelectItem, TreeNode } from 'primeng/api';
import { AppConsts } from 'src/shared/AppConsts';
import { AssessmentTypeServiceProxy, CreateOrEditSessionDto, SessionsServiceProxy, ValidatedAssessmentTypeInputDto } from 'src/shared/service-proxies/service-proxies';

@Component({
  selector: 'app-assessment-modal',
  templateUrl: './assessment-modal.component.html',
  styleUrls: ['./assessment-modal.component.scss']
})
export class AssessmentModalComponent implements OnInit {
  saving: boolean = false;
  ValidatedAssessmentTypeInputDto = new ValidatedAssessmentTypeInputDto();
  selectedTeacherClass: any;
  allParticipants: TreeNode[] = [];
  selectedParticipants: TreeNode[] = [];
  assessmentTypes: any[] = [];
  selectedAssessment: SelectItem;
  selectedRoles: any[] = [];
  isLoading = false;
  roles: any[] = []
  type = '';
  getAssessmentTypes: any = [];
  isInformantAssisstant: boolean = false;
  isTeacherSelected: boolean = false;
  isInformantExistForDistrict: boolean = false;
  isInformantExistForPrincipal: boolean = false;
  selectedOrganizationUnitAndUsersList: any[];
  sessionType: string;
  assessmentTypeId: any;
  constructor(
    private _appSession: AppSessionService,
    private _assessmentType: AssessmentTypeServiceProxy,
    private dialogRef: MatDialogRef<AssessmentModalComponent>,
    private _sessionsServiceProxyMountain: SessionsServiceProxy,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {

    // if (selectedTeacherId) {
    //   this.isTeacherSelected = true
    // }
    // this.checkTypesForInformant(checkType)
    this.getAssessmentTypes = data.assessmentTypes
    // this.selectedRoles=selectedRoles;
    if (this.type != AppConsts.Schedule) {
      this.roles = data.selectedRoles;
      let roles = [];
      roles = data.selectedRoles.filter((x: any) => x.children && x.children.length > 0);
      // this.selectedRoles

      roles.map((x: any) => {
        x.children.forEach((y: any) => {
          this.selectedRoles.push(y.data)
        });
      })
      if (roles.length == 0) {
        this.selectedRoles = data.selectedRoles;
      }
    }
    data.assessmentTypes.forEach((t: any) => this.assessmentTypes.push({ label: t.name, value: t.id, isDefault: t.isDefault, isAllowMultipleAssessment: t.isAllowMultipleAssessment }));
    this.assessmentTypes = this.assessmentTypes.filter(function (value, index, arr) {
      return value.isAllowMultipleAssessment == true;
    });

    if (this._appSession.user.districtId == AppConsts.restrictedBasicAssessmentDistrictId ||
      this._appSession.user.districtId == AppConsts.restrictedBasicAssessmentDistrictIdWalkerCounty ||
      this._appSession.user.districtId == AppConsts.restrictedBasicAssessmentDistrictIdDCMO) {

      // let id = this.assessmentTypes[0].value;

      var lists = this.assessmentTypes.filter(x => {
        return x.value != 1
      });

      this.assessmentTypes = lists

    }



    let selectedAssessment = this.assessmentTypes.filter(x => x.isDefault == true);
    if (selectedAssessment.length > 0) {
      this.selectedAssessment = selectedAssessment[0];
    }
    // if (this.isGranted("Pages.Rhithm.DistrictOwner")) {
    //   this.selectedRoles = selectedRoles;
    // }
    this.isLoading = true;

    this.selectedTeacherClass = null;

  }

  ngOnInit(): void {
    this.isInformantExistForDistrict = false
    this.isInformantExistForPrincipal = false
    this.selectedTeacherClass = null;
  }
  onSelectAsssessment() {
    if (this._appSession.isGranted('Pages.Rhithm.Teacher')) {
      this.isInformantAssisstant = this.getAssessmentTypes.filter((x: any) => x.id == this.selectedAssessment.value)[0].informantAssessment;
      if (this.isInformantAssisstant) {
        let message = " This is informant Assessment you can not create session"
      }
    }
    if ((this.isTeacherSelected) && this._appSession.isGranted('Pages.Rhithm.Principal')) {
      this.isInformantAssisstant = this.getAssessmentTypes.filter((x: any) => x.id == this.selectedAssessment.value)[0].informantAssessment;
      if (this.isInformantAssisstant) {
        let message = " This is informant Assessment you can not create session"

      }
    }

    // if ( this.isInformantExistForDistrict && this.isGranted('Pages.Rhithm.DistrictOwner')) {
    //   this.isInformantAssisstant = this.getAssessmentTypes.filter(x => x.id == this.selectedAssessment.value)[0].informantAssessment;
    //   if (this.isInformantAssisstant) {
    //     let message = " This is informant Assessment you can not create session."
    //     this.customMessage.open(AppConsts.warn, message, null)
    //   }

    // }
  }
  save() {

    if (this._appSession.isGranted('Pages.Rhithm.DistrictOwner')) {
      ;

      this.ValidatedAssessmentTypeInputDto = new ValidatedAssessmentTypeInputDto();
      this.ValidatedAssessmentTypeInputDto.assessmentTypeId = this.selectedAssessment &&
        this.selectedAssessment['value'] ? this.selectedAssessment['value'] : this.selectedAssessment;
      if (this.type != AppConsts.Schedule) {
        let selectedRoles: any = [];
        this.selectedRoles && this.selectedRoles.map(x => {
          if (x && x['data']) {
            selectedRoles.push(x['data'])
          }
          else {
            selectedRoles.push(x)
          }
        })
        this.ValidatedAssessmentTypeInputDto.userIds = selectedRoles;

        this._assessmentType.isValidatedAssessmentTypeForUsers(this.ValidatedAssessmentTypeInputDto).subscribe(x => {


          if (x && x.isValidated) {
            // this.startClassSession.emit([this.ValidatedAssessmentTypeInputDto.assessmentTypeId, this.roles]);
          }
          else {
            // this.customMessage.open(AppConsts.warn, 'This assessment is designed for particular grade levels or roles, and these participants have a grade level or role that doesn’t correspond to this assessment. Would you like to continue?', null, null, null, x)
          }
        })
      }
      if (this.type == AppConsts.Schedule) {
        // this.startClassSession.emit([this.ValidatedAssessmentTypeInputDto.assessmentTypeId, this.selectedAssessment &&
        // this.selectedAssessment['label'] ? this.selectedAssessment['label'] : '']);
        // this.close();
      }
    }
    else {


      this.ValidatedAssessmentTypeInputDto = new ValidatedAssessmentTypeInputDto();
      this.ValidatedAssessmentTypeInputDto.assessmentTypeId = this.selectedAssessment &&
        this.selectedAssessment['value'] ? this.selectedAssessment['value'] : this.selectedAssessment;
      if (this.type != AppConsts.Schedule) {
        let selectedRoles: any = [];
        this.selectedRoles && this.selectedRoles.map(x => {
          if (x && x['data']) {
            selectedRoles.push(x['data'])
          }
          else {
            selectedRoles.push(x)
          }
        })
        this.ValidatedAssessmentTypeInputDto.userIds = selectedRoles;

        this._assessmentType.isValidatedAssessmentTypeForUsers(this.ValidatedAssessmentTypeInputDto).subscribe(x => {


          if (x && x.isValidated) {
            // this.startClassSession.emit([this.ValidatedAssessmentTypeInputDto.assessmentTypeId, this.roles]);
            this.dialogRef.close({
              assessmentTypeId: this.ValidatedAssessmentTypeInputDto.assessmentTypeId,
              roles: this.roles
            })
          }
          else {
            // this.customMessage.open(AppConsts.warn, 'This assessment is designed for particular grade levels or roles, and these participants have a grade level or role that doesn’t correspond to this assessment. Would you like to continue?', null, null, null, x)
          }
        })
      }
      if (this.type == AppConsts.Schedule) {
        // this.startClassSession.emit([this.ValidatedAssessmentTypeInputDto.assessmentTypeId, this.selectedAssessment &&
        //   this.selectedAssessment['label'] ? this.selectedAssessment['label'] : '']);
        // this.close();
      }
    }
  }
  close() {
    this.dialogRef.close()
  }
  createMultipleGroupOrClassSession(event: any) {
    this.selectedOrganizationUnitAndUsersList = [];
    this.selectedTeacherClass = event;
    this.sessionType = AppConsts.sessionTypeDescendant;
    for (var x = 0; x < event.length; x++) {
      let createOrEditSession: CreateOrEditSessionDto = new CreateOrEditSessionDto();
      var userIds = [];
      if (event[x].parent) {
        if (this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == event[x].parent.key).length == 0) {
          createOrEditSession.classId = Number(event[x].parent.key);
          createOrEditSession.name = event[x].parent.label;
          createOrEditSession.rCreatorUserId = this._appSession.userId;
          createOrEditSession.isClosed = AppConsts.sessionStatusStarted;
          createOrEditSession.type = event[x].type;
          userIds.push(parseInt(event[x].data));
          createOrEditSession.userIds = userIds;
          createOrEditSession.assessmentTypeId = this.assessmentTypeId ? this.assessmentTypeId : AppConsts.DefaultAssessmentTypeId;
          this.selectedOrganizationUnitAndUsersList.push(createOrEditSession);
        }
        else {
          var parentKey = event[x].key.split('_')[0];
          var key = event[x].data;
          var isClassExist = this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == parentKey);
          if (isClassExist.length > 0) {
            if (isClassExist[0].userIds.filter((i: any) => i == key).length == 0) {
              userIds.push(parseInt(event[x].data));
              this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == parentKey)[0].userIds.push(key);
            }
          }
        }
      }
      else if (event[x].children) {
        if (this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == event[x].key).length == 0) {
          createOrEditSession.classId = Number(event[x].key);
          createOrEditSession.name = event[x].label;
          createOrEditSession.isClosed = AppConsts.sessionStatusStarted;
          createOrEditSession.type = event[x].children[0].type;
          createOrEditSession.rCreatorUserId = this._appSession.userId;
          createOrEditSession.assessmentTypeId = this.assessmentTypeId ? this.assessmentTypeId : AppConsts.DefaultAssessmentTypeId;
          var userIds = [];
          for (var z = 0; z < event[x].children.length; z++) {
            var userId = parseInt(event[x].children[z].data);
            userIds.push(userId);
          }
          createOrEditSession.userIds = userIds;
          this.selectedOrganizationUnitAndUsersList.push(createOrEditSession);
        }
      }
    }
    this._sessionsServiceProxyMountain.createMultipleClassSessions(this.selectedOrganizationUnitAndUsersList)
      .subscribe((result: string[]) => {
        if (result.length > 0) {
          // this.alreadySessionStartedClasses = [];
          // this.alreadySessionStartedClasses = result;
          // this.startedClassesWarningPopup = true;
        }
        else {
          //this.getRhithmForMyClassModal.close();
          // this.getMyActiveSession(false);
          // this.showUnattendedSessionsListModal();
        }
      });
  }

}
