import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getAllTeacherTips, getGradesList, getToolkitList, loadResponseGroups } from "./wellness-tips.actions";
import { catchError, exhaustMap, map, mergeMap } from "rxjs";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { TeacherServiceProxy as MountainTeacherServiceProxy } from "src/shared/service-proxies/service-proxies";
import { WellnessTipsServiceProxy } from "src/shared/service-proxies/service-proxies-v2";


@Injectable()
export class WellnessTipsEffects {

    constructor(
        private actions$: Actions,
        private _teacherMountainService: MountainTeacherServiceProxy,
        private _teacherToolkitService: WellnessTipsServiceProxy,
        private _gradesListService: WellnessTipsServiceProxy,
        private _teacherService: WellnessTipsServiceProxy,



    ) { }

    loadResponseGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadResponseGroups.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._teacherMountainService.getPDGroupForDropdown().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadResponseGroups.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get risk alert list.',
                            errorMapping: {},
                            failureAction: loadResponseGroups.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load risk alert.',
                        })
                    })
                )
            })
        )
    })


    getToolkitList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(getToolkitList.init),
            map((action: any) => action),
            exhaustMap((payload) => {

                return this._teacherToolkitService
                    .getAll(payload.payload).pipe(
                        map((res: any) => {
                            if (res) {
                                return getToolkitList.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get risk alert list.',
                                errorMapping: {},
                                failureAction: getToolkitList.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load tol kit  list.',
                            })
                        })
                    )
            })
        )
    })

    getGradesList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getGradesList.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._gradesListService
                    .getGradeLevelsForDropDown().pipe(
                        map((res: any) => {
                            if (res) {
                                return getGradesList.success({ items: res });
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get grade list.',
                                errorMapping: {},
                                failureAction: getGradesList.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load grade  list.',
                            })
                        })
                    )
            })
        )
    })


    getAllTeacherTips$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(getAllTeacherTips.init),
            map((action: any) => action),
            exhaustMap((payload) => {
                return this._teacherService
                    .getAllTeacherTips(payload.payload).pipe(
                        map((res: any) => {
                            if (res) {
                                return getAllTeacherTips.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get teacher tips list.',
                                errorMapping: {},
                                failureAction: getAllTeacherTips.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load teacher tips  list.',
                            })
                        })
                    )
            })
        )
    })

}