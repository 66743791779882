import { exportDashboardSessionTable, loadAssessmentQuestionTypesWithOptions, loadClassesByStudentId, loadDashboardSessionTable, loadDashboardSessionTableV2, loadDistrictAssessmentType, loadDistrictSearchType, loadPrincipalSearchType, loadTeacherSearchType, loadTenantAssessmentType, loadViewType, updateDashboardSortData } from "./dashboard.action";



export interface State {
    dashboardSortData: { direction: boolean; columns: string };
    districtAssessmetType: any;
    tanentAssessmetType: any;
    viewType: any;
    districtSearchType: any;
    teacherSearchType: any;
    principalSearchType: any;
    dashBoardSessionTable: any;
    dashBoardSessionTablePayload: any
    dashBoardSessionTableV2: any;
    dashBoardSessionTableV2Payload: any
    assessmentQuestionTypesWithOptions: any;
    assessmentQuestionTypesWithOptionsPayload: any;
    exportDashboardSessionTableFile: any,
    exportDashboardSessionTableFilePayload: any,
    studentClasses: any,
}
export const initialState: State = {
    dashboardSortData: { direction: true, columns: 'studentName' },
    districtAssessmetType: null,
    tanentAssessmetType: null,
    viewType: [],
    districtSearchType: null,
    teacherSearchType: null,
    principalSearchType: null,
    dashBoardSessionTable: [],
    dashBoardSessionTablePayload: null,
    dashBoardSessionTableV2: [],
    dashBoardSessionTableV2Payload: null,
    assessmentQuestionTypesWithOptions: [],
    assessmentQuestionTypesWithOptionsPayload: null,
    exportDashboardSessionTableFile: [],
    exportDashboardSessionTableFilePayload: null,
    studentClasses: []
};
export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default
    switch (action.type) {
        case updateDashboardSortData.type: {
            return Object.assign({}, state, {
                dashboardSortData: {
                    direction: action.direction,
                    columns: action.columns,
                },
            });
        }
        case loadDistrictAssessmentType.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                districtAssessmetType: requestPayload,
            });
        }
        case loadDistrictAssessmentType.success.type: {
            return Object.assign({}, state, {
                districtAssessmetType: action,
            });
        }
        case loadTenantAssessmentType.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                tanentAssessmetType: requestPayload,
            });
        }
        case loadTenantAssessmentType.success.type: {
            return Object.assign({}, state, {
                tanentAssessmetType: action,
            });
        }
        case loadDistrictSearchType.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                districtSearchType: requestPayload,
            });
        }
        case loadDistrictSearchType.success.type: {
            return Object.assign({}, state, {
                districtSearchType: action,
            });
        }
        case loadTeacherSearchType.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                teacherSearchType: requestPayload,
            });
        }
        case loadTeacherSearchType.success.type: {
            return Object.assign({}, state, {
                teacherSearchType: action,
            });
        }
        case loadPrincipalSearchType.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                principalSearchType: requestPayload,
            });
        }
        case loadPrincipalSearchType.success.type: {
            return Object.assign({}, state, {
                principalSearchType: action,
            });
        }

        case loadViewType.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                viewType: requestPayload,
            });
        }
        case loadViewType.success.type: {
            return Object.assign({}, state, {
                viewType: action,
            });
        }
        // Dashboard Session Table
        case loadDashboardSessionTable.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                dashBoardSessionTablePayload: requestPayload,
            });
        }
        case loadDashboardSessionTable.success.type: {
            return Object.assign({}, state, {
                dashBoardSessionTable: action,
            });
        }
        // Dashboard Session Table
        case loadDashboardSessionTableV2.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                dashBoardSessionTableV2Payload: requestPayload,
            });
        }
        case loadDashboardSessionTableV2.success.type: {
            return Object.assign({}, state, {
                dashBoardSessionTableV2: action,
            });
        }
        //  Dashboard Table AssessmentQuestionTypesWithOptions
        case loadAssessmentQuestionTypesWithOptions.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                assessmentQuestionTypesWithOptionsPayload: requestPayload,
            });
        }
        case loadAssessmentQuestionTypesWithOptions.success.type: {
            return Object.assign({}, state, {
                assessmentQuestionTypesWithOptions: action,
            });
        }

        //  Dashboard Table File
        case exportDashboardSessionTable.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                exportDashboardSessionTableFilePayload: requestPayload,
            });
        }
        case exportDashboardSessionTable.success.type: {
            return Object.assign({}, state, {
                exportDashboardSessionTableFile: action,
            });
        }
        // load classes by student id
        case loadClassesByStudentId.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                studentClasses: requestPayload,
            });
        }
        case loadClassesByStudentId.success.type: {
            return Object.assign({}, state, {
                studentClasses: action,
            });
        }
        // load classes by student id end

        default:
            stateToReturn = state;
            break;
    }
    return stateToReturn;
}
export const getUpdatedDashboardSortData = (state: State) => state.dashboardSortData;
export const getDistrictAssessmetType = (state: State) => state.districtAssessmetType;
export const getTanentAssessmetType = (state: State) => state.tanentAssessmetType;
export const getViewType = (state: State) => state.viewType;
export const getDistrictSearchType = (state: State) => state.districtSearchType;
export const getTeacherSearchType = (state: State) => state.teacherSearchType;
export const getPrincipalSearchType = (state: State) => state.principalSearchType;
export const getDashboardSessionTable = (state: State) => state.dashBoardSessionTable;
export const getDashboardSessionTableV2 = (state: State) => state.dashBoardSessionTableV2;
export const getAssessmentQuestionTypesWithOptions = (state: State) => state.assessmentQuestionTypesWithOptions;
export const getExportDashboardSessionTableFile = (state: State) => state.exportDashboardSessionTableFile;
export const getClassByStudentId = (state: State) => state.studentClasses;
