import { OAuthModule } from 'angular-oauth2-oidc';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { TokenAuthServiceProxy, UserServiceProxy, TenantServiceProxy, AccountAppServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { SharedModule } from '@app/shared';
import { UserSelectRoleComponent } from './user-select-role/user-select-role.component';
import { AccountComponent } from './account/account.component';
import { LoginEmailComponent } from './login-email/login-email.component';
import { AccountRouteGuard } from './account-route-guard';
import { UserNotExistComponent } from './user-not-exist/user-not-exist.component';
import { SsoQrLoginComponent } from './sso-qr-login/sso-qr-login.component';
import { QrLoginComponent } from './qr-login/qr-login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    AuthRoutingModule,
    SharedModule,
    OAuthModule.forRoot(),

  ],
  providers: [
    UserServiceProxy,
    TokenAuthServiceProxy,
    TenantServiceProxy,
    AccountRouteGuard,
    AccountAppServiceProxy
  ],
  declarations: [LoginComponent, UserSelectRoleComponent, AccountComponent, LoginEmailComponent, UserNotExistComponent, SsoQrLoginComponent, QrLoginComponent, ForgotPasswordComponent, ResetPasswordComponent],
})
export class AuthModule { }
