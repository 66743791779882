import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";

export const loadAllStartedUserSessions = createAsyncAction(
    '[All Started User Sessions] Get list of All Started User Sessions',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAllUserNotes = createAsyncAction(
    '[load All User Notes] Get list of All User Notes',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadQuestionAllAndSelectedOption = createAsyncAction(
    '[load All User Notes] Get All Question and Selected Option',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
