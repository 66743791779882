import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import * as reducers from '../../reducers';

@Pipe({
  name: 'languagePipe'
})
export class LanguagePipe implements PipeTransform {
  constructor(
    private _store: Store<reducers.State>,
  ) { }
  transform(value: any, args?: any): any {
    let transformedValue = '';
    this._store.select(reducers.getAllUserConfiguration).subscribe((res: any) => {
      console.log(res?.localization?.values?.Rhithm[value]);
      if (res?.localization?.values?.Rhithm[value]) {
        transformedValue = res?.localization?.values?.Rhithm[value];

      } else {
        transformedValue = value
      }
    });
    return transformedValue
  }

}
