import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { AppConsts } from './../../shared/AppConsts';
import { RhithmFeaturesModel } from './../shared/interfaces/activity.interface';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Router } from '@angular/router';
import { DataService } from './data.service';

@Injectable()
export class RMSService {

    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(
        private http: HttpClient,
        private dataService: DataService,
        private _route: Router
    ) {
    }

    onLoginFlowRegulator(initialUrl: any) {
        let url_ = AppConsts.rmsUrls.metricReaderServiceUrl + AppConsts.rmsFunctionPath.metricReaderService.IsHealthy;
        return this.http.get(url_).subscribe(x => {

            initialUrl = initialUrl.replace(/\/$/, "")
            if (x && x['ready']) {
                location.href = initialUrl;
            }
            else {
                this.dataService.onRouteUrl('data');
                location.href = initialUrl + '/app/main/redirect';
            }
        });
    }

    onMaintenanceMode() {
        let url_ = AppConsts.rmsUrls.featuresServiceUrl + AppConsts.rmsFunctionPath.featuresService.GetRhithmFeatures;
        url_ = url_ + "?enviroment=" + AppConsts.appEnvironmentName;
        return this.http.get(url_).subscribe(x => {
            if (x['appSettings_IsMaintenanceMode']) {
                this.dataService.onMaintenance('data');
                this._route.navigateByUrl('maintenance');
            }
        })
    }

    onSyncMongoDb(body: any, ssoSettings_UseAWSRmsLoginService: boolean) {
        let url_ = this.getApiUrl(ssoSettings_UseAWSRmsLoginService) + AppConsts.rmsFunctionPath.loginService.SyncSqlMongo_SyncTenants;
        return this.http.post(url_, body)
    }

    onSyncAllMongoDb(body: any, ssoSettings_UseAWSRmsLoginService: boolean) {
        let url_ = this.getApiUrl(ssoSettings_UseAWSRmsLoginService) + AppConsts.rmsFunctionPath.loginService.SyncSqlMongo_SyncAll;
        return this.postRequest(url_, body);
    }

    onLoginExternalAuthenticate(body: any, ssoSettings_UseAWSRmsLoginService: boolean) {
        let url_ = this.getApiUrl(ssoSettings_UseAWSRmsLoginService) + AppConsts.rmsFunctionPath.loginService.Login_ExternalAuthenticate;
        return this.postRequest(url_, body);
    }


    onLoginAuthenticate(body: any, ssoSettings_UseAWSRmsLoginService: boolean) {
        let url_ = this.getApiUrl(ssoSettings_UseAWSRmsLoginService) + AppConsts.rmsFunctionPath.loginService.Login_Authenticate;
        return this.postRequest(url_, body);
    }

    private getApiUrl(ssoSettings_UseAWSRmsLoginService: boolean) {
        if (ssoSettings_UseAWSRmsLoginService == true) {
            return AppConsts.rmsUrls.loginServiceAwsUrl;
        } else {
            return AppConsts.rmsUrls.loginServiceUrl;
        }
    }

    getRhithmFeatures(): Observable<RhithmFeaturesModel> {
        let url_ = AppConsts.rmsUrls.featuresServiceUrl + AppConsts.rmsFunctionPath.featuresService.GetRhithmFeatures;
        url_ = url_ + "?enviroment=" + AppConsts.appEnvironmentName;
        return this.http.get<RhithmFeaturesModel>(url_);
    }



    postRequest(url_: any, body: any) {

        const content_ = JSON.stringify(body);
        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            withCredentials: true,
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "text/plain",
                "Cache-Control": "no-cache, no-store, must-revalidate, max-age=0"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response: any) => {
            const responseBlob = response instanceof HttpResponse ? response.body : (<any>response).error instanceof Blob ? (<any>response).error : undefined;
            return this.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                return _observableOf(resultData200);
            }));
        }));

    }




    blobToText(blob: any): Observable<string> {
        return new Observable<string>((observer: any) => {
            if (!blob) {
                observer.next("");
                observer.complete();
            } else {
                let reader = new FileReader();
                reader.onload = event => {
                    observer.next((<any>event.target).result);
                    observer.complete();
                };
                reader.readAsText(blob);
            }
        });
    }
}
