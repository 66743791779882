import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessSnackbarComponent } from './success-snackbar.component';
import { LoaderComponent } from './loader/loader.component';
import { FailureSnackbarComponent } from './failure-snackbar.component';
import { AngularMaterialModule } from './angular-material.module';
import { PaginationComponent } from './components/pagination.component';
import { LoadingIndicatorComponent } from './components/loading-indicator.component';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { VaMatTableComponent } from './components/va-mat-table.component';
import { ColumnSorterService } from './directives/column-sorter.service';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { SaveAndCancelBannerComponent } from './components/save-and-cancel-banner/save-and-cancel-banner.component';
import { SpyAnchorDirective } from './directives/spy-anchor.directive';
import { SpyAreaForDirective } from './directives/spy-area-for.directive';
import { SpyScrollContainerDirective } from './directives/spy-scroll-container.directive';
import { StickyDirective } from './directives/sticky.directive';
import { DefaultFaviconDirective } from './directives/default-favicon.directive';
import { ConvertCsvPipe } from './pipes/convert-csv.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ChatsComponent } from './components/chats/chats.component';
import { FeatureUnavailableModalComponent } from './components/feature-unavailable-modal/feature-unavailable-modal.component';
import { PasswordChangeModalComponent } from './components/password-change-modal/password-change-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConcurrentLoginModalComponent } from './components/concurrent-login-modal/concurrent-login-modal.component';
import { YesNoGeneralModalComponent } from './components/yes-no-general-modal/yes-no-general-modal.component';
import { DisableTooltipDirective } from './directives/disable-tooltip.directive';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDropdownComponent } from './custom-dropdown.component';
import { SorterSelectComponent } from './components/sorter-select/sorter-select.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SelectAllDirective } from './directives/select-all.directive';

import { RiskAlertsServiceProxy as MountainRiskAlertsServiceV2, PrincipalServiceProxy, WellnessTipsServiceProxy, DistrictsServiceProxy as DistrictsServiceProxyv2, TeacherServiceProxy as TeacherServiceProxyv2, RiskAlertsTemplateServiceProxy, DashboardSessionTableServiceProxy, AccountAppServiceProxy } from "src/shared/service-proxies/service-proxies-v2"
import { DataService } from './data.service';
import { AccountAppServiceProxy as AccountAppServiceProxyV2, DistrictsServiceProxy as DistrictsServiceProxyV2 } from 'src/shared/service-proxies/service-proxies-v2';
import { AssessmentTypeServiceProxy, DashboardSessionTableServiceProxy as DashboardSessionTableServiceProxyV1, DistrictsServiceProxy, OrganizationUnitServiceProxy, ProfileServiceProxy, QuestionServiceProxy, RhithmActivityServiceProxy, RiskAlertTemplateServiceProxy, RiskAlertsServiceProxy, SessionEventServiceProxy, SessionsServiceProxy, TeacherServiceProxy, UserAnswersServiceProxy, UserServiceProxy, UserSessionServiceProxy, VideoProviderServiceProxy } from 'src/shared/service-proxies/service-proxies';

import { TokenAuthServiceProxy } from '../../shared/service-proxies/service-proxies';
import { GetInRhithmModalComponent } from './modal/get-in-rhithm-modal/get-in-rhithm-modal.component';
import { AttemptSessionModalComponent } from './modal/attempt-session-modal/attempt-session-modal.component';
import { ClassWidgetModalComponent } from './modal/class-widget-modal/class-widget-modal.component';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { AssessmentModalComponent } from './modal/assessment-modal/assessment-modal.component';
import { DropdownModule } from 'primeng/dropdown';
import { AudioDirective } from './directives/audio.directive';
import { SpeechService } from './directives/speech.service';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { VideomodalComponent } from '@app/shared/modal/videomodal/videomodal.component';
import { SecurlyButtonModule } from '@securly-engineering/securly-styles';
import { AuthenticationService } from '@app/auth/authentication.service';
import { FileDownloadService } from './file-download.service';
import { SkipQuestionModalComponent } from './modal/skip-question-modal/skip-question-modal.component';
import { CustomMsgModalComponent } from './modal/custom-msg-modal/custom-msg-modal.component';
import { RMSService } from './rms-service';
import { LoginService } from '@app/auth/login.service';
import { LanguagePipe } from './pipes/language.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2SearchPipeModule,
    // PdfViewerModule,
    TreeModule,
    TreeSelectModule,
    DropdownModule,
    SecurlyButtonModule,

  ],
  declarations: [
    LoaderComponent,
    SuccessSnackbarComponent,
    FailureSnackbarComponent,
    PaginationComponent,
    LoadingIndicatorComponent,
    ResizeColumnDirective,
    SelectAllDirective,
    VaMatTableComponent,
    // SubNavComponent,
    CustomDropdownComponent,
    SaveAndCancelBannerComponent,
    SpyScrollContainerDirective,
    SpyAreaForDirective,
    SpyAnchorDirective,
    StickyDirective,
    DefaultFaviconDirective,
    DisableTooltipDirective,
    ConvertCsvPipe,
    FilterPipe,
    ChatsComponent,
    FeatureUnavailableModalComponent,
    ConcurrentLoginModalComponent,
    PasswordChangeModalComponent,
    ButtonToggleComponent,
    YesNoGeneralModalComponent,
    DateRangePickerComponent,
    SorterSelectComponent,
    GetInRhithmModalComponent,
    AttemptSessionModalComponent,
    ClassWidgetModalComponent,
    AssessmentModalComponent,
    AudioDirective,
    VideomodalComponent,
    SkipQuestionModalComponent,
    CustomMsgModalComponent,
    LanguagePipe
  ],
  exports: [
    LoaderComponent,
    CustomDropdownComponent,
    SuccessSnackbarComponent,
    FailureSnackbarComponent,
    AngularMaterialModule,
    PaginationComponent,
    LoadingIndicatorComponent,
    DisableTooltipDirective,
    ResizeColumnDirective,
    SelectAllDirective,
    VaMatTableComponent,
    // SubNavComponent,
    DateRangePickerComponent,
    SaveAndCancelBannerComponent,
    SpyScrollContainerDirective,
    SpyAreaForDirective,
    SpyAnchorDirective,
    ConvertCsvPipe,
    FilterPipe,
    ChatsComponent,
    ButtonToggleComponent,
    SorterSelectComponent,
    NgbModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    TreeModule,
    TreeSelectModule,
    DropdownModule,
    AudioDirective,
    VideomodalComponent,
    // PdfViewerModule,
    SkipQuestionModalComponent,
    CustomMsgModalComponent,
    SecurlyButtonModule,
    LanguagePipe
  ],
  providers: [
    ColumnSorterService,
    RiskAlertsServiceProxy,
    RiskAlertTemplateServiceProxy,
    AssessmentTypeServiceProxy,
    DistrictsServiceProxy,
    MountainRiskAlertsServiceV2,
    DataService,
    SpeechService,
    TeacherServiceProxy,
    WellnessTipsServiceProxy,
    RiskAlertsTemplateServiceProxy,
    DistrictsServiceProxyV2,
    UserServiceProxy,
    TokenAuthServiceProxy,
    DistrictsServiceProxyv2,
    PrincipalServiceProxy,
    TeacherServiceProxyv2,
    TokenAuthServiceProxy,
    RiskAlertsTemplateServiceProxy,
    QuestionServiceProxy,
    DashboardSessionTableServiceProxy,
    UserSessionServiceProxy,
    SessionsServiceProxy,
    UserAnswersServiceProxy,
    SessionEventServiceProxy,
    DashboardSessionTableServiceProxyV1,
    RhithmActivityServiceProxy,
    VideoProviderServiceProxy,
    AuthenticationService,
    FileDownloadService,
    AccountAppServiceProxy,
    AccountAppServiceProxyV2,
    RMSService,
    ProfileServiceProxy,
    OrganizationUnitServiceProxy

  ],
})
export class SharedModule { }
