
import { getAppConfigData } from './../app.reducer';
import { ActionReducerMap, createSelector, ActionReducer } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from '../router/utils';
import * as fromApp from '../app.reducer';
import * as fromAccounts from '../accounts/accounts.reducer';
import * as fromDashboard from '../dashboard/dashboard.reducer';
import * as fromSettings from '../settings/settings/settings.reducer';
import * as fromWellnessTips from '../wellness-tips/wellness-tips.reducer';
import * as fromShared from '../shared/shared.reducer';

export interface State {
  app: fromApp.State;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  fromAccounts: fromAccounts.State;
  dashboard: fromDashboard.State;
  settings: fromSettings.State;
  wellnesstips: fromWellnessTips.State;
  shared: fromShared.State;
}

export const reducers: ActionReducerMap<State> = {
  app: fromApp.reducer,
  router: fromRouter.routerReducer,
  fromAccounts: fromAccounts.reducer,
  dashboard: fromDashboard.reducer,
  settings: fromSettings.reducer,
  wellnesstips: fromWellnessTips.reducer,
  shared: fromShared.reducer
};

/**
 * App error messages
 */

export const getAppErrorMessageState = (state: State) => state.app;

/**
 * fromAccounts Reducers
 */

export const getAccountsState = (state: State) => state.fromAccounts;
export const getAccounts = createSelector(
  getAccountsState,
  fromAccounts.getAccounts
);
export const getLookupAccounts = createSelector(
  getAccountsState,
  fromAccounts.getLookupAccounts
);
export const getNewAccount = createSelector(
  getAccountsState,
  fromAccounts.getNewAccount
);
export const getAccountsNofication = createSelector(
  getAccountsState,
  fromAccounts.getAccountsNofication
);
export const getTotalCount = createSelector(
  getAccountsState,
  fromAccounts.getTotalCount
);
export const getOffset = createSelector(
  getAccountsState,
  fromAccounts.getOffset
);
export const getAccount = createSelector(
  getAccountsState,
  fromAccounts.getAccount
);
export const getModifiedAccount = createSelector(
  getAccountsState,
  fromAccounts.getModifiedAccount
);
export const getGridColumnSettings = createSelector(
  getAppErrorMessageState,
  fromApp.getGridColumnSettings
);
export const getResetGridColumnSettings = createSelector(
  getAppErrorMessageState,
  fromApp.getResetGridColumnSettings
);
/* shared starts*/

export const getSharedState = (state: State) => state.shared;
export const getAllStartedUserSessions = createSelector(
  getSharedState,
  fromShared.getStartedUserSessions
);
export const getAllUserNotes = createSelector(
  getSharedState,
  fromShared.getUserNotes
);

export const getQuestionAllAndSelectedOption = createSelector(getSharedState, fromShared.getQuestionAllAndSelectedOption)

/* shared ends */

// Dashboard
export const getDashboardState = (state: State) => state.dashboard;
export const getUpdatedDashboardSortData = createSelector(
  getDashboardState,
  fromDashboard.getUpdatedDashboardSortData
);
export const getDistrictAssessmentTypeList = createSelector(
  getDashboardState,
  fromDashboard.getDistrictAssessmetType
);
export const getTenentAssessmentTypeList = createSelector(
  getDashboardState,
  fromDashboard.getTanentAssessmetType
);
export const getViewTypeList = createSelector(getDashboardState, fromDashboard.getViewType);

export const getDistrictSearchTypeList = createSelector(
  getDashboardState,
  fromDashboard.getDistrictSearchType
);
export const getTeacherSearchTypeList = createSelector(
  getDashboardState,
  fromDashboard.getTeacherSearchType
);
export const getPrincipalSearchTypeList = createSelector(
  getDashboardState,
  fromDashboard.getPrincipalSearchType
);
export const getDashboardClassByStudentId = createSelector(
  getDashboardState,
  fromDashboard.getClassByStudentId
);

export const getDashboardSessionTable = createSelector(getDashboardState, fromDashboard.getDashboardSessionTable);
export const getDashboardSessionTableV2 = createSelector(getDashboardState, fromDashboard.getDashboardSessionTableV2);
export const getAssessmentQuestionTypesWithOptions = createSelector(getDashboardState, fromDashboard.getAssessmentQuestionTypesWithOptions);
export const getExportDashboardSessionTableFile = createSelector(getDashboardState, fromDashboard.getExportDashboardSessionTableFile);

// Settings

export const getSettingState = (state: State) => state.settings;
export const getRistAlertList = createSelector(getSettingState, fromSettings.getAllRiskAlert);
export const emojiRiskAlert = createSelector(getSettingState, fromSettings.emojiRiskAlert)
export const assessmentTypes = createSelector(getSettingState, fromSettings.defaultAssessmentTypes);
export const getGrades = createSelector(getSettingState, fromSettings.grades);
export const getClasses = createSelector(getSettingState, fromSettings.classes);
export const getGroups = createSelector(getSettingState, fromSettings.groups);
export const getStudents = createSelector(getSettingState, fromSettings.students);
export const getTeachers = createSelector(getSettingState, fromSettings.teachers);
export const getAdmins = createSelector(getSettingState, fromSettings.admins);
export const getEmojiAssessmentQuestions = createSelector(getSettingState, fromSettings.emojiRiskALertQuestions);
export const getAlertRecipients = createSelector(getSettingState, fromSettings.alertRecipients);
export const createTempoRiskAlert = createSelector(getSettingState, fromSettings.tempoRiskAlert);
export const createNoteRiskAlert = createSelector(getSettingState, fromSettings.noteRiskAlert);

// wellness Tips
export const getWellnessTipsState = (state: State) => state.wellnesstips;
export const loadResponseGroups = createSelector(getWellnessTipsState, fromWellnessTips.responseGroups);
export const getToolkitList = createSelector(getWellnessTipsState, fromWellnessTips.toolKit);
export const getGradesList = createSelector(getWellnessTipsState, fromWellnessTips.grades);
export const getAllTeacherTips = createSelector(getWellnessTipsState, fromWellnessTips.teacherTips);

/**
 * Prod
 */
export const getAppState = (state: State) => state.app;
export const getAppConfigInfo = createSelector(
  getAppState,
  fromApp.getAppConfigData
);
export const getUserCurrentLoginInfo = createSelector(
  getAppState,
  fromApp.getCurrentLoginInfo
);
export const getAllUserConfiguration = createSelector(
  getAppState,
  fromApp.getUserConfiguration
);
export const getIsLoginLoader = createSelector(
  getAppState,
  fromApp.getLoginLoader
);
export const getLanguages = createSelector(
  getAppState,
  fromApp.getAllLanguages
);
export const getUserCurrentLanguage = createSelector(
  getAppState,
  fromApp.getCurrentLanguage
);
export const canRedirectTVersion1 = createSelector(
  getAppState,
  fromApp.canRedirectToVersion1
);
const productionReducer: ActionReducer<State> = combineReducers(reducers);
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>(
  'root reducer'
);

export function reducer(state: any, action: any) {
  return productionReducer(state, action);
}
// export function getUpdatedDashboardSortData(getUpdatedDashboardSortData: any) {
//   throw new Error('Function not implemented.');
// }

