import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { exportDashboardSessionTable, loadAssessmentQuestionTypesWithOptions, loadClassesByStudentId, loadDashboardSessionTable, loadDashboardSessionTableV2, loadDistrictAssessmentType, loadDistrictSearchType, loadPrincipalSearchType, loadTeacherSearchType, loadTenantAssessmentType, loadViewType } from "./dashboard.action";
import { AssessmentTypeServiceProxy, OrganizationUnitServiceProxy } from "src/shared/service-proxies/service-proxies";
import { DashboardSessionTableServiceProxy, DistrictsServiceProxy, PrincipalServiceProxy, TeacherServiceProxy } from "src/shared/service-proxies/service-proxies-v2";
@Injectable()
export class DashboardEffects {

    constructor(
        private actions$: Actions,
        private _assessmentTypeService: AssessmentTypeServiceProxy,
        private _districtMountainService: DistrictsServiceProxy,
        private _districtsService: DistrictsServiceProxy,
        private _principalService: PrincipalServiceProxy,
        private _teacherService: TeacherServiceProxy,
        private _mountainDashboardSessionTable: DashboardSessionTableServiceProxy,
        private _organizationUnitService: OrganizationUnitServiceProxy,
    ) { }

    loadDistrictAssessmentType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadDistrictAssessmentType.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._assessmentTypeService.getDistrictAssessmentType().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadDistrictAssessmentType.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get district assessment type list.',
                            errorMapping: {},
                            failureAction: loadDistrictAssessmentType.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load district assessment type.',
                        })
                    })
                )
            })
        )
    })
    loadTenantAssessmentType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTenantAssessmentType.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._assessmentTypeService.getTenantAssessmentType().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadTenantAssessmentType.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get tenant assessment type list.',
                            errorMapping: {},
                            failureAction: loadTenantAssessmentType.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load tenant assessment type.',
                        })
                    })
                )
            })
        )
    })

    loadViewType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadViewType.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._districtMountainService.getViewType().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadViewType.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Teacher Search Type list.',
                            errorMapping: {},
                            failureAction: loadViewType.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Teacher Search Type.',
                        })
                    })
                )
            })
        )
    })
    loadTeacherSearchType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTeacherSearchType.init),
            map((action: any) => action),
            mergeMap((payload) => {

                return this._teacherService.getTeacherSearchType(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadTeacherSearchType.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Teacher Search Type list.',
                            errorMapping: {},
                            failureAction: loadTeacherSearchType.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Teacher Search Type.',
                        })
                    })
                )
            })
        )
    })
    loadPrincipalSearchType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPrincipalSearchType.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._principalService.getPrincipalSearchType(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadPrincipalSearchType.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Principal Search Type list.',
                            errorMapping: {},
                            failureAction: loadPrincipalSearchType.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Principal Search Type.',
                        })
                    })
                )
            })
        )
    })
    loadDistrictSearchType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadDistrictSearchType.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._districtsService.getDistrictSearchType(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadDistrictSearchType.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get District Search Type list.',
                            errorMapping: {},
                            failureAction: loadDistrictSearchType.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load District Search Type.',
                        })
                    })
                )
            })
        )
    })

    loadDashboardSessionTable$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadDashboardSessionTable.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._mountainDashboardSessionTable.getDashboardSessionTable(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadDashboardSessionTable.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get DashboardSessionTable list.',
                            errorMapping: {},
                            failureAction: loadDashboardSessionTable.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load DashboardSessionTable.',
                        })
                    })
                )
            })
        )
    })
    loadDashboardSessionTableV2$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadDashboardSessionTableV2.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._mountainDashboardSessionTable.getStudentDashboardView(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadDashboardSessionTableV2.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get DashboardSessionTableV2 list.',
                            errorMapping: {},
                            failureAction: loadDashboardSessionTableV2.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load DashboardSessionTableV2.',
                        })
                    })
                )
            })
        )
    })
    loadClassByStudentId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadClassesByStudentId.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._organizationUnitService.getClassesByStudentId(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadClassesByStudentId.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get DashboardSessionTable list.',
                            errorMapping: {},
                            failureAction: loadClassesByStudentId.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load DashboardSessionTable.',
                        })
                    })
                )
            })
        )
    })

    loadAssessmentQuestionTypesWithOptions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadAssessmentQuestionTypesWithOptions.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._assessmentTypeService.getOtherAssessmentQuestionTypesWithOptions(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAssessmentQuestionTypesWithOptions.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get assessmentQuestionTypesWithOptions list.',
                            errorMapping: {},
                            failureAction: loadAssessmentQuestionTypesWithOptions.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load assessmentQuestionTypesWithOptions.',
                        })
                    })
                )
            })
        )
    })

    exportDashboardSessionTable$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(exportDashboardSessionTable.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._mountainDashboardSessionTable.exportDashboardSessionTable(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return exportDashboardSessionTable.success({ items: res });
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to download File.',
                            errorMapping: {},
                            failureAction: exportDashboardSessionTable.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to download File.',
                        })
                    })
                )
            })
        )
    })
}
