import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { LoginComponent } from './login.component';
import { AccountComponent } from './account/account.component';
import { LoginEmailComponent } from './login-email/login-email.component';
import { UserSelectRoleComponent } from './user-select-role/user-select-role.component';
import { AuthenticationGuard } from './authentication.guard';
import { AccountRouteGuard } from './account-route-guard';
import { UserNotExistComponent } from './user-not-exist/user-not-exist.component';
import { SsoQrLoginComponent } from './sso-qr-login/sso-qr-login.component';
import { QrLoginComponent } from './qr-login/qr-login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/account/login',
      },
      {
        path: 'loginemail',
        component: LoginEmailComponent,
        canActivate: [AccountRouteGuard],
        data: { title: extract('Loginemail') },
      },
      {
        path: 'securlyqrlogin',
        component: SsoQrLoginComponent,
        canActivate: [AccountRouteGuard],
        data: { title: extract('Securlyqrlogin') },
      },
      {
        path: 'qrlogin',
        component: QrLoginComponent,
        canActivate: [AccountRouteGuard],
        data: { title: extract('Qrlogin') },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [],
        data: { title: extract('Forgot-password') },
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [],
        data: { title: extract('reset-password') },
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [AccountRouteGuard],
        data: { title: extract('Login') },
      },
      {
        path: 'user-select-role',
        component: UserSelectRoleComponent,
        canActivate: [AccountRouteGuard],
        data: { title: extract('user-select-role') },
      },
      {
        path: 'user-not-exist',
        component: UserNotExistComponent,
        canActivate: [AccountRouteGuard],
        data: { title: extract('user-not-exist') },
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule { }
