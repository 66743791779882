
import { loadAllStartedUserSessions, loadAllUserNotes, loadQuestionAllAndSelectedOption } from "./shared.actions";

export interface State {
    allStartedUserSessions: any[],
    allUserNotes: any[],
    questionAllAndSelectedOption: any,
    questionAllAndSelectedOptionPayload: any,

}
export const initialState: State = {

    allStartedUserSessions: null,
    allUserNotes: null,
    questionAllAndSelectedOption: [],
    questionAllAndSelectedOptionPayload: null,
}
export function reducer(state = initialState, action: any) {
    let stateToReturn = state;

    switch (action.type) {
        // All started user session
        case loadAllStartedUserSessions.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                allStartedUserSessions: requestPayload,
            });
        }
        case loadAllStartedUserSessions.success.type: {

            return Object.assign({}, state, {
                allStartedUserSessions: action,
            })
        }
        // user Notes
        case loadAllUserNotes.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                allUserNotes: requestPayload,
            });
        }
        case loadAllUserNotes.success.type: {

            return Object.assign({}, state, {
                allUserNotes: action,
            })
        }
        // Question and selected option
        case loadQuestionAllAndSelectedOption.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                questionAllAndSelectedOptionPayload: requestPayload,
            });
        }
        case loadQuestionAllAndSelectedOption.success.type: {

            return Object.assign({}, state, {
                questionAllAndSelectedOption: action,
            })
        }
        default:
            stateToReturn
            break;
    }
    return stateToReturn;
}

export const getStartedUserSessions = (state: State) => state?.allStartedUserSessions;
export const getUserNotes = (state: State) => state?.allUserNotes;
export const getQuestionAllAndSelectedOption = (state: State) => state?.questionAllAndSelectedOption;