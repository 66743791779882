<div class="app-user-not-exists">
    <div class="app-user-img">
        <i class="fa fa-user-times not-exists-icon" aria-hidden="true"></i>
    </div>
    <div class="app-user-para">
        <h3>This user does not exists</h3>
        <p>{{userEmail}}</p>
    </div>
    <div class="app-user-btn">
        <button (click)="goToLogin()"      
         class="btn btn-primary btn-elevate kt-login__btn-primary">Login</button>
    </div>
</div>