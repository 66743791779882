<div class="container-fluid">
    <div class="header-wrapper">
        <div *ngIf="VideoUrl && pageData == 'teacherTip' ">
            <h4 class="heading_color">{{girVideo?.title}}</h4>
        </div>
        <div *ngIf="VideoUrl && pageData == 'toolKit' ">
            <h4 class="heading_color">{{girVideo?.name}}</h4>
        </div>
        <div class="cursor_pointer langDropdown" *ngIf="pageData == 'girModal' && teacherLanguageChange">
            <div ngbDropdown>
                <a id="help-dropdown" ngbDropdownToggle>
                    <i class="fas fa-globe-americas languageIconSize"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="help-dropdown"
                    class="dropdown-menu appSettingsDropdown dropdown-menu-right">
                    <ng-container *ngFor="let item of languages">
                        <a [ngClass]="currentVideoLanguage == item.name ? 'dropdown-item item-selected' : 'dropdown-item'"
                            (click)="changeLanguageForVideo(item.name)">
                            {{item.displayName}}
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="cursor_pointer" *ngIf="pageData == 'girModal' && studentLogout">
            <mat-icon svgIcon="icon-out" (click)="logout()"></mat-icon>
        </div>
        <div class="cursor_pointer close-icon-wrapper" *ngIf="pageData != 'girModal'">
            <mat-icon (click)="closeModal()" svgIcon="close-icon" title="close"></mat-icon>
        </div>
        <br>
    </div>
    <div class="video-wrapper">
        <div class="modal-body text-center mb-0 p-0">
            <div class="video-player-wrapper">
                <video #video class="azuremediaplayer amp-default-skin preload-video" controls autoplay
                    id="session-video" width="100%" [ngStyle]="{'min-height': !VideoUrl ? '480px' : '480px'}">
                    <p class="amp-no-js">
                        To view this video please enable JavaScript, and consider upgrading
                        to a web browser that supports HTML5 video
                    </p>
                    <track *ngIf="rhithmVideoSubtitleEN" label="English" kind="subtitles" srclang="en"
                        [src]="rhithmVideoSubtitleEN" default>
                    <track *ngIf="rhithmVideoSubtitleSP" label="Español" kind="subtitles" srclang="es"
                        [src]="rhithmVideoSubtitleSP">
                </video>
                <div class="custom-play-button" *ngIf="!isPlay">
                    <div class="center" (click)="rhithmVideo()">
                        <img src="assets\scss\images\videoPlay.png" alt="Play" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!VideoUrl && pageData == 'girModal'" class="videoFooter">
        <div>
            <!-- adding mat-button to remove focus from prev button -->
            <button mat-button cdkFocusInitial class="hidden-button"></button>

            <button mat-icon-button autofocus="false" (click)="moveToPreviousVideo()">
                <mat-icon class="next-prev-arr-size" svgIcon="back-arr-icon" title="prev"></mat-icon>
            </button>
            <button mat-icon-button (click)="moveToNextVideo()">
                <mat-icon class="next-prev-arr-size" svgIcon="next-arr-icon" title="next"></mat-icon>
            </button>
        </div>
        <div class="cursor_pointer">
            <securly-button class="sec-btn" color="primary" (click)="closeModal()">
                Close</securly-button>
        </div>

    </div>

</div>