import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

interface ErrorHandlingProperties {
  defaultMessage: string;
  errorMapping: { [key: string]: string };
  failureAction: any;
  values?: any[];
  unauthorizedError?: string;
}

export const httpErrorResponseHandler = (
  errData?: HttpErrorResponse,
  errorProperties?: ErrorHandlingProperties
) => {
  let errorMessage =
    errorProperties.defaultMessage || 'An unexpected error occured.';
  let error = '';
  if (errData?.error?.errors?.length > 0 || errData?.error?.code) {
    if (errData?.error?.errors?.length) {
      error = errData.error.errors.map((error: any) => {
        return error.error;
      })[0];
    } else {
      error = errData?.error?.code;
    }
  }
  for (const errorMappingType of Object.keys(errorProperties?.errorMapping)) {
    if (error === errorMappingType) {
      errorMessage = errorProperties.errorMapping[errorMappingType];
    }
  }
  return of(
    errorProperties.failureAction({
      message: errorMessage,
      values: errorProperties.values,
    })
  );
};
