import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  SimpleChange,
  OnChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'custom-dropdown',
  template: `
    <div ngbDropdown #dropdown="ngbDropdown" class="d-inline-block">
      <button
        class="btn btn-dropdown dark-text"
        id="dropdownCategory"
        ngbDropdownToggle
        [ngClass]="{ 'select-border': isLabelVisible }"
        [disabled]="disabled"
      >
        <div class="btn-dropdown-value w-9em dark-text">
          {{ currentSelection[keyProp] }}
        </div>
        <i class="icon icon-down-arrow"></i>
      </button>
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownCategory"
        class="dropdownMenu"
      >
        <div class="search-container" *ngIf="items?.length > 11">
          <i class="icon icon-search"></i>
          <input
            type="text"
            class="filterControl dark-text search-input m-b-0"
            placeholder="Search"
            [formControl]="dropdownSearch"
            (click)="$event.stopPropagation(); dropdown.open()"
          />
        </div>
        <div
          class="smallBody empty-search"
          *ngIf="!(items | filter: dropdownSearch.value:keyProp)?.length"
        >
          <i>{{ 'No results match' }} {{ dropdownSearch.value }}</i>
        </div>
        <div class="items-container">
          <ng-container
            *ngFor="let item of items | filter: dropdownSearch.value:keyProp"
          >
            <button
              ngbDropdownItem
              [disabled]="disabled"
              class="dropdown-item dropdown-value"
              (click)="onItemSelect(item)"
              *ngIf="currentSelection !== item"
            >
              <div class="dropdown-text">
                {{ item[keyProp] }}
              </div>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dark-text {
        line-height: 20px;
        color: #171b1f;
      }
      #dropdownCategory {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        position: relative;
        font-size: 0.875em;
      }
      .select-border {
        border: 1px solid #b0cceb;
        text-align: left;
        width: 100%;
        position: relative;
        height: 35px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .select-border.btn-dropdown span {
        color: #637484;
      }
      .dropdown-item:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: all;
      }
      .dropdown-menu.show {
        max-height: none;
        overflow-y: hidden;
      }
      .dropdown-toggle i {
        padding-top: 0.2em;
        padding-left: 0.5em;
      }
    `,
  ],
})
export class CustomDropdownComponent implements OnInit, OnChanges {
  @Output() onSelectionChange = new EventEmitter();
  @Input() items: Array<any>;
  @Input() keyProp: string;
  currentSelection: any;
  @Input() newSelectedItem: any;
  @Input() disabled: boolean;
  @Input() isLabelVisible: boolean;
  dropdownSearch: FormControl;

  constructor() {}

  ngOnInit() {
    this.currentSelection = this.newSelectedItem
      ? this.newSelectedItem
      : this.items[0]
      ? this.items[0]
      : '';
    this.dropdownSearch = new FormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    const newSelectedItem: SimpleChange = changes.newSelectedItem;
    if (!newSelectedItem || !newSelectedItem.currentValue) {
      return;
    }
    if (
      this.newSelectedItem?.hasOwnProperty('name') ||
      this.newSelectedItem?.hasOwnProperty('caption')
    ) {
      this.currentSelection = this.newSelectedItem;
    }
  }

  /**
   * On dropdown selection change event
   * @param {Object} item The selected item
   */
  onItemSelect(item: any) {
    this.currentSelection = item;
    this.dropdownSearch.setValue('');
    this.onSelectionChange.emit(item);
  }
}
