import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { finalize, skip } from 'rxjs/operators';
import * as reducers from '../reducers';

import { environment } from '@env/environment';
import { untilDestroyed } from '@core';
import { AuthenticationService } from './authentication.service';
import { UiCustomizationSettingsDto, UserLoginInfoDto, UserServiceProxy, GetCurrentLoginInformationsOutputBasic, TokenAuthServiceProxy, AuthenticateModel, DistrictTenantListDto, IsTenantAvailableInput, IsTenantAvailableOutput, TenantAvailabilityState, TenantServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { LocalStorageService } from '@app/shared/local-storage.service';
import { AppConsts } from 'src/shared/AppConsts';
import { FeatureFlagEnum } from 'src/shared/FeatureFlags';
import { ExternalLoginProvider, LoginService } from './login.service';
import { UrlHelper } from 'src/shared/helpers/UrlHelper';
import { DataService } from '@app/shared/data.service';
import { CredentialsService } from './credentials.service';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: UntypedFormGroup;
  isLoading = false;
  isLoadUI: boolean = false;
  tenantListCopy: DistrictTenantListDto[];
  tenantList: DistrictTenantListDto[];
  userHasMultipleTenants: boolean = false;
  tenantName: string;
  isSsoLogin: boolean = false;
  classLinkRedirection: boolean = false;
  cleverRedirection: boolean = false;
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private UserServiceProxy: UserServiceProxy,
    private _localStorage: LocalStorageService,
    private _tenantServiceProxy: TenantServiceProxy,
    public loginService: LoginService,
    private _dataService: DataService,
    private _credentialsService: CredentialsService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<reducers.State>,
  ) {
    this.isSsoLogin = false;  //AppConsts.isFeatureEnabled(FeatureFlagEnum.UseSecurlySso);
    _activatedRoute.queryParams.subscribe(param => {
      switch (param?.provider) {
        case 'clever':
          this.isLoading = true;
          break;
        default:
          // this.isLoading = false;
          break;
      }
    });
    if (window.location.href.toString().includes('classLinkConnect')) {
      this.isLoading = true;
    } else {

      this.store.select(reducers.getIsLoginLoader).pipe(skip(1)).subscribe(data => {
        this.isLoading = data;
      })
    }/*  */
    // this._dataService.socialLoginLoader.subscribe(data => {
    //   this.isLoading = data;
    // })
    // window.addEventListener("load", event => {
    //   var image = document.querySelector('img');
    //   var load = image.complete;
    //   this.isLoadUI = image.complete
    // });
    if (this.redirectionClassLink() == false) {
      this.processEmailAddressFromCookie();
    }
    this.createForm();
    _localStorage.removeItem('user-email')
    _localStorage.removeItem('user-name')
  }

  async ngOnInit() {
    if (this.isSsoLogin && !this.router.url.toLowerCase().startsWith("/account/qrlogin")
      && !this.router.url.toLowerCase().startsWith("/account/securlyqrlogin")
    ) {
      await this.authenticationService.SecurelyIdentifyAsync()
    }
  }

  redirectionClassLink(): boolean {
    let state = UrlHelper.getQueryParametersUsingHash().state;
    let provider = UrlHelper.getQueryParametersUsingHash().provider;

    //classlink provide state in url
    if (state === undefined) {
      state = UrlHelper.getQueryParameters().state;
    }

    if (provider === undefined) {
      provider = UrlHelper.getQueryParameters().provider;
    }

    if (state && state.indexOf("openIdConnect") >= 0) {
      this.loginService.openIdConnectLoginCallback({});
      return true;
    } else if (state && state.indexOf("classLinkConnect") >= 0) {
      this.classLinkRedirection = true;
      this._dataService.classLinkRedirectionState(
        this.classLinkRedirection
      );
      this.loginService.classLinkConnectLoginCallback({});
      return true;
    } else if (provider && provider.indexOf("clever") >= 0) {
      this.cleverRedirection = true;
      this._dataService.cleverRedirectionState(this.cleverRedirection);
      this.loginService.cleverConnectLoginCallback({});
      return true;
    }

    return false;
  }

  externalLogin(provider: ExternalLoginProvider) {
    // if (provider.name == AppConsts.classLink) {
    //     this._googleTagAnalyticManagerService.triggerEventWithSessionData(
    //         GoogleTagEventEnum.OnLoginClassLinkClick
    //     );
    // } else if (provider.name == AppConsts.Clever) {
    //     this._googleTagAnalyticManagerService.triggerEventWithSessionData(
    //         GoogleTagEventEnum.OnLoginCleverClick
    //     );
    // } else if (provider.name == AppConsts.Aequitas) {
    //     this._googleTagAnalyticManagerService.triggerEventWithSessionData(
    //         GoogleTagEventEnum.OnLoginAequitasClick
    //     );
    // } else {
    //     this._googleTagAnalyticManagerService.triggerEventWithSessionData(
    //         GoogleTagEventEnum.OnLoginGoogleClassRoomClick
    //     );
    // }
    this.loginService.externalAuthenticate(provider);
  }

  login() {
    this.isLoading = true;
    const login$ = this.authenticationService.login(this.loginForm.value);
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }), untilDestroyed(this))
      .subscribe({
        next: (v) => {
          this.router.navigate(['/app/dashboard'], { replaceUrl: true });
          setTimeout(() => {
            window.location.reload()
          }, 200);
        },
        error: (e) => this.error = e,
        complete: () => console.info('complete')
      })
  }

  setCookie(name: string, value: any, days: number): void {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + '; ' + expires + ';path=/';
  }

  getTenantNameById(): void {
    var emailAddress = this.loginForm.value.username;
    if (emailAddress) {
      this.UserServiceProxy
        .getTenantsByEmailAddress(emailAddress)
        .subscribe((result) => {
          if (result && result.length > 0) {
            let tenanatlist: any = [];

            this.tenantListCopy = result;
            if (result.length > 0) {
              result.forEach(item => {
                if (item.id == null) {
                  item.roleName = item.roleName == "DistrictOwner" ? "District Admin" : item.roleName;
                  tenanatlist.push({ id: null, name: `Login as ${item.roleName} ` });
                } else {
                  item.roleName = item.roleName == "Principal" ? "Campus Admin" : item.roleName;
                  tenanatlist.push({ id: item.id, name: `Login as ${item.roleName} from ${item.name}` });
                }
              })
            }
            this.tenantList = tenanatlist;
            if (this.tenantList.length > 1) {
              this.userHasMultipleTenants = true;
              // abp.multiTenancy.setTenantIdCookie(null);
              // abp.multiTenancy.setTenantIdCookie(
              //     this.tenantList[0].id
              // );
              if (this.tenantList[0].id) {
                this.setCookie('Abp.TenantId', this.tenantList[0].id, 30)
              }
              this.loginForm.get('tenantname').setValue(this.tenantList[0]);
            } else {
              this.userHasMultipleTenants = false;
              this.loginForm.get('tenantname').setValue(this.tenantList[0]);
              // abp.multiTenancy.setTenantIdCookie(
              //     this.tenantList[0].id
              // );
              if (this.tenantList[0].id) {
                this.setCookie('Abp.TenantId', this.tenantList[0].id, 30)
              }

            }
          } else {
            this.userHasMultipleTenants = false;
            // this.processEmailAddressFromCookie();
          }
        });
    } else {
      this.userHasMultipleTenants = false;
      // this.processEmailAddressFromCookie();
    }
  }
  compareObjects(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.id === option2.id : option1 === option2;
  }
  getDisplayValue(option: any): string {
    return option ? option.name : ''; // Adjust the property based on your object structure
  }

  switchTenant(tenant: DistrictTenantListDto): void {
    ;
    let item = this.tenantListCopy.filter(x => x.id == tenant.id);
    let input = new IsTenantAvailableInput();
    if (tenant.name) {
      input.name = tenant.name;
    } else if (tenant.tenancyName) {
      input.tenancyName = tenant.tenancyName;
    }
    if (item.length > 0) {
      let element = item[0];
      if (!element.name) {
        // abp.multiTenancy.setTenantIdCookie(undefined);
        return;
      }
      input.name = element.name != "" ? element.name : element.roleName;
    }
    if (input.name != null) {
      ;
      this._tenantServiceProxy
        .isTenantAvailable(input)
        .subscribe((result: IsTenantAvailableOutput) => {
          switch (result.state) {
            case TenantAvailabilityState._1:
              // abp.multiTenancy.setTenantIdCookie(result.tenantId);
              if (result.tenantId) {
                this.setCookie('Abp.TenantId', result.tenantId, 30)
              }
              return;
            case TenantAvailabilityState._2:
              // this.message.warn(
              //     this.l("This school is not active. Please contact the school administrator.", tenant.tenancyName)
              console.log("This school is not active. Please contact the school administrator.", tenant.tenancyName)
              // );
              break;
            case TenantAvailabilityState._3: //NotFound
              // this.message.warn(
              //     this.l(
              //         "There is no school defined with name {0}",
              //         tenant.tenancyName
              //     )
              // );
              console.log("There is no school defined with name {0}", tenant.tenancyName)
              break;
            case TenantAvailabilityState._4:
              // this.message.warn(
              //     this.l(
              //         "We are unable to switch to this school. Please contact the Rhithm team for further assistance.",
              //         tenant.tenancyName
              //     )
              // );
              console.log("We are unable to switch to this school. Please contact the Rhithm team for further assistance.", tenant.tenancyName)
              break;
          }
        });
    }
  }
  processEmailAddressFromCookie(): void {
    this._credentialsService.setCookie(
      'Abp.TenantId',
      null,
      null,
    );
    this._credentialsService.removeCookie(
      AppConsts.authorization.userNameOrEmailAddress,
    );
    // this.router.navigate([AppConsts.loginEmail]);

    // var emailAddressFromCookie = this._utilsService.getCookieValue('username_or_email_address')
    // var emailAddressFromCookie = this._utilsService.getCookieValue('_ruoe')
    // if (emailAddressFromCookie) {
    //     // if email address exists then route to loginpass screen
    //     if(this.verifyFlag == true){
    //         this.router.navigate([AppConsts.loginEmail]);
    //     }
    //     else{
    //         this.router.navigate([AppConsts.loginPassUrl]);
    //     }

    // } else {
    //     // else remove tenant id from cookie, because we want to retrieve tenant of newly entered user again
    //     abp.multiTenancy.setTenantIdCookie(null);
    // }
  }
  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      tenantname: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }
}
