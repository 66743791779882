import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GetSessionNotesInput } from 'src/shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as reducers from './../../../reducers';
import { loadAllUserNotes } from '@app/shared/shared.actions';
import { Subject, skip, takeUntil } from 'rxjs';

@Component({
  selector: 'app-custom-msg-modal',
  templateUrl: './custom-msg-modal.component.html',
  styleUrls: ['./custom-msg-modal.component.scss']
})
export class CustomMsgModalComponent implements OnInit {
  customMsg: string = ''
  userNoteList: any[] = [];
  isNote: boolean = false
  splitedText: string[] = []
  destroy$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = ['Date', 'Category', 'Emoji', 'Note'];
  constructor(
    private dialogRef: MatDialogRef<CustomMsgModalComponent>,
    @Inject(MAT_DIALOG_DATA) private msg: any,
    private _dialog: MatDialog,
    private store: Store<reducers.State>,
  ) {
    this.isNote = msg.isNote
  }

  ngOnInit() {
    if (this.isNote) {
      let payload: GetSessionNotesInput = new GetSessionNotesInput()
      payload.isNote = false;
      payload.sessionId = this.msg.sessionId;
      payload.userId = this.msg.userId;
      payload.date = moment(new Date());
      payload.filter = "";
      payload.isNote = false;
      payload.page = 0;
      payload.pageSize = 10;
      payload.roleName = "";
      payload.searchFilter = "";
      payload.sortBy = "";
      this.store.dispatch(loadAllUserNotes.init({ payload }));
      this.getAllUserNotes();
    } else {
      this.customMsg = this.msg.customMsg
    }
    document.getElementById('msg_dialog_cls_btn').focus();
  }
  closeSelf() {
    this.dialogRef.close();
  }
  getAllUserNotes() {
    this.store
      .select(reducers.getAllUserNotes)
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res) {
          this.userNoteList = res.items;
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
