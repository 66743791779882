import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-user-not-exist',
  templateUrl: './user-not-exist.component.html',
  styleUrls: ['./user-not-exist.component.scss']
})
export class UserNotExistComponent implements OnInit {

  userEmail :any;



  constructor(private route: ActivatedRoute){}


  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      this.userEmail = params['email'];
            });
  }

  goToLogin(){
    location.href = 'account/login';
    }

}
