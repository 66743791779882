import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DistrictTenantListDto, IsTenantAvailableInput, IsTenantAvailableOutput, TenantAvailabilityState, TenantServiceProxy, UserServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { AppConsts } from 'src/shared/AppConsts';
import { AuthenticationService } from '../authentication.service';
import { CredentialsService } from '../credentials.service';

@Component({
  selector: 'app-user-select-role',
  templateUrl: './user-select-role.component.html',
  styleUrls: ['./user-select-role.component.scss']
})
export class UserSelectRoleComponent implements OnInit {
  rhithmUserInfoCookieName: string = "RhithmUserInfo";
  userHasMultipleTenants: boolean = false;
  tenantList: DistrictTenantListDto[];
  captchaResponse?: string;
  emailAddress: string;
  tenantName: any;
  submitting = false;
  tenantListCopy: any;
  userObj: any;
  securly_token: string = "securly_token";
  userObjList: any;


  constructor(
    private credentialsService: CredentialsService,
    private _userServiceProxy: UserServiceProxy,
    private _tenantServiceProxy: TenantServiceProxy,
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    ;
    var cookieJsonValue = this.credentialsService.getCookie(this.securly_token)
    let getTokenEmail = JSON.parse(atob(cookieJsonValue.split('.')[1]));
    // this.userObj = window.history.state;
    this.userObj = getTokenEmail.email
    this._userServiceProxy
      .getRhithmCookiesUserInfo(this.userObj)
      .subscribe((result) => {
        this.userObjList = result;
        this.emailAddress = this.userObj;
        if (this.emailAddress.trim)
          this.getTenantNameById(this.userObj);
      })

  }

  getDisplayValue(option: any): string {
    return option ? option.name : ''; // Adjust the property based on your object structure
  }
  compareObjects(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.id === option2.id : option1 === option2;
  }

  getTenantNameById(emailAddress?: string) {

    if (emailAddress.trim().length > 0) {
      if (this.userObjList && this.userObjList.length > 0) {
        let tenanatlist: any = [];
        this.tenantListCopy = this.userObjList;
        this.userObjList.forEach((item: { tenantId: null; roleName: string; districtName: any; tenantName: any; }) => {
          if (item.tenantId == null) {
            item.roleName = item.roleName == "DistrictOwner" ? "District Admin" : item.roleName;
            tenanatlist.push({ id: null, name: `Login as ${item.roleName} from ${item.districtName}` });
          } else {
            item.roleName = item.roleName == "Principal" ? "Campus Admin" : item.roleName;
            tenanatlist.push({ id: item.tenantId, name: `Login as ${item.roleName} from ${item.tenantName}` });
          }
        })

        this.tenantList = tenanatlist;
        if (this.tenantList.length > 1) {
          this.userHasMultipleTenants = true;
          this.credentialsService.setCookie('Abp.TenantId', null)
          this.credentialsService.setCookie('Abp.TenantId', this.tenantList[0].id)
          // abp.multiTenancy.setTenantIdCookie(null);
          // abp.multiTenancy.setTenantIdCookie(
          //   this.tenantList[0].id
          // );
          this.tenantName = {
            id: this.tenantList[0].id,
            name: this.tenantList[0].name
          };
        } else {
          this.userHasMultipleTenants = false;
          this.tenantName = {
            id: this.tenantList[0].id,
            name: this.tenantList[0].name
          };
          // abp.multiTenancy.setTenantIdCookie(
          //   this.tenantList[0].id
          // );
          this.credentialsService.setCookie('Abp.TenantId', this.tenantList[0].id)
        }
      }
      else {
        this.userHasMultipleTenants = false;
      }
    }
  }
  switchTenant(tenant: DistrictTenantListDto): void {

    let item = this.tenantListCopy.filter((x: any) => x.tenantId == tenant.id);

    if (item.length > 1) {
      let tenantName = tenant.name.split("from ");
      item = item.filter((x: any) => x.districtName == tenantName[1]);
    }
    let input = new IsTenantAvailableInput();
    if (tenant.name) {
      input.name = tenant.name;
    } else if (tenant.tenancyName) {
      input.tenancyName = tenant.tenancyName;
    }
    if (item.length > 0) {
      let element = item[0];
      if (!element.tenantName) {
        this.credentialsService.setCookie('Abp.TenantId', undefined)
        // abp.multiTenancy.setTenantIdCookie(undefined);
        return;
      }
      input.name = element.tenantName != "" ? element.tenantName : element.roleName;
    }
    if (input.name != null) {
      AppConsts.isTenant = true;
      this._tenantServiceProxy
        .isTenantAvailable(input)
        .subscribe((result: IsTenantAvailableOutput) => {
          AppConsts.isTenant = false;
          switch (result.state) {
            case TenantAvailabilityState._1:
              // abp.multiTenancy.setTenantIdCookie(result.tenantId);
              this.credentialsService.setCookie('Abp.TenantId', result.tenantId)
              return;
            case TenantAvailabilityState._2:
              // this.message.warn(
              //   this.l("This school is not active. Please contact the school administrator.", tenant.tenancyName)
              // );
              console.log("This school is not active. Please contact the school administrator.", tenant.tenancyName)
              break;
            case TenantAvailabilityState._3: //NotFound
              console.log("There is no school defined with name {0}", tenant.tenancyName)
              // this.message.warn(
              //   this.l(
              //     `There is no school defined with name ${tenant.tenancyName}`
              //   )
              // );
              break;
            case TenantAvailabilityState._4:
              console.log("We are unable to switch to this school. Please contact the Rhithm team for further assistance.", tenant.tenancyName)
              // this.message.warn(
              //   this.l(
              //     "We are unable to switch to this school. Please contact the Rhithm team for further assistance.",
              //     tenant.tenancyName
              //   )
              // );
              break;
          }
        });
    }
  }

  loginEmail(): void {
    ;
    let selectedTenant = this.userObjList.filter((x: any) => x.tenantId == this.tenantName["id"]);
    if (selectedTenant.length > 1) {
      let tenantName = this.tenantName["name"].split("from ");
      selectedTenant = selectedTenant.filter((x: any) => x.districtName == tenantName[1]);
    }
    if (selectedTenant.length > 0 && selectedTenant.length < 2) {
      this.authService.setRhithmloginInfoToCookie(selectedTenant[0]);
      setTimeout(() => {
        location.href = '/';
      }, 2000);

    }
  }
  logout(): void {
    ;
    localStorage.removeItem("dateType");
    localStorage.removeItem("treeSelectedTab");
    localStorage.removeItem("selectedDate");
    localStorage.removeItem("selectedParentOption");
    localStorage.removeItem("selectedSubOption");
    localStorage.clear();

    // this.loginPassService.authenticateModel.password = null;
    // this.loginPassService.authenticateModel.userNameOrEmailAddress = null;
    // switching to login screen and nullifying all variables
    // abp.multiTenancy.setTenantIdCookie(null);
    this.credentialsService.setCookie('Abp.TenantId', null)
    this.credentialsService.removeCookie(
      AppConsts.authorization.userNameOrEmailAddress,
    );

    this.authService.logout()
    // .then((response:any) => {
    // this._interComwidgetService.Intercomshutdown();
    this.router.navigate([AppConsts.loginUrl]);
    // })
    // .catch(exception => {
    // });
  }

}
