import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, Optional, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { AssessmentTypeServiceProxy, CreateOrEditSessionDto, SessionEventServiceProxy, SessionsServiceProxy as SessionServiceProxyMountain, SessionsServiceProxy } from './../../../../shared/service-proxies/service-proxies';
import { AppConsts } from '../../../../shared/AppConsts';
// import { EventTrackingHelperClass } from '../../../../shared/helpers/EventTrackingHelperService';
import { FeatureFlagEnum } from '../../../../shared/FeatureFlags';
// import { timeStamp } from 'console';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AssessmentModalComponent } from '../assessment-modal/assessment-modal.component';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
interface ITreeNode extends TreeNode {
  selected: boolean;
}
@Component({
  selector: 'app-class-widget-modal',
  templateUrl: './class-widget-modal.component.html',
  styleUrls: ['./class-widget-modal.component.scss']
})
export class ClassWidgetModalComponent implements OnInit, AfterViewInit {
  alreadySessionStartedClasses: string[];

  saving: boolean = false;
  selectedTeacherClass: any;
  allParticipants: TreeNode[] = [];
  selectedParticipants: TreeNode[] = [];
  feature_156 = true;
  clothesPin: any;
  sessionTrackerId: any;
  feature_DA156: any
  isValidGroupOrClassSession: boolean;
  assessmentTypeId: number;
  isValidTeacherorStaffSession: any;
  selectedOrganizationUnitAndUsersList: any[];
  sessionType: string;

  constructor(
    private _route: Router,
    private _dialog: MatDialog,
    private _snackBarService : SecurlySnackBarService,
    private _sessionEventService: SessionEventServiceProxy,
    private _assessmentType: AssessmentTypeServiceProxy,
    private _sessionServiceProxyMountain: SessionServiceProxyMountain,
    private appSession: AppSessionService,
    public dialogRef: MatDialogRef<ClassWidgetModalComponent>,
    private _sessionsServiceProxyMountain: SessionsServiceProxy,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.feature_DA156 = AppConsts.isFeatureEnabled(FeatureFlagEnum.Feature_DA156)
    this.selectedTeacherClass = null;
    this.showParticipants()
  }
  ngAfterViewInit(): void {
    let el = document.querySelector('.p-inputtext');
    el.setAttribute('placeholder', 'Search for classroom or student');
  }


  open() {
    this.reset();
    this.selectedTeacherClass = null;
    this.showParticipants();
  }


  showParticipants() {
    this._sessionServiceProxyMountain
      .getSessionEventParticipants(this.appSession.userId)
      .subscribe((result) => {
        if (result) {

          result.forEach((rl) => {
            let children: TreeNode[] = [];
            rl.users.forEach((user) =>
              rl.collection === AppConsts.Principal &&
                user.id === this.appSession.userId
                ? null
                : children.push({
                  label: user.name,
                  data: user.id,
                  selectable: true,
                  key:
                    (rl.collectionId
                      ? rl.collectionId.toString()
                      : "0") +
                    "_" +
                    user.id.toString(),
                })
            );
            rl.collection === AppConsts.Principal
              ? (rl.collection = AppConsts.Admin)
              : null;

            let treeNode: TreeNode = {
              label: rl.collection,
              data: null,
              selectable: true,
              children,
              key: rl.collectionId
                ? rl.collectionId.toString()
                : "0",
            };
            this.allParticipants.push(treeNode);
          });
        } else {
          // this.notify.error("We were unable to retrieve the classes at the moment. Please try again later.");
        }
      }, (error) => {
        // this.notify.error("We were unable to retrieve the classes at the moment. Please try again later.");
      });
    if (this.feature_DA156) {
      if (this.appSession.isGranted("Pages.Rhithm.Teacher")) {
        this.sessionTrackerId = localStorage.getItem('loginSessionId')

        if (this.sessionTrackerId) {
          let eventName = "Viewed_List_of_students"
          // this.eventTrackService.logDigitalClothespin(this.sessionTrackerId, eventName)
        }
      }
    }
  }
  nodeSelect(event: any) {

    this.handingPartialSelectedTree(event);
  }

  nodeSelectSingleClass(event: any) {

    this.handingPartialSelectedTreeSingleClass(event);

  }

  nodeUnSelect(event: any) {

    this.handingPartialSelectedTree(event);
  }

  nodeUnSelectSingleClass(event: any) {

    this.nodeUnSelecthandingPartialSelectedTree(event);

  }

  nodeUnSelecthandingPartialSelectedTree(event: any) {

    const index = this.allParticipants.findIndex(item => item.key === event.node.key);
    this.allParticipants.forEach((element, arrayIndex) => {
      if (element.children) {
        element.children.map(res => {
          if (arrayIndex === index) {
            res.selectable = true;
          } else {
            element.selectable = true;
          }
        })
      }
      if (arrayIndex === index) {
        element.selectable = true;
      } else {
        element.selectable = true;
      }
    });

    if (this.selectedParticipants.length === 0) {
      this.allParticipants.forEach((rl) =>
        rl.children && rl.children.length > 0
          ? (rl["partialSelected"] = false)
          : null
      );
    } else {
      this.allParticipants.forEach((rl: ITreeNode) =>
        rl.children.forEach((c: ITreeNode) => (c.selected = false))
      );
      this.selectedParticipants.forEach((selectedParticipant) => {
        this.allParticipants.forEach((rl) => {
          rl.children.forEach((c: ITreeNode) => {
            if (c.key == selectedParticipant.key) {
              c.selected = true;
              c.type = AppConsts.Class;
            }
          });
          let selectedParticipants = rl.children.filter(
            (c: ITreeNode) => c.selected == true
          );
          // console.log(selectedParticipants);
          selectedParticipants.length > 0
            ? (rl["partialSelected"] = true)
            : (rl["partialSelected"] = false);
        });
      });
    }
  }

  handingPartialSelectedTree(event: any) {
    if (this.selectedParticipants.length === 0) {
      this.allParticipants.forEach((rl) =>
        rl.children && rl.children.length > 0
          ? (rl["partialSelected"] = false)
          : null
      );
    } else {
      this.allParticipants.forEach((rl: ITreeNode) =>
        rl.children.forEach((c: ITreeNode) => (c.selected = false))
      );
      this.selectedParticipants.forEach((selectedParticipant) => {
        this.allParticipants.forEach((rl) => {
          rl.children.forEach((c: ITreeNode) => {
            if (c.key == selectedParticipant.key) {
              c.selected = true;
              c.type = AppConsts.Class;
            }
          });
          let selectedParticipants = rl.children.filter(
            (c: ITreeNode) => c.selected == true
          );
          // console.log(selectedParticipants);
          selectedParticipants.length > 0
            ? (rl["partialSelected"] = true)
            : (rl["partialSelected"] = false);
        });
      });
    }
  }

  handingPartialSelectedTreeSingleClass(event: any) {

    const selectedIndex = this.allParticipants.findIndex(item => item.key === event.node.key);
    this.allParticipants.forEach((element, arrayIndex) => {
      if (arrayIndex === selectedIndex) {
        element.selectable = true;
      } else {
        element.selectable = false;
      }
    });



    if (this.selectedParticipants.length === 0) {
      this.allParticipants.forEach((rl) =>
        rl.children && rl.children.length > 0
          ? (rl["partialSelected"] = false)
          : null
      );
    } else {
      this.allParticipants.forEach((rl: ITreeNode) =>
        rl.children.forEach((c: ITreeNode) => (c.selected = false))
      );
      this.selectedParticipants.forEach((selectedParticipant) => {
        this.allParticipants.forEach((rl) => {
          rl.children.forEach((c: ITreeNode) => {
            if (c.key == selectedParticipant.key) {
              c.selected = true;
              c.type = AppConsts.Class;
            }
          });
          let selectedParticipants = rl.children.filter(
            (c: ITreeNode) => c.selected == true
          );
          // console.log(selectedParticipants);
          selectedParticipants.length > 0
            ? (rl["partialSelected"] = true)
            : (rl["partialSelected"] = false);
        });
      });
    }
  }

  save() {
    this.selectedParticipants;
    if (this.feature_DA156) {
      if (this.appSession.isGranted("Pages.Rhithm.Teacher")) {
        this.sessionTrackerId = localStorage.getItem('loginSessionId')

        if (this.sessionTrackerId) {
          let eventName = "Clicked_Okay"
          // this.eventTrackService.logDigitalClothespin(this.sessionTrackerId, eventName)
        }
      }
    }
    this.startGroupOrClassSession(this.selectedParticipants)

  }
  startGroupOrClassSession(event: any) {
    this._assessmentType.getTenantAssessmentType().subscribe(x => {
      x = x.filter(list => list.isInactive === false);
      let assessmentTypes = x.filter(x => x.isAllowMultipleAssessment == true);
      // let inActiveList = x.filter(x=> x.isInactive == false);
      // assessmentTypes = inActiveList;
      if (assessmentTypes && assessmentTypes.length > 1) {

        this.isValidGroupOrClassSession = true;
        this.assessmentmodalgetinrhithm(x, event);
      }
      else {
        // this.assessmentTypeId = x[0].id ? x[0].id : AppConsts.DefaultAssessmentTypeId;
        // this.createMultipleGroupOrClassSession(event);
        // this.getRhithmForMyClassModal.close();
        // this.getRhithmForMyGroupModal.close();
      }
    })
  }
  assessmentmodalgetinrhithm(assessmentTypes: any, selectedParticipants: any) {
    const dialogRef = this._dialog.open(AssessmentModalComponent, {
      width: '600px',
      height: '250px',
      data: {
        assessmentTypes: assessmentTypes,
        selectedRoles: selectedParticipants
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.roles) {
        let payload = [result.assessmentTypeId, result.roles]
        this.onSaveAssessmentmodal(payload)

      }
    });
  }
  onSaveAssessmentmodal(e: any) {

    if (e && e != null && e.length > 1) {
      this.assessmentTypeId = e[0] ? e[0] : AppConsts.DefaultAssessmentTypeId;
      if (this.isValidGroupOrClassSession) {
        this.createMultipleGroupOrClassSession(e[1]);
      }
      // if (this.isValidTeacherorStaffSession) {
      //     this.createSessionSelectedTeacherOrStaff(e[1]);
      // }
      // if (this.isValidSessionForSelectedPrincipal) {
      //     this.currentDialogMode = AppConsts.inviteSessionForSelectedPrincipal;
      //     this.inviteSessionForSelectedPrincipal(e[1]);
      //     this.getRhithmForMyPrinciapalModal.close();
      // }
      // this.showassessmentmodalgetinrhithm.close();
    }
  }
  createMultipleGroupOrClassSession(event: any) {
    this.selectedOrganizationUnitAndUsersList = [];
    this.selectedTeacherClass = event;
    this.sessionType = AppConsts.sessionTypeDescendant;
    for (var x = 0; x < event.length; x++) {
      let createOrEditSession: CreateOrEditSessionDto = new CreateOrEditSessionDto();
      var userIds = [];
      if (event[x].parent) {
        if (this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == event[x].parent.key).length == 0) {
          createOrEditSession.classId = Number(event[x].parent.key);
          createOrEditSession.name = event[x].parent.label;
          createOrEditSession.rCreatorUserId = this.appSession.userId;
          createOrEditSession.isClosed = AppConsts.sessionStatusStarted;
          createOrEditSession.type = event[x].type;
          userIds.push(parseInt(event[x].data));
          createOrEditSession.userIds = userIds;
          createOrEditSession.assessmentTypeId = this.assessmentTypeId ? this.assessmentTypeId : AppConsts.DefaultAssessmentTypeId;
          this.selectedOrganizationUnitAndUsersList.push(createOrEditSession);
        }
        else {
          var parentKey = event[x].key.split('_')[0];
          var key = event[x].data;
          var isClassExist = this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == parentKey);
          if (isClassExist.length > 0) {
            if (isClassExist[0].userIds.filter((i: any) => i == key).length == 0) {
              userIds.push(parseInt(event[x].data));
              this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == parentKey)[0].userIds.push(key);
            }
          }
        }
      }
      else if (event[x].children) {
        if (this.selectedOrganizationUnitAndUsersList.filter(i => i.classId == event[x].key).length == 0) {
          createOrEditSession.classId = Number(event[x].key);
          createOrEditSession.name = event[x].label;
          createOrEditSession.isClosed = AppConsts.sessionStatusStarted;
          createOrEditSession.type = event[x].children[0].type;
          createOrEditSession.rCreatorUserId = this.appSession.userId;
          createOrEditSession.assessmentTypeId = this.assessmentTypeId ? this.assessmentTypeId : AppConsts.DefaultAssessmentTypeId;
          var userIds = [];
          for (var z = 0; z < event[x].children.length; z++) {
            var userId = parseInt(event[x].children[z].data);
            userIds.push(userId);
          }
          createOrEditSession.userIds = userIds;
          this.selectedOrganizationUnitAndUsersList.push(createOrEditSession);
        }
      }
    }

    this._sessionsServiceProxyMountain.createMultipleClassSessions(this.selectedOrganizationUnitAndUsersList)
      .subscribe((result: string[]) => {
        if (result.length > 0) {
          this.alreadySessionStartedClasses = [];
          this.alreadySessionStartedClasses = result;
          this._dialog.closeAll();
          this.customMsgModal()
        }
        else {
          this._dialog.closeAll();
          //this.getRhithmForMyClassModal.close();
          // this.getMyActiveSession(false);
          // this.showUnattendedSessionsListModal();
        }
      });
  }

  customMsgModal() {
    let classesList = this.alreadySessionStartedClasses.join(',');
    let customMsg = `The Sessions For The Following Classes Have Already Been Started: ${classesList} `
    this._snackBarService.open(customMsg,SecurlySnackBarType.Warning)
  }
  reset() {
    this.allParticipants = [];
    this.selectedParticipants = [];
    this.clothesPin = null
  }
  close() {
    this._dialog.closeAll()
  }

}
